import { UserConstants } from '../constants/user.constants';
import secureStorage from '../../config/encrypt';

let authUser = '';
let initialState = '';
try{
  authUser = secureStorage.getItem('authUserInfo');
  initialState = authUser ? { loggedIn: true, authUser: authUser } : {};
}catch(err){
  
}

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case UserConstants.LOGIN_CAPTCHA:
      return {
        captchaImage: (action.captcha && action.captcha.svg) || "",
        captchaValue: (action.captcha && action.captcha.value) || "",
        captchaError: (action.captcha && action.captcha.error) || ""
      };
    case UserConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        authUser: authUser ? authUser : action.user,
        ...state
      };
    case UserConstants.LOGIN_SUCCESS:  
      return {
        loggingIn: true,
        loggedIn: true,
        loginType:action.user.login_type,
        authUser: authUser ? authUser : action.user,
        redirectTo: '/',
        ...state
      };
    case UserConstants.LOGIN_FAILURE:
      return {...state, loggingIn: false, invalidLogin: true};
    case UserConstants.LOGOUT:
      return {
        loggedIn: false,
        loggedOut: true,
      };    
    
    case UserConstants.SITE_SETTINGS_SUCCESS:
      return {
        siteSettings: action.data,
        ...state
      };
    case UserConstants.SITE_SETTINGS_ERROR:
      return {
        siteSettings: [],
        ...state
      };
    case UserConstants.GET_CREATED_BY_SUCCESS:
      return {
        ...state,
        createdByList: (action.createdByList && action.createdByList.data) ? action.createdByList.data : []
      };  
      

    default:
      return state
  }
}