import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { AuctionService } from '../../../service';
import { AuctionActions, CommonMethodActions, VehicleActions } from '../../../store/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuctionBasicDetail from './AuctionBasicDetail';
import ManageBuyers from "./ManageBuyer"
import AddVechileAuction from "./AddVechileAuction"
import { Tab, Nav } from 'react-bootstrap';
import InventoriesTable from './InventoriesTable'
import FullPageLoader from '../../common/FullPageLoader';
import ModalPopup from './../../elements/PopupModal';
import AuctionActivityLog from './AuctionActivityLog';
import { default as config } from '../../../config/config';
import moment from 'moment';

class Auction extends Component {
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.business_type_div = React.createRef()
    this.region_div = React.createRef()
    this.province_id_div = React.createRef()
    this.city_id_div = React.createRef()
    this.auction_tl_div = React.createRef()
    this.start_date_time_div = React.createRef()
    this.end_date_time_div = React.createRef()
    this.format_div = React.createRef()
    this.vehicle_type_div = React.createRef()

  }
  initialState = () => {
    let now = new Date(); 
    let timeMintues = now.getMinutes();
    let diffMint= (timeMintues<30)? 30-timeMintues: 60-timeMintues; 
    let startInitialTime =  new Date(now.getTime() + diffMint * 60000).toISOString();
    return {
      selectedOption: null,
      formFields: {
        name: '',
        start_date_time: startInitialTime,
        end_date_time: null,
        province_id: '',
        province_name: '',
        city_id: '',
        city_name: '',
        region: '',
        auction_tl: '',
        format: '1',
        business_type: '1',
        vehicle_type: config.constants.FourWheeler,
        auction_buyers: [],
        auction_inventory: []
      },
      auctionId: this.props.match.params.id,
      errors: {},
      submitted: false,
      loading: true,
      cityList: [],
      stateList: [],
      saved_auction_data: {},
      inventoryFilters: {
        // category_id: 1, //DEFAULT VALUE
        // car_status: [1],
        page_number: 1
      },
      totalSavedInventory: 0,
      showHistoryModal: false
    };
  }

  componentDidMount = async () => {

    this._isMounted = true

    //FETCH CITY/STATE
    this.getCityStateList();

    //GET VEHICLE DETAILS
    await this.getAuctionDetails();

    //GET BUYERS LIST
    this.getBuyersList();

    //GET INVENTORY LIST
    // this.listInventory();


    //GET AUCTION FILTERS
    this.getAuctionFilters()

  }

  /**
   * UNMOUNT COMPONENT
   */
  componentWillUnmount = () => {
    this._isMounted = false
  }

  /**
   * GET BUYERS
   */
  getBuyersList = () => {
    if (!this.props.buyerList || !Object.keys(this.props.buyerList).length) {
      let filter = {};
      filter['auction_buyer'] = [1];
      this.props.actions.getBuyersList({ filter: filter })
    }
  }

  /**
   * GET AUCTION FILTERS
   */
  getAuctionFilters = () => {
    if (!this.props.auctionFilterList || !Object.keys(this.props.auctionFilterList).length) {
      this.props.actions.getAuctionFilter()
    }
  }

  listInventory = async (params = {}, pagination = false) => {
    
    if (typeof params['keyword'] != 'undefined' && params['keyword'] === '') delete params['keyword'];
    // this.setState({...this.state.inventoryFilters, ...params})

    if (pagination) {
      this.props.actions.getVehicleList({ ...this.state.inventoryFilters, ...params });

    } else if ((!this.props.inventoryList || !Object.keys(this.props.inventoryList).length)) {
      this.props.actions.getVehicleList(this.state.inventoryFilters)
    }
  }


  /**
   * GET CITY/STATE LIST
   */
  getCityStateList = async () => {

    //CITY/STATE LIST
    let cityList = [], stateList = []

    let cityStateData = this.props.cityStateData

    //CHECK IF NOT IN STATE THEN CALL API
    if (!this.props.cityStateList || !Object.keys(this.props.cityStateList).length) {
      cityStateData = await this.props.actions.getCityStateList()
    } else {
      cityStateData = this.props.cityStateList
    }

    if (cityStateData && cityStateData.city) {
      //PREPARE CITY ARRAY FOR SELECTBOX
      cityList = cityStateData.city.map(city => {
        return { value: '' + city.id, label: city.name, state_id: city.state_id }
      });

      if (this._isMounted) this.setState({ cityList });
    }

    if (cityStateData && cityStateData.state) {
      //PREPARE STATE ARRAY FOR SELECTBOX
      stateList = cityStateData.state.map(state => {
        return { value: '' + state.id, label: state.name }
      });

      if (this._isMounted) this.setState({ stateList });
    }

  }

  /**
   * GET VEHICLE DETAILS
   */
  getAuctionDetails = () => {

    //FETCH AUCTION DETAILS
    if (this.state.auctionId) {
      //CALL API TO FETCH DATA
      let params = {
        auction_id: this.state.auctionId,
      }

      this.props.actions.getAuctionDetails(params).then(async resp => {
        if (resp && resp['status'] === 200 && resp['data']) {

          this.showFormFieldsData(resp['data'])
        } else {
          this.props.history.push("/list-auction");
          toast.error(resp['message'])
        }
      }).catch(err => this.setState({ loading: false }))
    } else {
      this.setState({ loading: false })
    }
  }


  /**
   * PUPULATE FORM DATA
   * @param {*} auctionData 
   */
  showFormFieldsData = async (auctionData) => {
    let { formFields } = this.state;

    //PREPARE DATA TO SET IN STATE
    let updateFormData = {
      loading: false,
      formFields: this.state.formFields
    }
    if (auctionData) {
      for (let field of Object.keys(auctionData)) {
        if (typeof formFields[field] !== 'undefined') {
          updateFormData['formFields'][field] = auctionData[field]

        }
      }
    }

    updateFormData['saved_auction_data'] = auctionData

    if (this._isMounted) await this.setState({ ...updateFormData })

  }

  // handleDateChange = (selectedKey) => async e => {

  //   let formFields = { ...this.state.formFields };
  //   formFields[selectedKey] = new Date(e).toISOString() ;
  //   this.setState({ formFields: formFields });

  // }

  submitData = async () => {
    let { formFields } = this.state;

    this.setState({ errors: {} })

    let formError = this.validateFormData();

    if (!Object.keys(formError).length) {

      this.saveAuction(formFields);
    } else {

      if (this._isMounted) this.setState({ errors: formError })
      toast.error(this.props.t('common.enter_required_fields'))
    }

  }

  /**
   * VALIDATION HANDLERS
   */
  validateFormData = () => {

    let formFields = this.state.formFields;
    let formError = {}

    if(formFields['start_date_time']){
      formFields['start_date_time'] = moment(formFields['start_date_time']).seconds(0).milliseconds(0).toISOString();
    }
    if(formFields['end_date_time']){
      formFields['end_date_time'] = moment(formFields['end_date_time']).seconds(0).milliseconds(0).toISOString();
    }

    if (!formFields["name"]) {
      formError['name'] = this.props.t('pages.auction.error.auction_name_is_required')
    }

    if (!formFields["format"]) {
      formError['format'] = this.props.t('pages.auction.error.format_is_required')
    }

    if (!formFields["business_type"]) {
      formError['business_type'] = this.props.t('pages.auction.error.business_type_is_required')
    }

    if (!formFields['region']) {
      formError['region'] = this.props.t('pages.auction.error.region_is_required')
    }
    if (!formFields['province_id']) {
      formError['province_id'] = this.props.t('pages.auction.error.province_is_required')
    }
    if (!formFields['city_id']) {
      formError['city_id'] = this.props.t('pages.auction.error.city_is_required')
    }
    if (!formFields['auction_tl']) {
      formError['auction_tl'] = this.props.t('pages.auction.error.auction_tl_is_required')
    }
    if (!formFields['vehicle_type']) {
      formError['vehicle_type'] = this.props.t('pages.auction.error.vehicle_type_is_required')
    }
    if (!formFields['start_date_time']) {
      formError['start_date_time'] = this.props.t('pages.auction.error.start_date_time_is_required')
    }
    if (!formFields['end_date_time']) {
      formError['end_date_time'] = this.props.t('pages.auction.error.end_date_time_is_required')
    }
    
    if (formFields['start_date_time'] && formFields['end_date_time'] && +new Date((formFields['end_date_time'])).getTime() <= +new Date((formFields['start_date_time'])).getTime()) {
      formError['end_date_time'] = this.props.t('pages.auction.error.start_date_should_be_less_than_end_date')
    }

    return formError;

  }

  saveAuction = async (params) => {
    this.setState({ submitted: true });
   
    if(params["start_date_time"]){
      let start=params["start_date_time"].split(":");
      let start2=start[2].split(".");
        start2[0]='00';
        start[2]=start2.join(".");
      params["start_date_time"]=start.join(":");
    }
   
    //ADD AUCTION ID TO POSTDATA
    if (this.state.auctionId) {
      params['auction_id'] = this.state.auctionId
    }


    AuctionService.saveAuction(params)
      .then(resp => {
        if (resp && resp.status === 200) {
          toast.success(this.props.t('pages.auction.add.saved'))

          this.setState({ submitted: false })
          //IN CASE OF NEW AUCTION ADD CLEAR STATE
          if (!this.state.auctionId) {
            this.setState({ formFields: { name: '', status: 1, start_date_time: null, end_date_time: null, openForAuction: 1 } });
          }

          if(resp.data.data){
            let record = resp.data.data;
            this.props.actions.setAuctionFlag(true);
            this.showFormFieldsData(record)
            let auctionHashId = record.hash_id || record.auction_id;

            this.props.history.push(`/edit-auction/${auctionHashId}`);
          } 

        } else {
          this.setState({ submitted: false });
        }
      })


  }


  /**
   * SHOW ACTIVITY MODAL
   */
  showActivityLog = () => {
    this.setState({ showHistoryModal: !this.state.showHistoryModal })
    document.body.classList.add("overflow-hidden");

  }

  /**
   * CLOSE ACTIVITY MODAL
   */
  historyCloseModal = () => {
    this.setState({ showHistoryModal: !this.state.showHistoryModal })
    document.body.classList.remove("overflow-hidden");

  }

  /**
   * UPDATE PARENT STATE
   * @param {*} formFields 
   */
  updateBasicDetails = (formFields) => {
    this.setState({ formFields })
  }

  /**
   * GET ALL SELECTED INVENTORY FROM CHILD COMPONENT
   * @param {selected inventory list} selectedInventory 
   */
  getSelectedInventory = (selectedInventory) => {
    let getInventoryData = [];

    for (let inventory of selectedInventory) {
      getInventoryData.push({
        id: inventory.id,
        make_name: inventory.make,
        make_id: inventory.make_id,
        model_id: inventory.model_id,
        model_name: inventory.model,
        version_id: inventory.version_id,
        version_name: inventory.carversion,
        reg_no: inventory.reg_no,
        base_price: inventory.car_price,
        expected_price: inventory.expected_price,
        reserved_price: inventory.reserved_price,
        qc_status: inventory.auction_qc_status_value || 0

      })
    }

    this.setState({ formFields: { ...this.state.formFields, auction_inventory: getInventoryData } });
  }

  /**
   * GET ALL SELECTED BUYERS FROM CHILD COMPONENT
   * @param {selected buyer list} selectedBuyers 
   */
  getSelectedBuyers = (selectedBuyers) => {
    let getBuyersIds = selectedBuyers.map(buyer => { return { id: buyer.id, organization: buyer.organization } })

    this.setState({ formFields: { ...this.state.formFields, auction_buyers: getBuyersIds } });

  }

  /**
   * UPDATE INVETORY COUNT AFTER ADDING NEW INVENTORY
   */
  getTotalInventory = (savedInventoryCount) => {
    this.setState({ totalSavedInventory: savedInventoryCount })
  } 
  render() {  
    const { submitted, auctionId, saved_auction_data, totalSavedInventory, loading, showHistoryModal,formFields } = this.state;

    const { BUSINESS_TYPE } = this.props.auctionFilterList || {}; 
 
    const expiredTime = (formFields.end_date_time && (new Date(formFields.end_date_time).getTime() - new Date().getTime()) < 0) ? true:false 
    return (
      <div className="container-fluid-full"> 
        <FullPageLoader show={loading || submitted} />
        <div className="top-sec">


          {
            auctionId
              ?
              <div className="top-heading-action-sec  d-block">
                <span className="edit-txt">{this.props.t('pages.auction.edit.page_title')}</span>
                <h1>{saved_auction_data.name}</h1>
              </div>
              :
              <div className="top-heading-action-sec">
                <h1>{this.props.t('pages.auction.add.page_title')}</h1>
              </div>
          }

        </div>


        <div className="edit-option-tab add-auctio-bg">
          <div >
            <Tab.Container id="" defaultActiveKey={this.props.isAuctionAdd?'second':'first'}>
              <div className="auction-tab">
                {
                  auctionId
                    ?
                    <div className="tab-bg add-auction-tab">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first">{this.props.t('pages.auction.edit.auction_details')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">{this.props.t('pages.auction.edit.auction_inventories')} <span>({totalSavedInventory || 0})</span></Nav.Link>
                          {/* <Nav.Link eventKey="second">Auction Inventories <span>({getSaveInventoryCount })</span></Nav.Link> */}

                        </Nav.Item>
                      </Nav>

                      <div className="action-btn">
                        <button className="btn-line" onClick={this.showActivityLog}>
                          <i className="ic-access_alarms"></i>
                          <span>{this.props.t('pages.auction.edit.view_timeline')}</span>
                        </button>
                      </div>


                    </div>
                    :
                    ""
                }


                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="auction-data-table">
                        <AuctionBasicDetail basicDetails={{ ...this.state, BUSINESS_TYPE }} updateBasicDetails={this.updateBasicDetails} expiredTime={expiredTime}/>

                      <div className="row">
                        <div className="auction-buyer-outer">
                          <ManageBuyers buyersData={{ buyerList: this.props.buyerList || [], savedAuctionBuyers: saved_auction_data['auctionBuyers'] || [] }} passSelectedBuyers={this.getSelectedBuyers} expiredTime={expiredTime}/>
                          {
                            !auctionId
                              ?
                              <AddVechileAuction inventoryData={{ inventoryList: this.props.inventoryList || [] }} listInventory={this.listInventory} passSelectedInventory={this.getSelectedInventory} />
                              :
                              ''
                          }

                        </div>
                      </div>
                      <div className="btn-add-auction">

                        {
                          !submitted
                            ?
                            <button type="submit" className="btn-primary" onClick={this.submitData.bind(this)} disabled={expiredTime}>{this.props.t('pages.auction.add.submit')}</button>
                            :
                            <button type="submit" className="btn-primary" >{this.props.t('common.please_wait')}</button>

                        }
                      </div>
                    </div>
                  </Tab.Pane>

                  {
                    auctionId
                      ?
                      <Tab.Pane eventKey="second">

                        <InventoriesTable auctionData={{ auctionId, saved_auction_data, inventoryList: this.props.inventoryList || [] }} getTotalInventory={this.getTotalInventory} expiredTime={expiredTime}/>
                      </Tab.Pane>
                      :
                      ""
                  }


                </Tab.Content>
              </div>
            </Tab.Container>

            <div className="view-timeline-popup">

              {
                showHistoryModal
                ?
                  <ModalPopup show={showHistoryModal} handleClose={this.historyCloseModal}>
                    <AuctionActivityLog auctionId={auctionId} />
                  </ModalPopup>
                :
                  ''
              }
            </div>
            

          </div>
        </div>

      </div>
    )
  }
}



const mapStateToProps = (state, ownProps) => { 
  return {
    buyerList: state.common.buyerList || {},
    cityStateList: state.common.cityStateList || {},
    inventoryList: state.vehicle.vehicleList || {},
    auctionFilterList: state.common.auctionFilterList || {},
    isAuctionAdd: state.auction.isAuctionAdd || false
  };
}


const mapDispatchToProps = dispatch => {
  return {
    actions: {
      getCityStateList: bindActionCreators(
        CommonMethodActions.getCityStateList,
        dispatch
      ),
      getAuctionDetails: bindActionCreators(
        AuctionActions.getAuctionDetails,
        dispatch
      ),
      getBuyersList: bindActionCreators(
        CommonMethodActions.getBuyersList,
        dispatch
      ),
      getVehicleList: bindActionCreators(
        VehicleActions.getVehicleList,
        dispatch
      ),
      getAuctionFilter: bindActionCreators(
        CommonMethodActions.getAuctionFilter,
        dispatch
      ),
      setAuctionFlag: bindActionCreators(
        AuctionActions.setAuctionFlag,
        dispatch
      ),
    }
  }
}


export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Auction));
