// import './App.css';
// import './webroot/css/main.css';
// import './webroot/css/font-awesome.min.css';
import './App.scss';
import CustomRouter from './routes/route';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import './webroot/css/chat.css';
import { default as config } from './config/config';


function App() {
  const favicon = document.getElementById('favicon');
  const title = document.getElementsByTagName('title')[0];

  if (process.env.REACT_APP_COUNTRY_CODE === 'id') {
    favicon.href = '/favicon.ico';
  } else {
    favicon.href = '/favicon_ph.ico';
  }
  //SET TITLE
  title.innerHTML = config.constants.TITLE;

  return <MainApp />;

}

function MainApp() {
  return (
    <div className="App">
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      <CustomRouter />
    </div>
  );
}

export default App;
