import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { default as config } from '../../../config/config';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { AuctionActions } from '../../../store/action';
import FilterTable from '../../helpers/filter-table';
import DateFormat from 'dateformat';
import AddAuctionCar from './AddAuctionCar';
import ModalPopup from '../../elements/Popup';
import Pagination from '../../elements/Pagination';

class Auction extends Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }
  initialState = () => {
    return {
      // cachedPostData:{},
      // selectedFiltersCache:{},
      loading:false,
      resultList:[],
      pagination:{},
      totalLengthWithoutPagination:0,
      runPaginationFunc: false,
      formAction: 'auctionList',
      tableHeaders: [
        {key:'pages.auction.inventory.car_details'},
        {key:'common.created_at'},
        {key:'common.actions'}
      ],
      filterData:{'auction_id': this.props.match.params.id, page_number: 1},
      tableHtmlData:[],
      showAddCarModal:false

    };
  }

  componentDidMount = async () => {
    this._isMounted = true;

    //CHECK FROM REDUX STATE
    if(this.props.auctionCarsList && this.props.auctionCarsList[this.state.filterData.auction_id]){
      let searchResult = this.props.auctionCarsList[this.state.filterData.auction_id];

      //UPDATE STATE FROM REDUX STATE
      await this.setState({
        loading: false,
        resultList: searchResult.data || [], 
        pagination: (searchResult && searchResult.pagination) ? searchResult.pagination : {}, 
        totalLengthWithoutPagination: ((searchResult &&searchResult['pagination'] && searchResult['pagination']['total']) || 0), 
        runPaginationFunc: true
      });

      this.generateTableData();

    }else{
      //CALL ACTION
      this.getAuctionCarsList({page_number: 1})
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  getAuctionCarsList = async (data={}) => { 
    if(this._isMounted) await this.setState({loading: true});
    
    data = {...this.state.filterData, ...data} 
    let searchResult = await this.props.actions.getAuctionCarsList(data)

    if(this._isMounted){

      let auctionCarsData = (searchResult && searchResult.data  ? searchResult.data : [] );

      let finalResult = (data.page_number===1) ? auctionCarsData : [...this.state.resultList, ...auctionCarsData]
      await this.setState({
        loading: false,
        resultList: finalResult || [], 
        pagination: (searchResult && searchResult.pagination) ? searchResult.pagination : {}, 
        totalLengthWithoutPagination: ((searchResult &&searchResult['pagination'] && searchResult['pagination']['total']) || 0), runPaginationFunc: true
      });
    }

    this.generateTableData();
  }


  getFilterList = async (searchParams) => {
      let postData = this.state.filterData;
      postData['page_number'] = searchParams['page_number'];

      this.getAuctionCarsList(postData);

  }

  //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
  callbackFunction = async (childData) => {
    if(this._isMounted) await this.setState({runPaginationFunc: childData['runPaginationFunc']});
  }

  handleRequestData = async (data) => {
    let page = data.page_number;

    if(this._isMounted){

      await this.setState({
        formAction:'', 
        loading: true,
        leadList: [],
        page, 
        pagination: {}, 
        totalLengthWithoutPagination:  0, 
        runPaginationFunc: false
      });
      
      this.getFilterList(data);
        
    }
  }

  removeAuctionCar = async (auctionCarId) => {
    
    if(window.confirm(this.props.t('common.are_you_sure'))){
      this.props.actions.removeAuctionCar({auctionCarId}).then(result=>{
        if(result.data && result.data.status===200){
          //REMOVE FROM LISTING
          // resultList = resultList.filter(cars=> cars.hash_id!=auctionCarId);
          // if(this._isMounted) this.setState({resultList, totalLengthWithoutPagination: totalLengthWithoutPagination-1})

          // this.getAuctionCarsList({page_number: 1});
          this.getInitialListing()

          
        }
      });
    }
  
  }

  generateTableData = async() => {
    let html = []

    const { resultList, tableHeaders } = this.state;
    
    for(let [key, list] of resultList.entries()){
      html.push(
        <tr key={key}>
          <td>
            {
              list.car_details
              ?
                list.car_details.make + ' '+ list.car_details.modelVersion + ' '+ list.car_details.uc_colour  + ' '+ list.car_details.make_year + ' '+ list.car_details.fuel_type + ' (' + list.car_details.display_price + ')'
              :
                ''
            }
          </td>
          <td>{
                      DateFormat(list.created_at, config.constants.dateFormatDDMMMYYYYHIS)
              }
          </td>
          <td>
              <a href="#!" onClick={()=>this.removeAuctionCar(list.hash_id)} title="Remove car">  <i className="fa fa-trash"></i> </a>
          </td>
          
      </tr>)
    }

    if(!resultList.length){
      
      html.push(<tr key="1"><td colSpan={Object.keys(tableHeaders).length} className="text-center">{this.props.t('common.no_records_found')}</td></tr>)
    }

  
    if(this._isMounted) this.setState({tableHtmlData: html})
    return html;

  }

  generateButtons = () => {
    let btnsArray = []

    //SHOW ADD CAR BUTTON
    btnsArray.push(<button key="1"  onClick={() => this.btnActions('addCar')} className="btn btn-success mrgin-r-10"  >{this.props.t('pages.auction.manage.add_car')}</button>)

    return btnsArray;
  }

  btnActions = (action) => { 
    if(action === 'addCar' && this._isMounted){
      this.setState({showAddCarModal: !this.state.showAddCarModal})
    }
  }


  closeModal = () =>{
    if(this._isMounted) this.setState({showAddCarModal: false})
  }

  updateAuctionInventoryList = () => {
    this.getInitialListing()
  }

  getInitialListing = () => {
    if(this._isMounted) this.setState({page: 1})
    this.getAuctionCarsList({page_number: 1})
  }
  
  render() {
    const { filterData, showAddCarModal, tableHtmlData, formAction, loading, totalLengthWithoutPagination, resultList, page, runPaginationFunc, tableHeaders } = this.state;

    
    return (
      <div className="container">
          <div className="savefilterwrap"> 
              <h1 className="mrg-rauto">{this.props.t('pages.auction.manage.title')}</h1>
          </div>

          <Pagination paginateData={{ page,runPaginationFunc, loading: loading, 'tableData': resultList, totalLengthWithoutPagination} } action={formAction} sendRequestDataToParent={this.handleRequestData} />

          {/* <FilterBox filterBox={{ page,runPaginationFunc, loading: loading, 'tableData': resultList, totalLengthWithoutPagination} } action={formAction} sendRequestDataToParent={this.handleRequestData} /> */}

          <div className="result-wrap">
              <FilterTable filterTableData={{ tableHeaderBtns: this.generateButtons(), formAction, loading: loading, totalLengthWithoutPagination, tableData: tableHtmlData, page, runPaginationFunc, tableHeaders }} parentCallback={this.callbackFunction}/>
          </div>

          {
            showAddCarModal
            ?
              <ModalPopup id="auctionCarAddPopup" className="modal modal-lg" title=""  modalClose={this.closeModal}>
            
                <AddAuctionCar modalClose={this.closeModal} auctionId={filterData.auction_id} updateAuctionInventoryList={this.updateAuctionInventoryList}/>
            
              </ModalPopup>
            :
            ''
          }
          

      </div>

      

    )
  }
}

const mapStateToProps = state => {
  return {
    auctionCarsList: (state.auction && state.auction.auctionCarsList) || {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions:{
      getAuctionCarsList: bindActionCreators(
        AuctionActions.getAuctionCarsList,
        dispatch
      ),
      removeAuctionCar: bindActionCreators(
        AuctionActions.removeAuctionCar,
        dispatch
      )
    }
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Auction)));