import React, { Component, createRef } from 'react';
import Select from 'react-select';
import Modal from '../../elements/PopupModal';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { AuctionActions } from './../../../store/action';
import { toast } from 'react-toastify';
import MultiSelect from '../../common/MultiSelect'

const Template = [
    { value: 'Change in Auction Time', label: 'Change in Auction Time' },
    { value: 'other', label: 'Others' }
];

class SMSPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sellerOption: null,
            smsPopup: { show: false },
            sellerList: props.sellerList,
            selectedSeller: [],
            message: '',
            toremoveSellerId: [],
            loading: false
        };
        this.sms_to =  createRef();
        this.templt_div = createRef();
    }

    handleChange = (event) => {
        this.setState({ message: event.target.value });
    }
    handleChangeTemplate = (TemplateOption) => {
        this.setState({ TemplateOption });
        this.templt_div.current.classList.add('active-label')
    };
    hideModalSmsPopup = () => {
        this.props.closeModalSmsPopup();
        //RESET SELECTED VALUES
        this.resetSelectedValues();

    }
    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ smsPopup: { ...this.state.smsPopup, ...props.smsPopup }, sellerList: props.sellerList });
    }
    onSelect(key, selectedList, selectedItem) {
        this.setState({ selectedSeller: selectedList });
        if(selectedList && selectedList.length){
            this.sms_to.current.classList.add('active-label')
        }else{
            this.sms_to.current.classList.remove('active-label')
        }
    }
    removeSeller = (selllerid) => {
        let removedIds = this.state.toremoveSellerId;
        removedIds.push(selllerid);
        this.setState({ toremoveSellerId: removedIds })
        let selectedSellers = this.state.selectedSeller;
        let sellerListAfterRemove = selectedSellers.filter(seller => !removedIds.includes(seller.value));
        this.setState({ selectedSeller: sellerListAfterRemove });

    }
    sendSMS = () => {
        this.setState({loading: true})
        let postdata = {};
        postdata.seller = this.state.selectedSeller;
        postdata.template = (this.state.TemplateOption)?this.state.TemplateOption.value:'';
        postdata.message = this.state.message;
        this.props.actions.submitSMSForm(postdata).then(res=>{     
            this.setState({loading: false});
            this.resetSelectedValues();

            if(res.status===200){
                toast.success(res.message);
                this.setState({selectedSeller:[], TemplateOption:'', message:''})
                this.props.closeModalSmsPopup();
            }
        }).catch(err=> {
            this.setState({loading: true})
            toast.error(err);
        });

    }

    resetSelectedValues = () => {
        this.setState({selectedSeller:[], TemplateOption:'', message:'', loading: false});
        this.sms_to.current.classList.remove('active-label')
        this.templt_div.current.classList.remove('active-label')
    }

    /**
     * 
     * ON SELECT CLICK HANDLER 
     */
     onSelectOpen  = (el) => {
        let selectedDiv = el+'_div';
        //ADD CLASS ON CLICK
        if(this[selectedDiv]) this[selectedDiv].current.classList.add('active-label')

    }

    /**
     * ON SELECT MENU HIDE HANDLER 
     */
    onSelectClose = (el) => {
        let selectedDiv = el+'_div';
        //REMOVE IF EMPTY
        if(this[selectedDiv] && ( (el === 'templt' && !this.state.TemplateOption )) )  this[selectedDiv].current.classList.remove('active-label')

    }

    render() {
        const { show } = this.state.smsPopup;
        const { TemplateOption, sellerList, selectedSeller, message, loading } = this.state;
        let list = [];
        if (selectedSeller && selectedSeller) {
            list = selectedSeller;
        }
        return (
            <div>
                <div>
                    <div className="Auction-share-detail-popup">
                        <Modal show={show} handleClose={this.hideModalSmsPopup} >
                            <div>
                                <div className="modal-header">
                                    <h2>SMS</h2>
                                </div>
                                <div className="modal-body" >
                                    <div className="mail-form-sec sms-email-sec">
                                        <fieldset className="multiselect-dropDown buyer-dropdown">
                                            <div className="material animation-effect" ref={this.sms_to}>
                                                
                                                    <MultiSelect
                                                        isMulti={true}
                                                        options={sellerList}
                                                        placeholder={""}
                                                        value={selectedSeller}
                                                        onChange={this.onSelect.bind(this, 'seller')}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                    />
                                                <label data-label={this.props.t('pages.auction.popup.sms.to')} className="form-label sms-to-label"></label>
                                            </div>
                                        </fieldset>
                                        <div className="select-item-list">
                                            <ul>
                                                {list.map((item, index) => (
                                                    (index <= 2) ?
                                                        <li>
                                                        <span> {item.label}</span>
                                                        <i className="ic-clearclose" onClick={() => this.removeSeller(item.value)}></i></li>
                                                        : (+index === 3) ? <li className="more-data"><span> + {(list.length - 3)} more</span> </li> : ''

                                                )
                                                )}
                                            </ul>
                                        </div>
                                        <fieldset className="buyer-dropdown form-select-bx">
                                            <div className="material animation-effect" ref={this.templt_div} >
                                                <Select
                                                    value={TemplateOption}
                                                    onChange={this.handleChangeTemplate}
                                                    onFocus={()=>this.onSelectOpen('templt')}
                                                    options={Template}
                                                    placeholder=""
                                                    classNamePrefix='auction-dropdown'
                                                    onMenuClose={()=>this.onSelectClose('templt')}

                                                />
                                                <label data-label={this.props.t('pages.auction.popup.sms.template')}  className="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset className="">
                                            <div className="material">

                                                <textarea className="form-input" placeholder=" " value={message} onChange={this.handleChange}></textarea>
                                                <label data-label="Message" className="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <div className="btn-send">
                                                {
                                                    loading
                                                    ?
                                                        <button className="btn-primary" disabled>Sending...</button>
                                                    :
                                                        <button className="btn-primary" onClick={this.sendSMS}>Send</button>

                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // getBlockedClosedLeads: state.lead.makeModelVersionList
})

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            submitSMSForm: bindActionCreators(
                AuctionActions.submitSMSForm,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(SMSPopup)));