import React, { Component } from 'react';
import TopActionBtn from '../components/TopActionBar'
import TopSearchBar from '../components/TopSearchBar'
import VechileListData from '../components/VechileListData'

class BankAuction extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
        };
    }

    render() {

        return (
            <div className="container-fluid-full">
                <div className="top-sec">
                    <TopActionBtn />
                </div>
                <div className="top-search-bar">
                    <TopSearchBar />
                </div>
                <div className="Vechile-table-outer">
                    <VechileListData />
                </div>
            </div>
        )
    }
}
export default BankAuction