import MasterService from './MasterService';

export const AuctionService = {
  ...MasterService,

  saveAuction(postData) {
    return this.post('/auction/saveAuction', postData);
  },
  getInventory(postData) {
    return this.post('/auction/getInventory', postData);
  },
  saveAuctionCar(postData) {
    return this.post('/auction/saveAuctionCar', postData);
  },
  getAuctionList(postData) {
    return this.post('/auction/getAuctionList', postData);
  },
  getAuctionCarsList(postData) {
    return this.post('/auction/getAuctionCarsList', postData);
  },
  removeAuctionCar(postData) {
    return this.post('/auction/removeAuctionCar', postData);
  },
  getAuctionDetails(postData) {
    return this.post('/auction/getAuctionDetails', postData);
  },
  getAuctionActivity(postData) {
    return this.post('/auction/getAuctionActivity', postData);
  },
  updateAuctionInventory(postData) {
    return this.post('/auction/updateAuctionInventory', postData);
  },
  sendSMSToBuyer(postData){
    return this.post('/auction/sendSms', postData);
  },
  sendEmailToBuyer(postData){
    return this.post('/auction/shareAuction', postData);
  },
  auctionCityList(){
    return this.get('/auction/getAuctionCityList');
  },
  getAuctionBiddenCarsList(postData) {
    return this.post('/auction/auctionBidCars', postData);
  },
  getBidListByCarId(postData) {
    return this.post('/auction/getBidListByCarid', postData);
  },
  cancelBid(postData) {
    return this.post('/auction/updateBidStatus', postData);
  },
  removeAuctionCarIds(postData) {
    return this.post('/auction/deleteAuctionCarIds', postData);
  },
  postAuctionDetail(postData) {
    return this.post('/auction/postAuctionDetail', postData);
  },
  getAuctionCarBuyers(postData) {
    return this.post('/auction/getAuctionCarBuyers', postData);
  }
  
}
export default AuctionService;