import React, { Component } from 'react';
import Pagination from './pagination'
import ShareDetailPopup from './AuctionShareDetailPopup'



class LiveAuctionTable extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            ViewTimeLine: false,
            SharePopup: false,
        };
    }
    showModalViewTimeLine = () => {
        this.setState({ ViewTimeLine: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalViewTimeLine = () => {
        this.setState({ ViewTimeLine: false });
        document.body.classList.remove("overflow-hidden");
    }

    showModalSharePopup = () => {
        this.setState({ SharePopup: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalSharePopup = () => {
        this.setState({ SharePopup: false });
        document.body.classList.remove("overflow-hidden");
    }

    render() {
        return (
            <div>
                <div className="table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th>Bucket ID</th>
                                <th>Auction Name</th>
                                <th>Region</th>                               
                                <th>format</th>
                                <th>Vehicle Type</th>
                                <th>End Date & Time</th>
                                <th>Time Remaining</th>
                                <th>Vehicle Left</th>
                                <th>Auctions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1" onClick={this.showModalSharePopup}></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history" onClick={this.showModalViewTimeLine}></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>BMW Financial Service 4W All India</td>
                                <td>North</td>
                                
                                <td>Open</td>
                                <td>4 Wheeler</td>
                                <td>30/07/21,  7:30 PM</td>
                                <td>3 d, 12 hr</td>
                                <td>5 of 30</td>
                                <td>
                                    <div className="action-btn">
                                        <button type="button" className="btn-line">
                                            Edit/Manage
                                        </button>
                                        <button type="button" className="btn-line">
                                            View
                                        </button>
                                        <i className="ic-email1"></i>
                                        <i className="ic-chat"></i>
                                        <i className="ic-Call-history"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="loader loader-default is-active loader-main" data-text="loading"></div>
                    <div className="pagination-outer">
                        <Pagination />

                    </div>

                </div>


                <div className="view-timeline-popup">
                    <Modal show={this.state.ViewTimeLine} handleClose={this.hideModalViewTimeLine} >
                        <div className="modal-header">
                            <h2>Timeline</h2>
                        </div>
                        <div className="modal-body" >
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>
                <div>

                    <div>
                        <div className="Auction-share-detail-popup">
                            <Modal show={this.state.SharePopup} handleClose={this.hideModalSharePopup} >
                                <ShareDetailPopup />
                            </Modal>
                        </div>
                    </div>

                </div>
            </div>




        );
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};


export default LiveAuctionTable;