import { AuctionService } from '../../service';
import {AuctionConstants} from '../constants/auction.constants';

export const AuctionActions = {
    getInventory,
    saveAuctionCar,
    getAuctionList,
    getAuctionCarsList,
    removeAuctionCar,
    getAuctionDetails,
    getAuctionActivity,
    submitSMSForm,
    submitEmailForm,
    updateAuctionInventory,
    auctionCityList,
    getAuctionBidCars,
    getBidListByCarId,
    cancelBid,
    setAuctionFlag,
    unSetAuctionFlag,
    getPostAuctionDetail,
    getAuctionCarBuyers
};

function getInventory(postData) {

  let auctionInventoryList = {}

  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.getInventory(postData)
        .then(
          inventoryList => {
            if(inventoryList && inventoryList.data &&  inventoryList.data.status === 200){

              if(!auctionInventoryList[postData['auction_id']]){
                auctionInventoryList[postData['auction_id']] = inventoryList.data.data || {}
              }

              dispatch(success(auctionInventoryList));
              resolve(inventoryList.data);
            }
            else{
              resolve((inventoryList && inventoryList.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          inventoryListError => {
            reject(inventoryListError);
            dispatch(failure(inventoryListError));
          }
        );
    });
  };

  function success(auctionInventoryList) { return { type: AuctionConstants.GET_INVENTORY_SUCCESS, auctionInventoryList } }
  function failure(inventoryListError) { return { type: AuctionConstants.GET_INVENTORY_FAILURE, inventoryListError } }

}


function saveAuctionCar(postData) {
  
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.saveAuctionCar(postData)
        .then(
          resp => {
            if(resp && resp.data &&  resp.data.status === 200){
              dispatch(success(resp.data));
              resolve(resp.data);
            }
            else{
              resolve((resp && resp.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };

  function success(resp) { return { type: AuctionConstants.SAVE_AUCTION_CAR_SUCCESS, resp } }
  function failure(error) { return { type: AuctionConstants.SAVE_AUCTION_CAR_FAILURE, error } }

}

function getAuctionList(postData) {
  
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.getAuctionList(postData)
        .then(
          resp => {
            if(resp && resp.data &&  resp.data.status === 200){
              dispatch(success(resp.data));
              resolve(resp.data);
            }
            else{
              resolve((resp && resp.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };

  function success(resp) { return { type: AuctionConstants.GET_AUCTION_LIST_SUCCESS, resp } }
  function failure(error) { return { type: AuctionConstants.GET_AUCTION_LIST_FAILURE, error } }

}

function getAuctionCarsList(postData) { 
  // let auctionCarsListResp = {} 
  // auctionCarsListResp[postData['auction_id']] = {} 
  
  return dispatch => {
    return new Promise((resolve, reject) => {

      AuctionService.getAuctionCarsList(postData)
      .then(
        auctionCarsList => {
            if(auctionCarsList && auctionCarsList.data && auctionCarsList.data.status === 200){
              // auctionCarsListResp[postData['auction_id']] = auctionCarsList.data
              // dispatch(success(auctionCarsListResp));
              resolve(auctionCarsList.data);
            }
            else{
              resolve((auctionCarsList && auctionCarsList.data) ||{});
              // dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            // dispatch(failure(error));
          }
        );
    });
  };

  // function success(auctionCarsList) { return { type: AuctionConstants.GET_AUCTION_CARS_LIST_SUCCESS, auctionCarsListResp } }
  // function failure(error) { return { type: AuctionConstants.GET_AUCTION_CARS_LIST_FAILURE, error } }

}

function removeAuctionCar(postData) {
  
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      let result = await AuctionService.removeAuctionCar(postData)
              resolve(result);
    });
  };

}


function getAuctionDetails(postData) {
  
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.getAuctionDetails(postData)
        .then(
          details => {
            if(details && details.data &&  details.data.status === 200){
              
              resolve(details.data);
            }
            else{
              resolve((details && details.data) ||{});
            }
          },
          auctionDataError => {
            reject(auctionDataError);
          }
        );
    });
  };
}

function getAuctionActivity(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.getAuctionActivity(postData)
        .then(
          getLog => {
           resolve(getLog.data || {})
          }
        ).catch(
          err => {
            reject(err);
          }
        )
    });
  };
}


function updateAuctionInventory(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.updateAuctionInventory(postData)
        .then(
          inventory => {
           resolve(inventory.data || {})
          }
        ).catch(
          err => {
            reject(err);
          }
        )
    });
  };
}

function submitSMSForm(postData){
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.sendSMSToBuyer(postData)
        .then(
          resp => {
            if(resp && resp.data &&  resp.data.status === 200){
              dispatch(success(resp.data));
              resolve(resp.data);
            }
            else{
              resolve((resp && resp.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };
  function success(resp) { return { type: AuctionConstants.SEND_SMS_SUCCESS, resp } }
  function failure(error) { return { type: AuctionConstants.SEND_SMS_FAIL, error } }
}

function submitEmailForm(postData){
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.sendEmailToBuyer(postData)
        .then(
          resp => {
            if(resp && resp.data &&  resp.data.status === 200){
              dispatch(success(resp.data));
              resolve(resp.data);
            }
            else{
              resolve((resp && resp.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };
  function success(resp) { return { type: AuctionConstants.SEND_SMS_SUCCESS, resp } }
  function failure(error) { return { type: AuctionConstants.SEND_SMS_FAIL, error } }
}

function auctionCityList(){
  return dispatch => {
    return new Promise((resolve, reject)=>{
      AuctionService.auctionCityList()
      .then(
        resp => {
          if(resp && resp.data &&  resp.data.status === 200){
            dispatch(success(resp.data));
            resolve(resp.data);
          }
          else{
            resolve((resp && resp.data) ||{});
            dispatch(failure('not_found'));
          }
        },
        error => {
          reject(error);
          dispatch(failure(error));
        }
      );
    });
  };
  function success(resp) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_SUCCESS, resp } }
  function failure(error) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_FAIL, error } }
}

function getAuctionBidCars(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.getAuctionBiddenCarsList(postData)
        .then(
          resp => {
            if (resp && resp.data && resp.data.status === 200) {
              // dispatch(success(resp.data));
              resolve(resp.data);
            } else {
              resolve((resp && resp.data) || {});
              // dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            // dispatch(failure(error));
          }
        )
    });
  }
  // function success(resp) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_SUCCESS, resp } }
  // function failure(error) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_FAIL, error } }

}

function getBidListByCarId(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.getBidListByCarId(postData)
        .then(
          resp => {
            if (resp && resp.data && resp.data.status === 200) {
              // dispatch(success(resp.data));
              resolve(resp.data);
            } else {
              resolve((resp && resp.data) || {});
              // dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            // dispatch(failure(error));
          }
        )
    });
  }
  // function success(resp) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_SUCCESS, resp } }
  // function failure(error) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_FAIL, error } }

}

function cancelBid(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.cancelBid(postData)
        .then(
          resp => {
            if (resp && resp.data && resp.data.status === 200) {
              // dispatch(success(resp.data));
              resolve(resp.data);
            } else {
              resolve((resp && resp.data) || {});
              // dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            // dispatch(failure(error));
          }
        )
    });
  }
  // function success(resp) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_SUCCESS, resp } }
  // function failure(error) { return { type: AuctionConstants.GET_AUCTION_CITY_LIST_FAIL, error } }
}

function setAuctionFlag(data){ 
  return {
      type: AuctionConstants.AUCTION_ADD_FLAG,
      resp:data
  }
}

function unSetAuctionFlag(data){ 
  return {
      type: AuctionConstants.AUCTION_UNSET_FLAG,
      resp:data
  }
}
 


function getPostAuctionDetail(postData) { 
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.postAuctionDetail(postData)
        .then(
          resp => {
            if (resp && resp.data && resp.data.status === 200) { 
              resolve(resp.data);
            } else {
              resolve((resp && resp.data) || {}); 
            }
          },
          error => {
            reject(error); 
          }
        )
    }); 
  }
}


function getAuctionCarBuyers(postData) { 
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuctionService.getAuctionCarBuyers(postData)
        .then(
          resp => {
            if (resp && resp.data && resp.data.status === 200) { 
              resolve(resp.data);
            } else {
              resolve((resp && resp.data) || {}); 
            }
          },
          error => {
            reject(error); 
          }
        )
    }); 
  }
}
