import React from 'react';
import { Route, Redirect } from "react-router-dom";
import secureStorage from './../config/encrypt';
import * as HELPER from './../config/helper';

const PrivateRoute = ({ component: Component, ...rest }) => {

    let authUserInfo = secureStorage.getItem('authUserInfo');

    //CHECK USER IS AUTHORIZED
    let isAuthorized = (authUserInfo && authUserInfo['headerMenu']) ? HELPER.isUserHasAccessPage() : true
    let dashboardRedirect = (authUserInfo && authUserInfo.dashboardRedirect) || ''; //DASHBOARD REDIRECT ROUTE CHECK
    if(!isAuthorized){
        return <Route><Redirect to="/vehicle-list" /></Route>
    }
    let path=rest.path?rest.path:"" ;

    return (
      <Route
        {...rest}
        
        render={props =>
          secureStorage.getItem('authUserInfo')
          ? 
            isAuthorized
            ?
              <Component {...props} key={(path==='/vehicle-list')?new Date().getTime():""} />
            :
              <Redirect to={"/"+dashboardRedirect} />
          :
            <Redirect to="/login" />
        }
      />
    );
};

export default PrivateRoute;