import React, {Component} from 'react';
import * as HELPER from './../../config/helper';

class CurrencyInputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            type: '',
            name: '',
            label:'',
            dataerror:'',
            maxLength:'',
            value:'',
            onChange: '',
            className:'form-control'
        }
    }

    changeHandel = (event)=>{
        if (typeof this.props.onChange === 'function') {
            if(!isNaN(event.target.value)){
            this.setState({value: event.target.value})

            this.props.onChange(event);
        }
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedState = prevState;
        if(prevState.id !== nextProps.inputProps.id || (nextProps.inputProps.value !== prevState.value)/*&& nextProps.inputProps.value*/){
            updatedState = {...nextProps.inputProps, value: nextProps.inputProps.value ? HELPER.addCurrencyFormatting(nextProps.inputProps.value) : ''};
            
            return updatedState;
        }
        // } else if(prevState.id==='' && nextProps.inputProps.id){
        //     updatedState = {...nextProps.inputProps, value: HELPER.addCurrencyFormatting(nextProps.inputProps.value)};
        //     return updatedState;
        // }else if(nextProps.inputProps.value!=0){
        //     updatedState = {value: HELPER.addCurrencyFormatting(nextProps.inputProps.value)}; 
        //     return updatedState;
        // }
        return null;
    }

    chkValFocusOut = (event) => {
        if(event.target.value){
            event.target.value =  HELPER.addCurrencyFormatting(event.target.value);
            this.setState({value: event.target.value})
            this.props.onChange(event)
    
        }
        
    }
    chkValFocus = (event) => {
        if(event.target.value){
            event.target.value = HELPER.removeCurrencyFormatting(event.target.value)
            this.setState({value: event.target.value})
    
            this.props.onChange(event)
        }
        
    }
        
    render() {
        let props = this.props;
        return (

                <>  
                    <input {...this.state} onBlur={this.chkValFocusOut} onFocus={this.chkValFocus} onChange={this.changeHandel} className={props.inputProps.className || this.state.className} autoComplete="off" />          
                </>
        );
    }
}

export default CurrencyInputField;
