const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
      <div className={showHideClassName}>
          <section className='modal-main'>
              {children}
              <button
                  onClick={handleClose}
                  className="close_icn"
              >
                  <i className="ic-clearclose"></i>
              </button>
          </section>
      </div>
  );
};
export default Modal;
