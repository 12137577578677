import { VehicleConstants } from '../constants/vehicle.constants';

export default function VehicleReducer(state='', action) {
  switch (action.type) {

    case VehicleConstants.GET_VEHICLE_LIST_SUCCESS:
      return {
        ...state,
        vehicleList: action.vehicleList,
        vehicleListPagination: action.vehicleList.pagination
      }

    case VehicleConstants.RESET_VEHICLE_LIST:
      return {
        ...state,
        vehicleList: undefined,
        vehicleListPagination: undefined
      }
  

    default:
      return state
  }
}