import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import BasicDetail from './AuctionBasicDetail';
import ManageBuyers from './ManageBuyer'
import InventoriesTable from './InventoriesTable'
import MultiSelect from '../../common/MultiSelect'

const BankAuction = [
    { value: 'Tata Harrier Camzo (83HDD945) 12BG 453, Delhi', label: 'Tata Harrier Camzo (83HDD945) 12BG 453, Delhi' },
    { value: 'Tata Harrier Camzo (83HDD945) 12BG 453, Nodia', label: 'Tata Harrier Camzo (83HDD945) 12BG 453, Nodia' },
    { value: 'Tata Harrier Camzo (83HDD945) 12BG 453, Jaipur', label: 'Tata Harrier Camzo (83HDD945) 12BG 453, Jaipur' },
];


class EditAuction extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            AddVehile: false,
        };
    }

    handleChangeBankAuction = (BankAuctionOption) => {
        this.setState({ BankAuctionOption }
        );
    };
    showModalAddVehile = () => {
        this.setState({ AddVehile: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalAddVehile = () => {
        this.setState({ AddVehile: false });
        document.body.classList.remove("overflow-hidden");
    }
    render() {
        const { BankAuctionOption } = this.state;
        return (
            <div className="container-fluid-full">
                <div class="top-sec">
                    <div class="top-heading-action-sec  d-block">
                        <span className="edit-txt">Edit Auction</span>
                        <h1>BMW Financial Service 4W All India</h1>
                    </div>
                </div>

                <div className="edit-option-tab add-auctio-bg">
                    <div >
                        <Tab.Container id="" defaultActiveKey="second">
                            <div className="auction-tab">
                                <div className="tab-bg">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Aucton Details</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Auction Inventories <span>(46)</span></Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                </div>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="auction-data-table">
                                            <BasicDetail />

                                            <div className="row">
                                                <div className="auction-buyer-outer">
                                                    <ManageBuyers />
                                                </div>
                                            </div>
                                            <div className="btn-add-auction">
                                                <button className="btn-primary">
                                                    Add Auction
                                                </button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="top-right-btn">
                                            <fieldset class="search-bx search-top-bar">
                                                <input id="" type="text" placeholder="Search " name="" class="form-input" />
                                                <i className="ic-search"></i>
                                            </fieldset>
                                            <div class="action-btn">
                                                <button class="btn-line" onClick={this.showModalAddVehile}>
                                                    <i class="ic-add"></i>
                                                    <span>Add Vehicle</span>
                                                </button>
                                            </div>
                                        </div>
                                        <InventoriesTable />
                                    </Tab.Pane>

                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
                <div className="view-timeline-popup add-vechile-popup">
                    <Modal show={this.state.AddVehile} handleClose={this.hideModalAddVehile} >
                        <div className="modal-header">
                            <h2>Add Vehicle</h2>
                        </div>
                        <div className="modal-body" >
                            <fieldset class=" multiselect-dropDown">
                                <div class="material">
                                    <label className="option-txt">Select from Approved List</label>
                                   
                                    <MultiSelect
                                        isMulti={true}
                                        options={BankAuction}
                                        placeholder="Select from List"
                                        value={BankAuctionOption}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </fieldset>

                        </div>
                    </Modal>
                </div>
            </div >



        );
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>

            </section>
        </div>
    );
};






export default EditAuction;