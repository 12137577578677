import React, { Component } from 'react';
import Pagination from './pagination'


class VechileListData extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {


        };
    }

    render() {
        return (

            <div className="vechile-table-data">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </th>
                            <th>Vehicle Name</th>
                            <th> VID</th>
                            <th>Reg. No.</th>
                            <th>Seller Name</th>
                            <th>Location</th>
                            <th>QC Status</th>
                            <th className="sorting sorting_asc">Created On</th>
                            <th>Created By</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li className="custom-control custom-checkbox">
                                    <input id="all" type="checkbox" className="custom-control-input" />
                                    <label for="all" className="custom-control-label"></label>
                                </li>
                            </td>
                            <td>Ford Freestyle Titanium P</td>
                            <td>90Y3E1</td>
                            <td>5468121212</td>
                            <td><span class="name-txt">Ravinder Singh</span>

                            </td>
                            <td>Lajpat Nagar, Delhi</td>
                            <td>Pending</td>
                            <td> 12/6/2021, 4:30 PM</td>
                            <td>Sumit Kumar</td>
                            <td>
                                <button className="btn-view">
                                    Edit
                                    </button>
                            </td>
                        </tr>

                    </tbody>

                </table>
                <div className="pagination-outer">
                    <Pagination />

                </div>
            </div>



        );
    }
}


export default VechileListData; 