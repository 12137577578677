import React, { Component } from 'react';
import AuctionTab from './AuctionTab'

class AuctionListAdmin extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
        };
    }

    render() {

        return (
            <div className="container-fluid-full">
                <div className="top-sec">
                    <div className="top-heading-action-sec">
                        <h1>Auctions</h1>
                        <div className="action-btn">
                            <button className="btn-line">
                                <i className="ic-add"></i>
                                <span>Add Auction</span>
                            </button>

                        </div>
                    </div>
                </div>

                <div className="tab-outer">
                    <AuctionTab />
                </div>
                <div className="loader-full">
                    <div className=" loader loader-default is-active loader-main" data-text="loading"></div>
                </div>
            </div>
        )
    }
}
export default AuctionListAdmin