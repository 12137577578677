export const TITLE = 'AUCTION :: OTO';
export const LOGIN_PAGE_LOGO = require('../../webroot/images/oto-logo.svg');

export const CURRENCY_SYMBOL = 'Rp';
export const CURRENCY_FORMAT = '.';

export const language = [
    { value: '1', label: 'English',iso_code:'en' , key:'ENG'},
    { value: '2', label: 'Indonesian',iso_code :'id',key:'ID'}
];

// export const AUCTION_STATUS = [
//     { value: '1', label: 'Active' },
//     { value: '0', label: 'Inactive' },
// ];

export const CORE_URL = 'http://localhost:9003/'

export const dateFormatDMY = 'dd-MM-yyyy';
export const dateFormatDMYHIS = 'dd-MM-yyyy HH:mm:ss';
export const dateFormatDDMMMYYYYHIS = 'dd mmm yyyy hh:MM:ss tt'
export const dateFormatDDMMMYYYYHI = 'dd mmm yyyy, hh:MM tt'
export const dateFormatDDMMMYYYYHM = 'dd/mm/yyyy, hh:MM tt'
export const dateFormatMDY = 'MM-dd-yyyy';
export const dateFormatMY = 'MM/YYYY';
export const dateFormatYMD = 'dd/mm/yyyy, hh:MM tt'

export const VEHICLE_TYPE = [
    // { value: '1', label: '2 Wheeler' },
    // { value: '2', label: '3 Wheeler' },
    { value: '3', label: '4 Wheeler' },
    // { value: '4', label: 'Passenger Carrying Vehicle' },
    // { value: '5', label: 'Commercial Vehicle' }
];

export const FourWheeler = '3';
export const vehicleApproved = '4'; 
export const MONTH_DIFF =3;
export const VEHICLE_ADD =50;

export const VEHICLE_DETAILS_UPDATED = [
    { value: '1', label: 'Updated' },
    { value: '2', label: 'Created' },
];

export const MONTHS = [
    { value: '1', label: 'January'},
    { value: '2', label: 'February'},
    { value: '3', label: 'March'},
    { value: '4', label: 'April'},
    { value: '5', label: 'May'},
    { value: '6', label: 'June'},
    { value: '7', label: 'July'},
    { value: '8', label: 'August'},
    { value: '9', label: 'September'},
    { value: '10', label: 'October'},
    { value: '11', label: 'November'},
    { value: '12', label: 'December'}
]

export const INVENTORY_IMAGE_FOLDER = 'inventory_car_images'
export const INVENTORY_CSV_FOLDER = 'auction_inventory'

export const REGION = [
    { value: '1', label: 'North' },
    { value: '2', label: 'South' },
    { value: '3', label: 'East' },
    { value: '4', label: 'West' }
];

export const FORMAT_TYPE = [
    { value: '1', label: 'Open' },
    { value: '2', label: 'Private' },
    { value: '3', label: 'Closed' }
];

export const MAKE_ID_KEY = 'mk_id'
export const MODEL_ID_KEY_FOR_VERSION = 'md_id'
export const MODEL_ID_KEY_FOR_MODEL = 'p_m_id'
export const MODEL_VALUE_KEY = 'id'

export const AUCTION_STATUS = [
    {
        "value": 'live',
        "label": "Live"
    },
    {
        "value": 'upcoming',
        "label": "Upcoming"
    },
    {
        "value": 'expired',
        "label": "Expired"
    }
    
]

export const SOCKET_CONNECT_URL = {
    "local": "http://localhost:9003",
    "beta": "http://auction-ph.oto.com/",
    "prod": "http://localhost:9003"
}
