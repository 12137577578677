import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import Notifaction from './Notification'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserActions } from "../../store/action";
import { withTranslation } from 'react-i18next';
import secureStorage from '../../config/encrypt';
import { default as config } from '../../config/config';
import * as HELPER from '../../config/helper';

class Navigation extends Component {
    _isMount = false
    constructor(props) {
        super(props)
        this.state = {
            langOption: config.constants.language
        }
    }

    componentDidMount = () => {
        this._isMount = true
    }

    /**
     * Logout
     */
    authLogout = (e) => {
        e.preventDefault();
        this.props.actions.authLogout();
    }

    /**
     * Active Nav on route change
     */
    checkActiveBtn = (routeArray) => {
        let curPath = window.location.pathname.replace(/\//g, '');
        return routeArray.includes(curPath);
    }

    getCurrentClass = (menuData) => {
        let currentPath = window.location.pathname.split('/');
        let activeClass = '';
        currentPath = currentPath.filter(path => path) //FILTER URL PATH

        //SHOW ACTIVE CLASS 
        if (currentPath.length) {
            let stringMenuData = JSON.stringify(menuData) //CONVERT INTO STRING

            let checkActiveUrl = HELPER.isUserHasAccessPage(currentPath[0], stringMenuData)

            if (checkActiveUrl) activeClass = 'active';

        } else {
            //IF NOTHING IN URL DEFAULT DASHBOARD IS ACTIVE
            if (menuData.name === 'Dashboard') {
                activeClass = 'active';
            }
        }
        return activeClass;
    }

    //CREATE URL LINKS
    linkUrl = (menu) => {
        let menuLink = '#'
        if (menu['action']) {
            menuLink = '/' + menu['action']
        }

        return menuLink

    }

    componentWillUnmount = () => {
        this._isMount = false
    }

    checkVisibleMenu = (menus) => {
        return menus.filter(menu => menu.show_in_menu)
    }

    changeLanguage = (lang) => {
        if (this._isMount) {
            secureStorage.setItem('lang', lang);
            this.props.i18n.changeLanguage(lang)
        }
    }

    render() {
        const { loggedIn, loggingIn, siteSettings } = this.props;
        const langOption = this.state.langOption;
        const setLang = secureStorage.getItem('lang'); //PRE SELECTED LANG
        let selectedLang = langOption.filter(lang => lang.iso_code === setLang)
        const authUserInfo = secureStorage.getItem('authUserInfo') || {};
        const menuHeaders = authUserInfo && authUserInfo.headerMenu ? JSON.parse(authUserInfo.headerMenu) : [];
        const visibleMenus = this.checkVisibleMenu(menuHeaders)
        let defaultPage = (authUserInfo && authUserInfo.login_type ==='seller')?'/vehicle-list':'/';  
        return (
            <div className="header-outer-fixed">
                <div className="container-fluid" >
                    <div className="header-main">
                        <div className="logo">
                            <NavLink to={defaultPage}>
                                {siteSettings && siteSettings.language === 'id' ? "OTO Auction" : "CARMUDI Auction"}
                            </NavLink>
                        </div>
                        <div className="right-panel-naviganion menupanel">
                            <div className="nav">
                                <ul>
                                    {
                                        visibleMenus && (visibleMenus).length
                                            ?
                                            (visibleMenus).map((el, i) => {

                                                return (<li className={el.childMenu.length && !el['action'] ? 'dropdownmenu' : ''} key={i}>

                                                    <NavLink to={this.linkUrl(el)} activeClassName={this.getCurrentClass(el)}>
                                                        {this.props.t('menu.' + (el.name).toLowerCase().replace(' ','_'))}
                                                    </NavLink>

                                                    <ul className="submenu" key={i}>
                                                        {
                                                            this.checkVisibleMenu(el['childMenu']).map((elm, j) => {
                                                                let childMenuName = elm['name'].replace(' ', '_').toLowerCase()
                                                                return (<li className="level2" key={j}>

                                                                    <NavLink to={this.linkUrl(elm)} className={this.getCurrentClass(elm)}>{this.props.t('menu.' + childMenuName)}</NavLink>
                                                                    {/* <ul className="submenu" >
                                                                        
                                                                        {
                                                                            
                                                                            this.checkVisibleMenu(elm['childSubMenu']).map((elmn,k)=>{
                                                                                let subMenuName = elmn['name'].replace(' ', '_').toLowerCase()

                                                                                    return (<li key={k}><NavLink to={this.linkUrl(elmn)}>{this.props.t('menu.'+subMenuName)}</NavLink></li>)
                                                                            })
                                                                        }
                                                                    </ul> */}
                                                                </li>)
                                                            })
                                                        }
                                                    </ul>
                                                </li>)

                                            })
                                            :
                                            (
                                                <li></li>
                                            )
                                    }
                                </ul>
                            </div>
                            {
                                (loggedIn || loggingIn)
                                    ?
                                    <>
                                        <li className="notification-btn">
                                            <Notifaction />
                                        </li>

                                        <div className="user-detail ">
                                            <ul>

                                                <li className="dropdownmenu"><NavLink to="#">
                                                    <span className="userarc">
                                                        {(authUserInfo && authUserInfo.authUser && authUserInfo.authUser.name ? authUserInfo.authUser.name[0].toUpperCase() : '')}
                                                    </span>
                                                    <div className="username">
                                                        <span className="user-type">{(authUserInfo.authUser ? authUserInfo.authUser.name : '')}</span>
                                                        <span className="user-degintion">{((authUserInfo.dealer_data && authUserInfo.dealer_data[0] && authUserInfo.dealer_data[0]['gcd_code']) ? ' (' + authUserInfo.dealer_data[0]['gcd_code'] + ')' : '')}</span>
                                                    </div>
                                                </NavLink>
                                                    <ul className="submenu">
                                                        <li><NavLink to="/logout" >{this.props.t('menu.logout')}</NavLink></li>

                                                    </ul>
                                                </li>

                                            </ul>
                                        </div>
                                    </>

                                    :
                                    ''
                            }

                            {
                                langOption.length >= 1
                                ?
                                <div className="language">
                                    <ul>
                                        <li className="dropdownmenu">
                                            <NavLink to="#">
                                                {selectedLang && selectedLang[0] && selectedLang[0]['key']}
                                            </NavLink>
                                            {

                                                    langOption.length >= 1
                                                        ?
                                                        <ul className="submenu">

                                                            {
                                                                langOption.length >= 1
                                                                    ?
                                                                    langOption.map((lang, index) => {
                                                                        return <li key={index}>
                                                                            <NavLink to="#" onClick={() => this.changeLanguage(lang.iso_code)}>{lang.key}</NavLink>
                                                                        </li>
                                                                    })
                                                                    :
                                                                    ''
                                                            }
                                                        </ul>
                                                        :
                                                        ''

                                            }
                                            
                                        </li>

                                        </ul>
                                    </div>
                                    :
                                    ''
                            }


                        </div>

                    </div>

                </div >
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn,
    loggingIn: state.auth.loggingIn,
    siteSettings: state.auth.siteSettings

});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            authLogout: bindActionCreators(
                UserActions.logout,
                dispatch
            )
        }
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Navigation));