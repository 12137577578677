import React, { Component } from 'react';

class BiddingDetailPopup extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }



    render() {

        return (
            <div>
                <div className="modal-header">
                    <div className="heading-txt">
                        <h2>Bidder Details</h2>
                        <span>Ford Freestyle Titanium - HR 87B 9994</span>
                    </div>
                    <div className="bid-cancel-download-btn">
                        <button className="btn-line">
                            <i className="ic-clearclose"></i>
                            <span> Cancel Bid</span>
                        </button>
                        <button className="btn-line">
                            <i className="ic-pdf"></i>
                            <span> Download List</span>
                        </button>
                    </div>
                </div>
                <div className="modal-body" >
                    <div className="biding-table-data">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <li className="custom-control custom-checkbox">
                                            <input id="all" type="checkbox" className="custom-control-input" />
                                            <label for="all" className="custom-control-label"></label>
                                        </li>
                                    </th>
                                    <th>Bidder Details</th>
                                    <th>Deposit Amount</th>
                                    <th>Remaining Buyer Limit</th>
                                    <th>Bid Amount</th>
                                    <th>Date & Time</th>
                                    <th>Bid Left</th>
                                    <th>Bid Intermediary</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li></td>
                                    <td>
                                        <div className="bidder-detail">
                                            <span>Rohan Verma</span>
                                            <i className="ic-info-icon1"></i>
                                        </div>
                                    </td>
                                    <td>Rp 500.000</td>
                                    <td>Rp 34.500.000</td>
                                    <td>Rp 450.000</td>
                                    <td>20/08/2021, 9:30 PM</td>
                                    <td>10</td>
                                    <td>Rohit Sharma</td>
                                    <td>
                                        <button className="btn-line">
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li></td>
                                    <td>
                                        <div className="bidder-detail">
                                            <span>Rohan Verma</span>
                                            <i className="ic-info-icon1"></i>
                                        </div>
                                    </td>
                                    <td>Rp 500.000</td>
                                    <td>Rp 34.500.000</td>
                                    <td>Rp 450.000</td>
                                    <td>20/08/2021, 9:30 PM</td>
                                    <td>10</td>
                                    <td>Rohit Sharma</td>
                                    <td>
                                        <button className="btn-line">
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li></td>
                                    <td>
                                        <div className="bidder-detail">
                                            <span>Rohan Verma</span>
                                            <i className="ic-info-icon1"></i>
                                        </div>
                                    </td>
                                    <td>Rp 500.000</td>
                                    <td>Rp 34.500.000</td>
                                    <td>Rp 450.000</td>
                                    <td>20/08/2021, 9:30 PM</td>
                                    <td>10</td>
                                    <td>Rohit Sharma</td>
                                    <td>
                                        <button className="btn-line">
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li></td>
                                    <td>
                                        <div className="bidder-detail">
                                            <span>Rohan Verma</span>
                                            <i className="ic-info-icon1"></i>
                                        </div>
                                    </td>
                                    <td>Rp 500.000</td>
                                    <td>Rp 34.500.000</td>
                                    <td>Rp 450.000</td>
                                    <td>20/08/2021, 9:30 PM</td>
                                    <td>10</td>
                                    <td>Rohit Sharma</td>
                                    <td>
                                        <button className="btn-line">
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li></td>
                                    <td>
                                        <div className="bidder-detail">
                                            <span>Rohan Verma</span>
                                            <i className="ic-info-icon1"></i>
                                        </div>
                                    </td>
                                    <td>Rp 500.000</td>
                                    <td>Rp 34.500.000</td>
                                    <td>Rp 450.000</td>
                                    <td>20/08/2021, 9:30 PM</td>
                                    <td>10</td>
                                    <td>Rohit Sharma</td>
                                    <td>
                                        <button className="btn-line">
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li></td>
                                    <td>
                                        <div className="bidder-detail">
                                            <span>Rohan Verma</span>
                                            <i className="ic-info-icon1"></i>
                                        </div>
                                    </td>
                                    <td>Rp 500.000</td>
                                    <td>Rp 34.500.000</td>
                                    <td>Rp 450.000</td>
                                    <td>20/08/2021, 9:30 PM</td>
                                    <td>10</td>
                                    <td>Rohit Sharma</td>
                                    <td>
                                        <button className="btn-line">
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>




        );
    }
}



export default BiddingDetailPopup;