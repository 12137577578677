import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { NavLink } from 'react-router-dom';


function FooterAuction(props) {

  const { language } = props.siteSettings || {};
  let currentPath = window.location.pathname;

    if(!currentPath.includes('login')){
      return (
        <footer className="auction-footer">
            <div className="container-fluid-full">
                <div className="footer-txt">
                    <span className="copyright-txt">{ language === 'id' ? props.t('site_content.oto_copyright') : props.t('site_content.carmudi_copyright')}</span>
                    <ul className="footer-link">
                        <li className=""><NavLink to="/">{props.t('site_content.terms_conditions')}</NavLink> </li>
                        <li className=""><NavLink to="/">{props.t('site_content.privacy_policy')}</NavLink> </li>
                    </ul>
                </div>
            </div>
        </footer>
      )
    }else{
      return ('')
    }
}

const mapStateToProps = state => {
  return {
     siteSettings: state.auth.siteSettings
  }
}

export default withTranslation('common') (withRouter(connect(mapStateToProps)(FooterAuction)));