import React, { Component } from 'react';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from '../../../config/config';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import InputField from '../../elements/InputField';
import { VehicleActions, CommonMethodActions } from '../../../store/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalPopup from './../../elements/PopupModal';
import VehicleActivityLog from '../components/VehicleActivityLog';
import * as HELPER from './../../../config/helper';
import queryString from 'query-string'
import { NavLink } from "react-router-dom";
import ConfirmationModal from '../../common/ConfirmationModal';
import PostAuctionStatusFields from './PostAuctionStatusFields'; 
import {CustomValueContainer} from '../../common/CustomValueContainer'
import CurrencyInputField from '../../elements/CurrencyInputField';  

class SaveVehicle extends Component {
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.dealer_id_div = React.createRef()
    this.vehicle_type_div = React.createRef()
    this.make_id_div = React.createRef()
    this.model_id_div = React.createRef()
    this.version_id_div = React.createRef()
    this.fuel_type_div = React.createRef()
    this.transmission_div = React.createRef()
    this.make_month_div = React.createRef()
    this.make_year_div = React.createRef()
    this.body_type_div = React.createRef()
    this.uc_colour_id_div = React.createRef()
    this.reg_place_city_id_div = React.createRef()
    this.reg_month_div = React.createRef()
    this.reg_year_div = React.createRef()
    this.reg_valid_month_div = React.createRef()
    this.reg_valid_year_div = React.createRef()
    this.ownership_div = React.createRef()
    this.owner_type_div = React.createRef()
    this.vehicle_lying_city_div = React.createRef()
    this.vehicle_lying_state_div = React.createRef()
    this.qc_status_div = React.createRef()
    this.details_updated_status_div = React.createRef()
    this.inspection_status_div = React.createRef()

  }

  initialState = () => {
    return {
      selectedOption: null,
      formFields: {
        stock_used_car_id: '',
        id: '',
        dealer_id: '',
        make_id: '',
        model_id: '',
        version_id: '',
        vehicle_type: 3, //DEFAULT CAR
        fuel_type: '',
        transmission: '',
        make_month: '',
        make_year: '',
        body_type: '',
        uc_colour_id: '',
        engine_capacity: '',
        reg_no: '',
        odometer_rating: '',
        engine_number: '',
        chasis_number: '',
        reg_place_city_id: '',
        reg_month: '',
        reg_year: '',
        reg_valid_month: '',
        reg_valid_year: '',
        ownership: '',
        owner_type: '',
        km_driven: '',
        vehicle_lying_state: '',
        vehicle_lying_city: '',
        expected_price: '',
        reserved_price: '',
        car_price: '', //BASE PRICE
        auction: 'A',

        seller_name: '',
        contact_person_name: '',
        mobile: '',
        email: '',

        qc_status: '',
        details_updated_status: '',
        inspection_status: '',
        remarks: '',
        uc_colour: '',

        auction_status: 1,
        category_id: 1,
        car_body_type: '',
        send_to_inspection: false,

        post_auction_status: null,
        post_auction_status_text: null,
        follow_up_date: null,
        purchased_date: null,
        purchased_amount: null,
        purchased_buyer_id: null
      },
      vehicleId: this.props.match.params.id,
      errors: {},
      submitted: false,
      loading: true,
      inventoryFilters: this.props.inventoryFilters || [],
      mmvList: this.props.mmvList || {},
      makeList: [],
      modelList: [],
      versionList: [],
      imagesList: [],
      redirecTo: null,
      showHistoryModal: false,
      sellerList: [],
      cityStateList: {},
      cityList: [],
      vehicleLyingCity: [],
      saved_vehicle_data: {},
      showSendToInspectionConfirmationModal: false
    };


  }

  componentDidMount = async () => {

    this._isMounted = true
    //IF NOT IN STATE THEN LOAD INVENTORY FILTERS
    if (!this.state.inventoryFilters.length) {
      await this.getFilters();
    }
    //IF NOT IN STATE THEN LOAD MMV LIST
    if (!Object.keys(this.state.mmvList).length) {
      await this.getMMVList();
    }

    //GET SELLER LIST FROM STATE
    if (!this.props.sellerList || !Object.keys(this.props.sellerList).length) {
      let filter = {};
      filter['parent_dealer_type'] = [5];
      this.props.actions.getSellersList({ filter: filter })
    }

    //FETCH CITY/STATE
    await this.getCityStateList();

    //AUCTION VEHICLE FILTERS
    await this.auctionVehicleFilters();

    //GET VEHICLE DETAILS
    await this.getVehicleDetails();

  }

  /**
   * BASIC DATA FOR ADD VEHICLE
   */
  auctionVehicleFilters = async () => {
    if (this.props.auctionVehicleFilterList && !Object.keys(this.props.auctionVehicleFilterList).length) {
      this.props.actions.getAuctionVehicleFilters()
    }
  }

  /**
   * GET CITY/STATE LIST
   */
  getCityStateList = async () => {

    //CITY/STATE LIST
    let cityList = [], stateList = []

    let cityStateData = this.props.cityStateData

    //CHECK IF NOT IN STATE THEN CALL API
    if (!this.props.cityStateList || !Object.keys(this.props.cityStateList).length) {
      cityStateData = await this.props.actions.getCityStateList()
    } else {
      cityStateData = this.props.cityStateList
    }

    if (cityStateData && cityStateData.city) {
      //PREPARE CITY ARRAY FOR SELECTBOX
      cityList = cityStateData.city.map(city => {
        return { value: city.id, label: city.name, state_id: city.state_id }
      });
      if (this._isMounted) this.setState({ cityList });
    }

    if (cityStateData && cityStateData.state) {
      //PREPARE STATE ARRAY FOR SELECTBOX
      stateList = cityStateData.state.map(city => {
        return { value: city.id, label: city.name }
      });

      if (this._isMounted) this.setState({ stateList });
    }

  }

  /**
   * GET VEHICLE DETAILS
   */
  getVehicleDetails = () => {
    let { mmvList } = this.state;
    let queryParam = queryString.parse(this.props.location.search);

    //FETCH AUCTION DETAILS
    if (this.state.vehicleId && Object.keys(mmvList).length) {
      //CALL API TO FETCH DATA
      let params = {
        car_hash: this.state.vehicleId,
        user_id: queryParam['d_id'] || undefined
      }

      this.props.actions.getVehicleDetails(params).then(async resp => {
        if (resp && resp['status'] === 200) {
          this.showFormFieldsData(resp['data'])
        } else {
          window.location = '/vehicle-list'
        }
      }).catch(err => {
        // this.setState({ loading: false })
        this.props.updateParentState({loading: false})

      })
    } else {
      // this.setState({ loading: false })
      this.props.updateParentState({loading: false})


    }
  }

  showFormFieldsData = async (vehicleData) => {
    let { mmvList, formFields, inventoryFilters } = this.state;
    let { MODEL_ID_KEY_FOR_VERSION, MAKE_ID_KEY } = config.constants;
    let { QC_STATUS } = this.props.auctionVehicleFilterList;

    //PREPARE DATA TO SET IN STATE
    let updateFormData = {
      loading: false,
      formFields: { ...formFields }
    }
    if (vehicleData) {
      for (let field of Object.keys(vehicleData)) {
        if (typeof formFields[field] !== 'undefined') {
          updateFormData['formFields'][field] = vehicleData[field]

          if(field === 'qc_status'){
            updateFormData['formFields']['saved_qc_status'] = vehicleData[field]
          } else if(field === 'send_to_inspection'){
            updateFormData['formFields']['saved_send_to_inspection'] = vehicleData[field]
          }

          if (vehicleData['car_body_type']) {
            //GET VALUE 
            let bodyList = this.props.inventoryFilters ? this.props.inventoryFilters.filter(filter => filter.key === 'body_type') : []
            let carBodyType = (bodyList && bodyList[0] && bodyList[0]['list']) ? bodyList[0]['list'].filter(body => body.label.toLowerCase() === vehicleData['car_body_type'].toLowerCase()) : [];

            if (carBodyType && carBodyType.length) {
              updateFormData['formFields']['body_type'] = carBodyType[0]['value'];

            }
          }

          //CHECK FOR COLOR 
          //AFTER INSPECTION COLOR WILL GET FROM other_colour KEY
          if(field === 'uc_colour_id' && !updateFormData['formFields'][field] && vehicleData['other_colour'] && inventoryFilters && Object.keys(inventoryFilters).length){
            let colorList  = inventoryFilters.filter(filter => filter.key === 'color')

            let selectedColor = (colorList && colorList[0] && colorList[0]['list'] && colorList[0]['list'].filter(color=> (color.label).toLowerCase() === vehicleData['other_colour'].toLowerCase()))  || ''
            updateFormData['formFields'][field] = (selectedColor && selectedColor[0] && selectedColor[0]['value']) || ''
          }

        }
      }
    }

    let modelList = mmvList['model'] ? mmvList['model'].filter(model => model[MAKE_ID_KEY] === updateFormData.formFields.make_id) : []
    let editModelId = (this.props.match.params.id) ? 'p_m_id':MODEL_ID_KEY_FOR_VERSION;
    let versionList = mmvList['version'] ? mmvList['version'].filter(model => model[editModelId] === updateFormData.formFields.model_id) : []

    updateFormData['modelList'] = modelList;
    updateFormData['versionList'] = versionList;
    updateFormData['imagesList'] = vehicleData && vehicleData['usedCarImage'] ? vehicleData['usedCarImage'] : [];
    updateFormData['saved_vehicle_data'] = vehicleData;
    updateFormData['formFields']['car_body_type'] = vehicleData['car_body_type'];
    updateFormData['formFields']['description'] = vehicleData['description'];
    updateFormData['formFields']['send_to_inspection'] = (updateFormData['formFields']['send_to_inspection'] >0) ? true: false;
    updateFormData['formFields']['certification_url'] = vehicleData['certification_url'] || '';
  
    if(QC_STATUS && QC_STATUS.length && vehicleData['qc_status'] && (''+vehicleData['qc_status']).trim() !== ''){
      
      updateFormData['formFields']['qc_status'] = vehicleData['qc_status'];

      let findQcStatus = QC_STATUS.filter(status => +status.value === vehicleData['qc_status']);
      if(findQcStatus && findQcStatus.length){
        updateFormData['formFields']['qc_status_text'] = findQcStatus[0]['label'];
      }
    }

    if (vehicleData['vehicle_lying_state']) {
      this.handleChange('vehicle_lying_state', { value: vehicleData['vehicle_lying_state'] })
    }

    if (this._isMounted) await this.setState({ ...updateFormData })

    this.props.updateParentState({loading: false, imagesList: updateFormData['imagesList'], saved_vehicle_data: updateFormData['saved_vehicle_data']})

  }

  /**
   * UNMOUNT COMPONENT
   */
  componentWillUnmount = () => {
    this._isMounted = false
  }

  /**
   * GET INVENTORY FILTERS
   */
  getFilters = async () => {
    this.props.actions.getFilter().then(resp => {
      if (resp && resp.length && this._isMounted && Array.isArray(resp) ){
        this.setState({ inventoryFilters: resp })
      } else {
        this.setState({ inventoryFilters: [] })

      }
    })
  }

  /**
   * GET MMV LIST
   */
  getMMVList = async () => {
    return new Promise((resolve, reject) => {
      this.props.actions.getMMVList().then(resp => {
        if (Object.keys(resp).length && this._isMounted) this.setState({ mmvList: resp }); resolve(true);
      })
    });

  }

  /**
  * Input Handler
  * @param e (synthatic element)
  */
  handleInputChange = type => async (e) => {
    let { formFields, errors } = this.state;

    if (type === 'car_price' || type === 'reserved_price' || type==='expected_price') {
      // let regNum = /^\d.+$/;
      // if (regNum.test(e.target.value)) {
      //   formFields[type] = (e ? ((!e.target) ? e : e.target.value) : '');
      // } else if (!regNum.test(e.target.value) && !e.target.value.length) {
      //   formFields[type] = '';
      // }
      if (!isNaN(e.target.value)) {
        formFields[type] = e.target.value;
      }

    } else {
      formFields[type] = (e ? ((!e.target) ? e : e.target.value) : '');

    }


    //REMOVE FIELD ERROR
    if (errors[type]) delete errors[type]

    if (this._isMounted) {
      this.setState({ formFields, errors });
    }

  }

  /**
   * SELECT BOX HANDLER
   */
  handleChange = (key, event) => {

    let { formFields, modelList, versionList, mmvList, vehicleLyingCity, cityList, errors } = this.state;
    let { MODEL_ID_KEY_FOR_VERSION, MAKE_ID_KEY } = config.constants;

    //IN CASE OF MAKE, LOAD MODELS
    if (key === 'make_id') {
      modelList = (event && mmvList['model']) ? mmvList['model'].filter(model => model[MAKE_ID_KEY] === event.value) : []
      formFields['model_id'] = ''
      formFields['version_id'] = ''
      versionList = []
    }
    //IN CASE OF MODEL, LOAD VERSIONS
    if (key === 'model_id') {
      formFields['version_id'] = ''
      versionList = (event && mmvList['version']) ? mmvList['version'].filter(model => model[MODEL_ID_KEY_FOR_VERSION] === event.value) : []
    }

    //GET City ON STATE SELECT
    if (key === 'vehicle_lying_state' && event) {
      formFields['vehicle_lying_city'] = '';

      vehicleLyingCity = cityList.filter(city => +city.state_id === +event.value);
    }

    if (key === 'inspection_status') {
      formFields['certification_status'] = event ? '' + event.value : undefined
    }

    if (key === 'qc_status') {
      formFields['qc_status_text'] = event ? '' + event.label : undefined
      formFields['qc_status_local'] = event ? event.value : undefined
    }

    if (key === 'version_id') {

      formFields['body_type'] = event.c_b_type;
      formFields['engine_capacity'] = event.engine_capacity;
      formFields['fuel_type'] = event.f_type;
      formFields['transmission'] = event.tms;
    }

    if(key === 'send_to_inspection'){ 
      formFields['send_to_inspection'] = formFields['send_to_inspection'] ? false : true;

      if(formFields['send_to_inspection']){
        this.setState({showSendToInspectionConfirmationModal: true})
      }else{
        this.setState({showSendToInspectionConfirmationModal: false})

      }
      formFields['send_to_inspection_local'] = formFields['send_to_inspection'];

    } else{
      
      formFields[key] = event ? '' + event.value : '';

    }


    //REMOVE FIELD ERROR
    if (errors[key]) delete errors[key]

    this.setState({ formFields: formFields, modelList, versionList, vehicleLyingCity });

    //SELECT BOX DESIGN CLASS
    let getSelectRef = key + '_div';
    if (this[getSelectRef] && this[getSelectRef].current && !this[getSelectRef].current.classList.contains('active-label')) {
      this[getSelectRef].current.classList.add('active-label')
    } else {
      if (!formFields[key] && this[getSelectRef])
        this[getSelectRef].current.classList.remove('active-label')
    }

  };

  /**
   * SUBMIT FORM HANDLER
   */
  submitData = () => {
    let formFields = this.state.formFields || {};

    let formError = this.validateFormData(formFields);

    if (!Object.keys(formError).length) {

      let updatedParams = Object.assign({}, formFields);
      delete updatedParams['saved_qc_status']
      delete updatedParams['qc_status_local']
      delete updatedParams['send_to_inspection_local']
      delete updatedParams['saved_send_to_inspection']

      if(updatedParams['car_price']){
        updatedParams['car_price'] = HELPER.removeCurrencyFormatting(updatedParams['car_price'])
      }
      if(updatedParams['expected_price']){
        updatedParams['expected_price'] = HELPER.removeCurrencyFormatting(updatedParams['expected_price'])
      }
      if(updatedParams['reserved_price']){
        updatedParams['reserved_price'] = HELPER.removeCurrencyFormatting(updatedParams['reserved_price'])
      }

      this.saveVehicle(updatedParams);
    } else {
      if (this._isMounted) this.setState({ errors: formError })
      toast.error(this.props.t('common.enter_required_fields'))
      // toast.error(errorHtml)
    }

  }

  /**
   * VALIDATION HANDLERS
   */
  validateFormData = (params) => {

    let formFields = this.state.formFields;

    this.setState({ errors: {} })
    let errors = {}
    let emaialRegex = /\S+@\S+\.\S+/;

    if (!params['dealer_id']) {
      errors['dealer_id'] = this.props.t('pages.vehicle.add.dealer_is_required')
    }
    if (!params['make_id']) {
      errors['make_id'] = this.props.t('pages.vehicle.add.make_is_required')
    }
    if (!params['model_id']) {
      errors['model_id'] = this.props.t('pages.vehicle.add.model_is_required')
    }
    if (!params['version_id']) {
      errors['version_id'] = this.props.t('pages.vehicle.add.version_id_required')
    }
    // if (!params['make_month']) {
    //   errors['make_month'] = this.props.t('pages.vehicle.add.make_month_required')
    // }
    if (!params['make_year']) {
      errors['make_year'] = this.props.t('pages.vehicle.add.make_year_required')
    }
    if (!params['uc_colour_id']) {
      errors['uc_colour_id'] = this.props.t('pages.vehicle.add.color_required')
    }
    if (!params['reg_no']) {
      errors['reg_no'] = this.props.t('pages.vehicle.add.reg_no_required')
    }
    // if (!params['reg_place_city_id']) {
    //   errors['reg_place_city_id'] = this.props.t('pages.vehicle.add.reg_place_required')
    // }
    // if (!params['reg_valid_month']) {
    //   errors['reg_valid_month'] = this.props.t('pages.vehicle.add.reg_valid_month_required')
    // }
    // if (!params['reg_valid_year']) {
    //   errors['reg_valid_year'] = this.props.t('pages.vehicle.add.reg_valid_year_required')
    // }
    if (!params['km_driven']) {
      errors['km_driven'] = this.props.t('pages.vehicle.add.km_driven_required')
    }
    // if (!params['reg_year']) {
    //   errors['reg_year'] = this.props.t('pages.vehicle.add.reg_year_required')
    // }
    // if (!params['reg_month']) {
    //   errors['reg_month'] = this.props.t('pages.vehicle.add.reg_month_required')
    // }

    if (params['email'] && !emaialRegex.test(params['email'])) {
      errors['email'] = this.props.t('pages.vehicle.add.invalid_email')
    }
    if (!params['expected_price'] || params['expected_price'] < 1000) {
      errors['expected_price'] = this.props.t('pages.vehicle.add.expected_price_exist')
    }

    if (params['reserved_price'] && params['reserved_price'] < 1000) {
      errors['reserved_price'] = this.props.t('pages.vehicle.add.reserved_price_exist')
    }

    if (!params['car_price'] || params['car_price'] < 1000) {
      errors['car_price'] = this.props.t('pages.vehicle.add.car_price_exist')
    }

  
    if(formFields['post_auction_status'] && +formFields['post_auction_status'] === 5){

      if(!formFields['purchased_date']){
        errors['purchased_date'] = this.props.t('pages.vehicle.add.purchased_date_required')
      }

      if(!formFields['purchased_buyer_id']){
        errors['purchased_buyer_id'] = this.props.t('pages.vehicle.add.purchased_buyer_id_required')

      }
    }

    formFields['reg_year'] = (formFields['reg_year'] && formFields['reg_year'] > 0) ? formFields['reg_year'] : '';
    
    if (formFields['reg_year'] || formFields['make_year'] || formFields['reg_valid_year'] ) {

      if (new Date(formFields['reg_year'] + '-' + formFields['reg_month']).getTime() < new Date(formFields['make_year'] + '-' + formFields['make_month']).getTime()) {
        if(formFields['reg_month'] && formFields['make_month'] && formFields['reg_year'] && formFields['make_year'] && formFields['reg_year'] === formFields['make_year'] && formFields['reg_month'] < formFields['make_month']){ errors['reg_month'] = this.props.t('pages.vehicle.add.reg_month_should_be_greater_make_month'); }
        if(formFields['reg_year'] && formFields['reg_year'] !== formFields['make_year']){ errors['reg_year'] = this.props.t('pages.vehicle.add.reg_month_should_be_greater_make_year'); }
      }

      if (formFields['reg_valid_month'] || formFields['reg_valid_year']) {
        
        if (new Date(formFields['reg_valid_year'] + '-' + formFields['reg_valid_month']).getTime() < new Date(formFields['make_year'] + '-' + formFields['make_month']).getTime()) {
          if(formFields['reg_valid_month'] && formFields['make_month'] && formFields['reg_valid_month'] < formFields['make_month'] && formFields['reg_valid_year'] && formFields['make_year'] && formFields['reg_valid_year'] === formFields['make_year']){ errors['reg_valid_month'] = this.props.t('pages.vehicle.add.reg_valid_month_is_smaller_then_make_month'); }
          if(formFields['reg_valid_year'] && formFields['reg_valid_year'] !== formFields['make_year']){ errors['reg_valid_year'] = this.props.t('pages.vehicle.add.reg_valid_year_is_smaller_then_make_year'); } 
        }
        else if (new Date(formFields['reg_valid_year'] + '-' + formFields['reg_valid_month']).getTime() < new Date(formFields['reg_year'] + '-' + formFields['reg_month']).getTime()) {
          if(formFields['reg_valid_month'] && formFields['reg_month'] && formFields['reg_valid_month'] !== formFields['reg_month']) errors['reg_valid_month'] = this.props.t('pages.vehicle.add.reg_valid_month_is_smaller_then_reg_month');
          if(formFields['reg_valid_year'] && formFields['reg_year'] && formFields['reg_valid_year'] !== formFields['reg_year']) errors['reg_valid_year'] = this.props.t('pages.vehicle.add.reg_valid_year_is_smaller_then_reg_year')
        }
      }

    }

    return errors;

  }

  /**
   * SAVE VEHICLE FUNCTION
   */
  saveVehicle = async (params) => {
    this.setState({ submitted: true });
    this.props.updateParentState({submitted: true})

    //ADD VEHICLE ID IN CASE OF UPDATE
    if (this.state.vehicleId) {
      params['vehicle_id'] = this.state.vehicleId
      params['stock_used_car_id'] = this.state.saved_vehicle_data.id
    }

    //REMOVE EMPTY VALUES FROM POSTDATA
    for (let field of Object.keys(params)) {
      if (!params[field] && field !== 'send_to_inspection') {
        delete params[field]
      }
      else { //PASS VALUES AS STRING
        params[field] = (field === 'send_to_inspection') ? params[field] : '' + params[field]
      }
    }

    //DELETE EXTRA FIELDS
    delete params['car_body_type']
    delete params['certification_url']

    //CALL SAVE VEHICLE ACTION
    this.props.actions.saveVehicle(params)
      .then(resp => {

        params['saved_qc_status'] = params['qc_status']
        params['qc_status_local'] = params['qc_status']
        params['send_to_inspection_local'] = params['send_to_inspection']
        params['saved_send_to_inspection'] = params['send_to_inspection']

        this.setState({ formFields: {...this.state.formFields, ...params} })
        if (resp && resp.data && resp.data.status === 200) {
          toast.success(resp.data.message)
          //IF FILES EXISTS & newFileUpload IS TRUE
           
          if (this.props.componentData && this.props.componentData.files && this.props.componentData.files.length && this.props.componentData.newFileUpload) {
            this.props.uploadVehicleImages( {...params, ...resp.data.data} )
          }
          //IF ALL SAVED FILES REMOVED
          else if (this.props.componentData.files && !this.props.componentData.files.length && this.state.saved_vehicle_data && this.state.saved_vehicle_data.usedCarImage && this.state.saved_vehicle_data.usedCarImage.length) {

            this.props.confirmationModalResponse({response: true})

          } else if(resp.data.data){ 
            this.props.history.push(`/edit-vehicle/${resp.data.data.car_id_hash}`);
          }

          //IF REAUCTIONED THEN REMOVE POST AUCTION FIELDS
          // if(params['post_auction_status'] === '8') {
          //   document.getElementById('postAuctionFields').style.display = 'none';
          // }

          this.setState({submitted: false})
          this.props.updateParentState({submitted: false})


        } else {
          this.setState({submitted: false})
          this.props.updateParentState({submitted: false})
        }
      })
  }


  /**
   * SHOW ACTIVITY MODAL
   */
  showActivityLog = () => {
    this.setState({ showHistoryModal: !this.state.showHistoryModal })
    document.body.classList.add("overflow-hidden");

  }

  /**
   * CLOSE ACTIVITY MODAL
   */
  historyCloseModal = () => {
    this.setState({ showHistoryModal: !this.state.showHistoryModal })
    document.body.classList.remove("overflow-hidden");

  }

  /**
   * CHECK SEND TO INSPECTION STATUS TO ENABLE/DISABLE
   */
  checkSendToInspectionStatus = (params) => {
    let disabled = false

    if(params['saved_send_to_inspection']){
      disabled = true
    }


    return disabled;
  }

  /**
   * CONFIRMATION SEND TO INSPECTION 
   */
  confirmationSendToInspectionModalResponse = (params) => { 
    if(!params.response){
      this.setState({showSendToInspectionConfirmationModal: false, formFields: {...this.state.formFields, send_to_inspection: false}})
    }else{
      this.setState({showSendToInspectionConfirmationModal: false })

    }
  }


  /**
   *UPDATE POST AUCTION FIELDS VALUE
   */
  updatePostAuctionFields = (postAuctinFormFields) => { 
    this.setState({formFields: {...this.state.formFields, ...postAuctinFormFields}})
  }

  render() {
    const { submitted, errors, inventoryFilters, mmvList, versionList, modelList, vehicleId, showHistoryModal, cityList, stateList, vehicleLyingCity, saved_vehicle_data, showSendToInspectionConfirmationModal } = this.state;
    const { VEHICLE_TYPE, MONTHS } = config.constants;

    let { QC_STATUS, OWNERTYPE, POST_AUCTION_STATUS } = this.props.auctionVehicleFilterList;

    let formFields = this.state.formFields;

    let colorList = []
    let fuelType = []
    let transmissionList = []
    let ownerList = []
    let bodyList = []
    let makeYear = []

    if(inventoryFilters && inventoryFilters.length) {
      colorList         = inventoryFilters.filter(filter => filter.key === 'color')
      fuelType          = inventoryFilters.filter(filter => filter.key === 'fuel_type_id')
      transmissionList  = inventoryFilters.filter(filter => filter.key === 'uc_transmission_id')
      makeYear          = inventoryFilters.filter(filter => filter.key === 'max_make_year')
      ownerList         = inventoryFilters.filter(filter => filter.key === 'owner_type')
      bodyList          = inventoryFilters.filter(filter => filter.key === 'body_type')
  
    }

    let { getSellerList, authUser } = this.props;

    let sellerList = [];
    let selectedDealerInfo = {};

    //SELLER LIST
    if (getSellerList && getSellerList.length) {
      sellerList = getSellerList.map(seller => {
        return { value: seller.id, label: seller.organization }
      });

      if (formFields['dealer_id']) {
        selectedDealerInfo = saved_vehicle_data && saved_vehicle_data['dealer_detail'] ? saved_vehicle_data['dealer_detail'] : {}
      }
    }

    //FOR SELLER USER, AUTO FILL SELLER 
    let autoFillSeller = []
    if (authUser && authUser.authUser && authUser.authUser.role && authUser.authUser.role === 'dealercentral' && authUser.dealer_data && authUser.dealer_data.length) {
      autoFillSeller = sellerList.filter(seller => +(seller.value) === +(authUser.dealer_data[0]['dealer_id']))
      if (autoFillSeller.length) {
        formFields['dealer_id'] = autoFillSeller[0]['value']
      }
    }
    let MODEL_EDIT_ID  = (this.props.match.params.id) ? 'p_m_id':'value';
    return (
    
            <div className="edit-vechile-list">
              <div>
                <div className="edit-vechile-detail-form">
                  <h2>{this.props.t('pages.vehicle.edit.basic_details')}</h2>
                  <div className="edit-form row">

                    <fieldset className="form-select-bx col-md-4">
                    <div className={" material animation-effect " + (formFields['dealer_id'] ? 'active-label' : '') + (errors['dealer_id'] ? ' error ' : '')} ref={this.dealer_id_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="dealer_id"
                          name="dealer_id"
                          onChange={this.handleChange.bind(this, 'dealer_id')}
                          options={sellerList || []}
                          value={formFields['dealer_id'] && sellerList && Object.keys(sellerList).length && sellerList.filter((type) => Number(type.value) === Number(formFields.dealer_id))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.seller')+'*'}
                          isDisabled={(autoFillSeller.length || vehicleId) ? true : false} 

                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.seller')+'*'} className="form-label"></label> */}
                        {
                          errors['dealer_id']
                            ?
                            <span className="error-txt">{errors['dealer_id']}</span>
                            :
                            ''
                        }

                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + (formFields['vehicle_type'] ? 'active-label' : '') + (errors['vehicle_type'] ? ' error ' : '')} ref={this.vehicle_type_div}>
                        <Select
                          id="vehicle_type"
                          name="vehicle_type"
                          onChange={this.handleChange.bind(this, 'vehicle_type')}
                          options={VEHICLE_TYPE}
                          value={formFields['vehicle_type'] && VEHICLE_TYPE && VEHICLE_TYPE.filter((type) => Number(type.value) === Number(formFields.vehicle_type))}
                          isClearable={true}
                          placeholder=" "
                          isDisabled={true}
                          classNamePrefix='auction-dropdown'


                        />
                        <label data-label={this.props.t('pages.vehicle.add.vehicle_type')+'*'} className="form-label"></label>

                        {
                          errors['vehicle_type']
                            ?
                            <span className="error-txt">{errors['vehicle_type']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect" + (formFields['make_id'] ? ' active-label' : '') + (errors['make_id'] ? ' error ' : '')} ref={this.make_id_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="make_id"
                          name="make_id"
                          onChange={this.handleChange.bind(this, 'make_id')}
                          options={mmvList['make'] || []}
                          value={formFields['make_id'] && mmvList['make'] && mmvList['make'].filter((type) => Number(type['value']) === Number(formFields.make_id))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.make')+'*'} 
                          isDisabled={vehicleId ? true : false} 

                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.make')+'*'} className="form-label"></label> */}
                        {
                          errors['make_id']
                            ?
                            <span className="error-txt">{errors['make_id']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>


                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material " + (formFields['model_id'] ? ' active-label' : '') + (errors['model_id'] ? ' error ' : '')}  ref={this.model_id_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select" 
                          id="model_id"
                          name="model_id"
                          onChange={this.handleChange.bind(this, 'model_id')}
                          options={modelList || []}
                          value={formFields['model_id'] && modelList && modelList.filter((type) => Number(type[MODEL_EDIT_ID]) === Number(formFields.model_id))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.model')+'*'}
                          isDisabled={vehicleId ? true : false}
                          classNamePrefix='auction-dropdown react-select'

                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.model')+'*'} className="form-label"></label> */}
                        {
                          errors['model_id']
                            ?
                            <span className="error-txt">{errors['model_id']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + (formFields['version_id'] ? 'active-label' : '') + (errors['version_id'] ? ' error ' : '')} ref={this.version_id_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="version_id"
                          name="version_id"
                          onChange={this.handleChange.bind(this, 'version_id')}
                          options={versionList || []}
                          value={formFields['version_id'] && versionList && versionList.filter((type) => Number(type.value) === Number(formFields.version_id))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.variant')+'*'}
                          isDisabled={vehicleId ? true : false} 

                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.variant')+'*'} className="form-label"></label> */}
                        {
                          errors['version_id']
                            ?
                            <span className="error-txt">{errors['version_id']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + (formFields['fuel_type'] ? 'active-label' : '')} ref={this.fuel_type_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="fuel_type"
                          name="fuel_type"
                          onChange={this.handleChange.bind(this, 'fuel_type')}
                          options={fuelType[0] && fuelType[0]['list']}
                          value={formFields['fuel_type'] && fuelType[0] && fuelType[0]['list'].filter((type) => (Number(type.value) === Number(formFields.fuel_type) || (type.label).toLowerCase() === ('' + formFields.fuel_type).toLowerCase()))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.fuel_type')}  
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.fuel_type')} className="form-label"></label> */}

                        {
                          errors['fuel_type']
                            ?
                            <span className="error-txt">{errors['fuel_type']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + (formFields['transmission'] ? 'active-label' : '')} ref={this.transmission_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="transmission"
                          name="transmission"
                          onChange={this.handleChange.bind(this, 'transmission')}
                          options={transmissionList[0] && transmissionList[0]['list']}
                          value={formFields['transmission'] && transmissionList[0] && transmissionList[0]['list'].filter((type) => (Number(type.value) === Number(formFields.transmission) || (type.label).toLowerCase() === ('' + formFields.transmission).toLowerCase()))}
                          isClearable={true}
                          placeholder="Transmission" 
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 

                        />
                        {/* <label data-label="Transmission" className="form-label"></label> */}
                        {
                          errors['transmission']
                            ?
                            <span className="error-txt">{errors['transmission']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>


                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + (formFields['make_month'] ? 'active-label' : '')} ref={this.make_month_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="make_month"
                          name="make_month"
                          onChange={this.handleChange.bind(this, 'make_month')}
                          options={MONTHS}
                          value={formFields['make_month'] && MONTHS.filter((type) => Number(type.value) === Number(formFields.make_month))}
                          isClearable={true}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']}  
                          placeholder={this.props.t('pages.vehicle.add.manufacturing_month')}  

                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.manufacturing_month')+'*'} className="form-label"></label> */}
                        
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + (formFields['make_year'] ? 'active-label' : '') + (errors['make_year'] ? ' error ' : '')} ref={this.make_year_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="make_year"
                          name="make_year"
                          onChange={this.handleChange.bind(this, 'make_year')}
                          options={(makeYear && makeYear[0] && makeYear[0]['list']) || []}
                          value={formFields['make_year'] && makeYear[0] && makeYear[0]['list'].filter((type) => Number(type.value) === Number(formFields.make_year))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.manufacturing_year')+'*'}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.manufacturing_year')+'*'} className="form-label"></label> */}

                        {
                          errors['make_year']
                            ?
                            <span className="error-txt">{errors['make_year']}</span>
                            :
                            ''
                        }

                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + ((formFields['body_type'] || formFields['car_body_type']) ? 'active-label' : '')} ref={this.body_type_div}>

                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="body_type"
                          name="body_type"
                          onChange={this.handleChange.bind(this, 'body_type')}
                          options={(bodyList[0] && bodyList[0]['list']) || []}
                          value={formFields['body_type'] && bodyList[0] && bodyList[0]['list'] && bodyList[0]['list'].filter((type) => (Number(type.value) === Number(formFields.body_type) || (type.label).toLowerCase() === ('' + formFields.body_type).toLowerCase()))}

                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.body_type')} 
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.body_type')} className="form-label"></label> */}
                      </div>

                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + ((formFields['uc_colour_id']) ? 'active-label' : '') + (errors['uc_colour_id'] ? ' error ' : '')} ref={this.uc_colour_id_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="uc_colour_id"
                          name="uc_colour_id"
                          onChange={this.handleChange.bind(this, 'uc_colour_id')}
                          options={(colorList && colorList[0] && colorList[0]['list']) || []}
                          value={colorList[0] && colorList[0]['list'] && colorList[0]['list'].filter((type) => (Number(type.value) === Number(formFields.uc_colour_id)))}

                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.color')+'*'}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.color')+'*'} className="form-label"></label> */}
                        {
                          errors['uc_colour_id']
                            ?
                            <span className="error-txt">{errors['uc_colour_id']}</span>
                            :
                            ''
                        }

                      </div>
                    </fieldset>

                    <fieldset className="datepicer col-md-4">
                      <div className="material">

                        <InputField
                          inputProps={{
                            id: "engine_capacity",
                            type: "text",
                            name: "engine_capacity",
                            placeholder: " ",
                            value: formFields.engine_capacity || '',
                            className: 'form-input',
                            readOnly:true
                          }}
                          onChange={this.handleInputChange('engine_capacity')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.engine_capacity')} className="form-label"></label>
                      </div>
                    </fieldset>

                    <fieldset className="datepicer col-md-4">
                      <div className={"material" + (errors['reg_no'] ? ' error ' : '')}>

                        <InputField
                          inputProps={{
                            id: "reg_no",
                            type: "text",
                            name: "reg_no",
                            placeholder: " ",
                            value: formFields.reg_no || '',
                            className: 'form-input',
                            disabled:saved_vehicle_data['isAuctionCarExpired']
                          }}
                          onChange={this.handleInputChange('reg_no')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.reg_no')+'*'} className="form-label"></label>

                        {
                          errors['reg_no']
                            ?
                            <span className="error-txt">{errors['reg_no']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="datepicer col-md-4">
                      <div className="material">

                        <InputField
                          inputProps={{
                            id: "engine_number",
                            type: "text",
                            name: "engine_number",
                            placeholder: ' ',
                            value: formFields.engine_number || '',
                            className: 'form-input',
                            disabled:saved_vehicle_data['isAuctionCarExpired']

                          }}
                          onChange={this.handleInputChange('engine_number')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.engine_number')} className="form-label"></label>
                      </div>
                    </fieldset>

                    <fieldset className="datepicer col-md-4">
                      <div className="material">

                        <InputField
                          inputProps={{
                            id: "chasis_number",
                            type: "text",
                            name: "chasis_number",
                            placeholder: " ",
                            value: formFields.chasis_number || '',
                            className: 'form-input',
                            disabled:saved_vehicle_data['isAuctionCarExpired']
                          }}
                          onChange={this.handleInputChange('chasis_number')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.chasis_number')} className="form-label"></label>
                      </div>
                    </fieldset>


                    <fieldset className="form-select-bx col-md-4">
                      <div className={" material animation-effect " + ((formFields['reg_place_city_id']) ? 'active-label' : '')} ref={this.reg_place_city_id_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="reg_place_city_id"
                          name="reg_place_city_id"
                          onChange={this.handleChange.bind(this, 'reg_place_city_id')}
                          options={cityList}
                          value={formFields['reg_place_city_id'] && cityList && cityList.filter((type) => Number(type.value) === Number(formFields.reg_place_city_id))}
                          isClearable={true}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                          placeholder={this.props.t('pages.vehicle.add.reg_place')} 

                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.reg_place')+'*'} className="form-label"></label> */} 

                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + ((formFields['reg_month']) ? 'active-label' : '')+ (errors['reg_month']? ' error': '')} ref={this.reg_month_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="reg_month"
                          name="reg_month"
                          onChange={this.handleChange.bind(this, 'reg_month')}
                          options={MONTHS}
                          value={formFields['reg_month'] && MONTHS.filter((type) => Number(type.value) === Number(formFields.reg_month))}
                          isClearable={true}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                          placeholder={this.props.t('pages.vehicle.add.reg_month')} 

                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.reg_month')+'*'} className="form-label"></label> */} 
                        {
                          errors['reg_month']
                            ?
                            <span className="error-txt">{errors['reg_month']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + ((formFields['reg_year']) ? 'active-label' : '')+ (errors['reg_year']? ' error': '')} ref={this.reg_year_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="reg_year"
                          name="reg_year"
                          onChange={this.handleChange.bind(this, 'reg_year')}
                          options={(makeYear && makeYear[0] && makeYear[0]['list']) || []}
                          value={formFields['reg_year'] && makeYear[0] && makeYear[0]['list'].filter((type) => Number(type.value) === Number(formFields.reg_year))}
                          isClearable={true}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                          placeholder={this.props.t('pages.vehicle.add.reg_year')}  

                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.reg_year')+'*'} className="form-label"></label> */}
                        {
                          errors['reg_year']
                            ?
                            <span className="error-txt">{errors['reg_year']}</span>
                            :
                            ''
                        }

                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + ((formFields['reg_valid_month']) ? 'active-label' : '') + (errors['reg_valid_month']? ' error': '')} ref={this.reg_valid_month_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="reg_valid_month"
                          name="reg_valid_month"
                          onChange={this.handleChange.bind(this, 'reg_valid_month')}
                          options={MONTHS}
                          value={formFields['reg_valid_month'] && MONTHS.filter((type) => Number(type.value) === Number(formFields.reg_valid_month))}
                          isClearable={true}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                          placeholder={this.props.t('pages.vehicle.add.number_plate_valid_month')} 

                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.number_plate_valid_month')+'*'} className="form-label"></label> */}
                        
                        {
                          errors['reg_valid_month']
                            ?
                            <span className="error-txt">{errors['reg_valid_month']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>


                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + ((formFields['reg_valid_year']) ? 'active-label' : '')+ (errors['reg_valid_year']? ' error': '')} ref={this.reg_valid_year_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="reg_valid_year"
                          name="reg_valid_year"
                          onChange={this.handleChange.bind(this, 'reg_valid_year')}
                          options={HELPER.regValidYears()}
                          value={formFields['reg_valid_year'] && HELPER.regValidYears().filter((type) => Number(type.value) === Number(formFields.reg_valid_year))}
                          isClearable={true}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                          placeholder={this.props.t('pages.vehicle.add.number_plate_valid_year')} 

                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.number_plate_valid_year')+'*'} className="form-label"></label> */}
                        {
                          errors['reg_valid_year']
                            ?
                            <span className="error-txt">{errors['reg_valid_year']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + (formFields['owner_type'] ? 'active-label' : '')} ref={this.owner_type_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="owner_type"
                          name="owner_type"
                          onChange={this.handleChange.bind(this, 'owner_type')}
                          options={(ownerList[0] && ownerList[0]['list']) || []}
                          value={(formFields['owner_type'] && vehicleId) && ownerList[0] && ownerList[0]['list'] && ownerList[0]['list'].filter((type) => Number(type.value) === Number(formFields.owner_type))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.ownership')}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.ownership')} className="form-label"></label> */}
                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + (formFields['ownership'] ? 'active-label' : '')} ref={this.ownership_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="ownership"
                          name="ownership"
                          onChange={this.handleChange.bind(this, 'ownership')}
                          options={OWNERTYPE}
                          value={formFields['ownership'] && OWNERTYPE && OWNERTYPE.filter((type) => (Number(type.value) === Number(formFields.ownership) || (type.label).toLowerCase() === formFields.ownership.toLowerCase()))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.owner_type')} 
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />

                        {/* <label data-label={this.props.t('pages.vehicle.add.owner_type')} className="form-label"></label> */}
                      </div>
                    </fieldset>

                    <fieldset className="datepicer col-md-4">
                      <div className={"material" + (errors['km_driven'] ? ' error ' : '')}>

                        <InputField
                          inputProps={{
                            id: "km_driven",
                            type: "text",
                            name: "km_driven",
                            placeholder: " ",
                            value: formFields.km_driven || '',
                            className: 'form-input',
                            disabled:saved_vehicle_data['isAuctionCarExpired']
                          }}
                          onChange={this.handleInputChange('km_driven')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.km_driven')+'*'} className="form-label"></label>
                        {
                          errors['km_driven']
                            ?
                            <span className="error-txt">{errors['km_driven']}</span>
                            :
                            ''
                        }

                      </div>
                    </fieldset>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + (formFields['vehicle_lying_state'] ? 'active-label' : '')} ref={this.vehicle_lying_state_div}>

                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="vehicle_lying_state"
                          name="vehicle_lying_state"
                          onChange={this.handleChange.bind(this, 'vehicle_lying_state')}
                          options={stateList}
                          value={(typeof formFields['vehicle_lying_state'] !== 'undefined' && vehicleId) && stateList && stateList.filter((type) => Number(type.value) === Number(formFields.vehicle_lying_state))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.vehicle_lying_state')}
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.vehicle_lying_state')} className="form-label"></label> */}

                      </div>
                    </fieldset>


                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + (formFields['vehicle_lying_city'] ? 'active-label' : '')} ref={this.vehicle_lying_city_div}>

                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="vehicle_lying_city"
                          name="vehicle_lying_city"
                          onChange={this.handleChange.bind(this, 'vehicle_lying_city')}
                          options={vehicleLyingCity || []}
                          value={(typeof formFields['vehicle_lying_city'] !== 'undefined' && vehicleId) && vehicleLyingCity && vehicleLyingCity.filter((type) => Number(type.value) === Number(formFields.vehicle_lying_city))}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.vehicle_lying_city')} 
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.vehicle_lying_city')} className="form-label"></label> */}

                      </div>
                    </fieldset>

                    <fieldset className="datepicer col-md-4">
                      <div className={"material" + (errors['expected_price'] ? ' error ' : '')}>
                        <CurrencyInputField
                          inputProps={{
                            id: "expected_price",
                            type: "text",
                            name: "expected_price",
                            placeholder: " ",
                            value: formFields.expected_price || '',
                            className: 'form-input',
                            disabled:saved_vehicle_data['isAuctionCarExpired'],
                            maxlength:15
                          }}
                          onFocus={() => { }}
                          onBlur={() => { }}
                          onChange={this.handleInputChange('expected_price')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.expected_price')+'*'} className="form-label"></label>
                        {
                          errors['expected_price']
                            ?
                            <span className="error-txt">{errors['expected_price']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>


                    <fieldset className="datepicer col-md-4">
                      <div className={"material" + (errors['reserved_price'] ? ' error ' : '')}>
                        <CurrencyInputField
                          inputProps={{
                            id: "reserved_price",
                            type: "text",
                            name: "reserved_price",
                            placeholder: " ",
                            value: (formFields.reserved_price)?formFields.reserved_price:0,
                            className: 'form-input',
                            disabled:saved_vehicle_data['isAuctionCarExpired'],
                            maxlength:15
                          }}
                          onFocus={() => { }}
                          onBlur={() => { }}
                          onChange={this.handleInputChange('reserved_price')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.reserved_price')} className="form-label"></label>
                        {
                          errors['reserved_price']
                            ?
                            <span className="error-txt">{errors['reserved_price']}</span>
                            :
                            ''
                        }
                      </div>
                    </fieldset>

                    <fieldset className="datepicer col-md-4">
                      <div className={"material" + (errors['car_price'] ? ' error ' : '')}>
                        <CurrencyInputField
                          inputProps={{
                            id: "car_price",
                            type: "text",
                            name: "car_price",
                            placeholder: " ",
                            value: (formFields.car_price)?formFields.car_price:0,
                            className: 'form-input',
                            disabled:saved_vehicle_data['isAuctionCarExpired'],
                            maxlength:15
                          }}
                          
                          onFocus={() => { }}
                          onBlur={() => { }}
                          onChange={this.handleInputChange('car_price')}
                        />
                        <label data-label={this.props.t('pages.vehicle.add.base_price')+'*'} className="form-label"></label>
                        {
                          errors['car_price']
                            ?
                            <span className="error-txt">{errors['car_price']}</span>
                            :
                            ''
                        }

                      </div>
                    </fieldset>
                  </div>


                  {
                    vehicleId
                      ?
                      <>
                        <h2>{this.props.t('pages.vehicle.edit.seller_details')}</h2>
                        <div className="edit-form row">

                          <fieldset className="datepicer col-md-4">
                            <div className="material">
                              <InputField
                                inputProps={{
                                  id: "seller_name",
                                  type: "text",
                                  name: "seller_name",
                                  placeholder: " ",
                                  value: selectedDealerInfo['dealer_name'] || '',
                                  className: 'form-input',
                                  disabled: true

                                }}
                                onChange={this.handleInputChange('seller_name')}
                              />
                              <label data-label={this.props.t('pages.vehicle.add.seller_name')} className="form-label"></label>
                            </div>
                          </fieldset>

                          <fieldset className="datepicer col-md-4">
                            <div className="material">
                              <InputField
                                inputProps={{
                                  id: "contact_person_name",
                                  type: "text",
                                  name: "contact_person_name",
                                  placeholder: ' ',
                                  value: selectedDealerInfo['contact_persion_name'] || '',
                                  className: 'form-input',
                                  disabled: true

                                }}
                                onChange={this.handleInputChange('contact_person_name')}
                              />
                              <label data-label={this.props.t('pages.vehicle.add.contact_person_name')} className="form-label"></label>
                            </div>
                          </fieldset>

                          <fieldset className="datepicer col-md-4">
                            <div className="material">
                              <InputField
                                inputProps={{
                                  id: "email",
                                  type: "text",
                                  name: "email",
                                  placeholder: " ",
                                  value: selectedDealerInfo['dealer_email'] || '',
                                  // dataerror: errors['email'],
                                  className: 'form-input',
                                  disabled: true

                                }}
                                onChange={this.handleInputChange('email')}
                              />
                              <label data-label={this.props.t('pages.vehicle.add.email')} className="form-label"></label>
                            </div>
                          </fieldset>

                          <fieldset className="datepicer col-md-4">
                            <div className="material">
                              <InputField
                                inputProps={{
                                  id: "mobile",
                                  type: "text",
                                  name: "mobile",
                                  placeholder: " ",
                                  value: selectedDealerInfo['dealer_mobile'] || '',
                                  className: 'form-input',
                                  disabled: true


                                }}
                                onChange={this.handleInputChange('mobile')}
                              />
                              <label data-label={this.props.t('pages.vehicle.add.mobile')} className="form-label"></label>
                            </div>
                          </fieldset>

                        </div>
                        {
                          (this.props.loginType ==='seller') ? null :
                          <div className="Summary-heading">
                            <h2>{this.props.t('pages.vehicle.edit.summary_status')}</h2>
                            <div className="d-flex">
                              {
                                formFields['certification_url']
                                ?
                                  <a className="btn-line" target="blank" href={formFields['certification_url']}>
                                    <i className="ic-pdf-file"></i>
                                    <span>{this.props.t('pages.vehicle.edit.download_report')}</span>
                                  </a>
                                :
                                  ""

                              }
                              
                              <button className="btn-line m-sm-l" onClick={this.showActivityLog}>
                                <i className="ic-access_alarms"></i>
                                <span>{this.props.t('pages.vehicle.edit.view_timeline')}</span>
                              </button>
                            </div>
                            

                          </div>
                        }
                       
                        
                      </>
                      :
                      <div className="Summary-heading">
                        <h2>{this.props.t('pages.vehicle.edit.summary_status')}</h2>
                      </div>
                  }


                  <div className="edit-form row" style={(this.props.loginType ==='seller')? {display:'none'}: null}>

                    <fieldset className="form-select-bx col-md-4">
                      <div className={"material animation-effect " + (formFields['qc_status'] ? ' active-label ' : '')} ref={this.qc_status_div}>
                        <Select
                          components={{ ValueContainer: CustomValueContainer }}
                          className="react-select"  
                          classNamePrefix='auction-dropdown react-select'
                          id="qc_status"
                          name="qc_status"
                          onChange={this.handleChange.bind(this, 'qc_status')}
                          options={QC_STATUS}
                          value={(formFields['qc_status'] !== '' && QC_STATUS && QC_STATUS.filter((type) => Number(type.value) === Number(formFields.qc_status))) || ''}
                          isClearable={true}
                          placeholder={this.props.t('pages.vehicle.add.qc_status')} 
                          isDisabled={saved_vehicle_data['isAuctionCarExpired']} 
                        />
                        {/* <label data-label={this.props.t('pages.vehicle.add.qc_status')} className="form-label"></label> */}
                      </div>
                    </fieldset>
                    <fieldset className="form-select-bx col-md-4 checkbox-filed">
                      <div className="material">

                        <div className="custom-control custom-checkbox">
                          {
                            // formFields['saved_send_to_inspection'] && ['0','5'].includes(formFields['saved_qc_status'])
                            // ?
                            //   <input id="Individual" type="checkbox" className="custom-control-input "  onChange={this.handleChange.bind(this, 'send_to_inspection')} disabled={true} checked={formFields['send_to_inspection']} name="send_to_inspection" />
                            // :
                            //   <input id="Individual" type="checkbox" className="custom-control-input "  onChange={this.handleChange.bind(this, 'send_to_inspection')}  checked={formFields['send_to_inspection']} name="send_to_inspection" />
                            // }

                            <input id="Individual" type="checkbox" className="custom-control-input "  onChange={this.handleChange.bind(this, 'send_to_inspection')} disabled={this.checkSendToInspectionStatus(formFields) || saved_vehicle_data['isAuctionCarExpired']} checked={formFields['send_to_inspection']} name="send_to_inspection" /> }
                            <label className="custom-control-label">Send to Inspection </label>
                          
                        </div>

                      </div>
                    </fieldset>

                  </div>
                  
                  <div id="postAuctionFields">
                    {
                      (vehicleId && saved_vehicle_data['isAuctionCarExpired'] && this.props.loginType !== 'seller')
                      ?
                        <PostAuctionStatusFields {...this.state} componentData={{postAuctionStatus: POST_AUCTION_STATUS}} updatePostAuctionFields={this.updatePostAuctionFields}/>
                      :
                        ""
                    }
                  </div>
                  

                  <div className="edit-form" style={(this.props.loginType === 'seller')? {display:'none'}: null}>

                    <h2>{this.props.t('pages.vehicle.add.remarks')}</h2>
                    <fieldset className="">
                      <div className="material">
                        <textarea
                          className="form-control"
                          type="text"
                          name="description"
                          id="description"
                          value={formFields.description || ''}
                          placeholder="Hello, Your Comment"
                          onChange={this.handleInputChange('description')} 
                        />
                      </div>
                    </fieldset>
                  </div>

                  <div className="btn-submit-cancel">
                    {
                      !submitted
                        ?
                        <button type="button" className="btn-primary" onClick={this.submitData.bind(this)} disabled={(this.props.loginType === 'seller')}>{this.props.t('pages.auction.add.submit')}</button>
                        :
                        <button type="button" className="btn-primary" >{this.props.t('common.please_wait')}</button>
                    }
                    <NavLink className="btn-line" to='/vehicle-list'>{this.props.t('common.cancel')}</NavLink>
                  </div>
                </div>
              </div>

              <div className="view-timeline-popup">
                {
                  showHistoryModal  
                    ?
                    <ModalPopup show={showHistoryModal} handleClose={this.historyCloseModal}>
                      <VehicleActivityLog vehicleId={saved_vehicle_data.id} />
                    </ModalPopup>
                    :
                    ''
                }

              </div>

              

              {
                  showSendToInspectionConfirmationModal
                      ?
                          <ConfirmationModal confirmationData={{ type: "singleSendToInspection", show: showSendToInspectionConfirmationModal, confirmationText: this.props.t("pages.vehicle.edit.send_to_inspection_confirmation") }} confirmationModalResponse={this.confirmationSendToInspectionModalResponse} />
                      :
                          ''
              }
            </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {  
  return {
    inventoryFilters: state.common.inventoryFilterList || [],
    mmvList: state.common.mmvList || {},
    authUser: state.auth.authUser || {},
    allVehicleList: state.vehicle.vehicleList || {},
    getSellerList: state.common.sellerList || {},
    cityStateList: state.common.cityStateList || {},
    allVehicleDetails: state.vehicle.allVehicleDetails || {},
    auctionVehicleFilterList: state.common.auctionVehicleFilterList || {},
    loginType: state.auth.authUser.login_type || ''
  };
}


const mapDispatchToProps = dispatch => {
  return {
    actions: {
      getFilter: bindActionCreators(
        CommonMethodActions.getFilter,
        dispatch
      ),
      getMMVList: bindActionCreators(
        CommonMethodActions.getMMVList,
        dispatch
      ),
      saveVehicle: bindActionCreators(
        VehicleActions.saveVehicle,
        dispatch
      ),
      getSellersList: bindActionCreators(
        CommonMethodActions.getSellersList,
        dispatch
      ),
      getCityStateList: bindActionCreators(
        CommonMethodActions.getCityStateList,
        dispatch
      ),
      getVehicleDetails: bindActionCreators(
        VehicleActions.getVehicleDetails,
        dispatch
      ),
      getAuctionVehicleFilters: bindActionCreators(
        CommonMethodActions.getAuctionVehicleFilters,
        dispatch
      )
    }
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SaveVehicle));