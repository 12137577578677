import React, { Component } from 'react';
import {UserService} from './../../../service';

class UserList extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
        await this.getUserList();

    }

    getUserList() {
        UserService.getUserList()
            .then(function (response) {
                // console.log('response', response)
            })
            .catch(function (response) {
                // console.log('error', response)
            });
    }

    render() {
        return (
            <div className="container">
                <div className="result-wrap">
                    <div className="card">
List
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }
}

export default UserList;