import React, { Component } from 'react';
import App from '../components/FileUpload'
import FileUploadError from '../components/FileUploadError'

class TopActionBtn extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            BankPaymentUpload: false,
            BankPaymentUploadError: false,
        };
    }

    showModalBankPaymentUpload = () => {
        this.setState({ BankPaymentUpload: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalBankPaymentUpload = () => {
        this.setState({ BankPaymentUpload: false });
        document.body.classList.remove("overflow-hidden");
    }
    showModalBankPaymentUploadError = () => {
        this.setState({ BankPaymentUploadError: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalBankPaymentUploadError = () => {
        this.setState({ BankPaymentUploadError: false });
        document.body.classList.remove("overflow-hidden");
    }


    render() {

        return (
            <div>
                <div className="top-heading-action-sec">
                    <h1>Vehicle List</h1>
                    <div className="action-btn">
                        <button className="btn-line">
                            <i className="ic-share1"></i>
                            <span> Share</span>
                        </button>
                        <button className="btn-line">
                            <i className="ic-delete-icn"></i>
                            <span> Delete</span>
                        </button>
                        <button className="btn-line" onClick={this.showModalBankPaymentUploadError}>
                            <i className="ic-pdf"></i>
                            <span> Download Vehicle List</span>
                        </button>
                        <button className="btn-line" onClick={this.showModalBankPaymentUpload}>
                            <i className="ic-upload"></i>
                            <span> Upload Vehicle</span>
                        </button>
                        <button className="btn-line">
                            <i className="ic-add"></i>
                            <span> Add New Vehicle</span>
                        </button>
                        {/* <button class="btn-line" onClick={this.showModalBankPaymentUpload}>Bulk Upload UTR</button> */}

                    </div>
                </div>
                <div className="upload-vehicle-popup">
                    <Modal show={this.state.BankPaymentUpload} handleClose={this.hideModalBankPaymentUpload} >
                        <div className="modal-header">
                            <h2>Upload Vehicle</h2>
                        </div>
                        <div className="modal-body" >
                            <div className="upload-inventory-panel upload-heading">
                                <h3>Upload Vehicle Inventory File</h3>
                                <App />
                                <div className="download-txt">
                                    Download input format < a href="/#">
                                        Click Here
                                    </a>
                                </div>
                            </div>
                            <div className="upload-image-panel upload-heading">
                                <h3>Upload Vehicle Images</h3>
                                <App />
                            </div>
                        </div>

                        <div className="modal-footer footer-btn">
                            <button className="btn-primary">Upload</button>
                        </div>
                    </Modal>
                </div>


                <div className="upload-vehicle-popup">
                    <Modal show={this.state.BankPaymentUploadError} handleClose={this.hideModalBankPaymentUploadError} >
                        <div className="modal-header">
                            <h2>Upload Vehicle</h2>
                        </div>
                        <div className="modal-body" >
                            <div className="upload-inventory-panel upload-heading">
                                <h3>Upload Vehicle Inventory File</h3>
                                <App />
                                <div className="download-txt">
                                    Download input format < a href="/#">
                                        Click Here
                                    </a>
                                </div>
                            </div>
                            <div className="upload-image-panel upload-heading">
                                <h3>Upload Vehicle Images</h3>
                                <FileUploadError />
                            </div>
                        </div>

                        <div className="modal-footer footer-btn">
                            <button className="btn-primary">Upload</button>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};


const container = document.createElement('div');
document.body.appendChild(container);
export default TopActionBtn