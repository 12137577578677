import React, { useState, useEffect } from 'react';
import no_image from "../../../webroot/images/no_image.svg";


function ManageBuyers(props) {


    let [buyers, setBuyers] = useState({ ...props.buyersData, buyerList: [], origBuyerList: [], selectBuyersToAdd: [], selectedBuyers: [], checkedAllBuyers: false, checkedAllSelectedBuyers: false, savedBuyersAssigned: false });
    let [getSavedBuyer, setSavedBuyer] = useState({ savedBuyersAssigned: false })
    const [isEnable,setEnable] = useState(false);

    useEffect(() => {

        let buyerList = buyers.buyerList || []; //ASSIGN BUYER LIST
        let selectBuyersToAdd = buyers.selectBuyersToAdd;
        let savedBuyersAssigned = getSavedBuyer.savedBuyersAssigned;

        if (!buyerList.length && props.buyersData.buyerList && props.buyersData.buyerList.length) {
            buyerList = props.buyersData.buyerList.map(byer => { byer['show'] = true; return byer; })
            //SET IN STATE
            setBuyers({ ...buyers, buyerList })
        }


        if (props.buyersData.savedAuctionBuyers && props.buyersData.savedAuctionBuyers.length && !savedBuyersAssigned) {
            selectBuyersToAdd = props.buyersData.savedAuctionBuyers.map(buyers => buyers.buyer_id);

            savedBuyersAssigned = true;

            setSavedBuyer({ savedBuyersAssigned })

            setBuyers({ ...buyers, selectBuyersToAdd });

            setTimeout(() => {
                // addSelectedBuyers();
                if(document.getElementById('addToSelectedList')) document.getElementById('addToSelectedList').click();

            }, 1000)
        }

        setTimeout(() => {
            setEnable(props.expiredTime)  
        }, 1500)
    // eslint-disable-next-line
    }, [props]);


    /**
     * BUYER SELECTION BOX LEFT PANEL CHECKBOX HANDLER
     * @param {checkbox event} event 
     */
    const selectBuyersCheckbox = (event) => {
        let { buyerList, selectBuyersToAdd, checkedAllBuyers,selectedBuyers } = buyers;

        let selectedVal = event.target.value;

        //IF SELECT ALL CHECKED
        if (selectedVal === 'all') {
            //ASSIGN BUYER IDs TO DIFF ARRAY & RESET IF DESELECTED ALL
            selectBuyersToAdd = []
            if (event.target.checked){
                let selectedbuyerId = selectedBuyers.map(buyer=>buyer.id);
                selectBuyersToAdd = buyerList.map(buyer => buyer.id).filter((item)=>!selectedbuyerId.includes(item)); 
            } 

        } else {
            //ELSE ADD SINGLE BUYER TO ARRAY & REMOVE FROM ARRAY ON UNCHECKED
            if (event.target.checked)
                selectBuyersToAdd = [...selectBuyersToAdd, +selectedVal]
            else
                selectBuyersToAdd = selectBuyersToAdd.filter(buyer => +buyer !== +selectedVal)

        }
        //DEFAULT FALSE
        checkedAllBuyers = false

        //CHECK IF ALL SELECTED BUYER THEN ENABLE checkedAllBuyers FLAG
        if (buyerList.length === (selectBuyersToAdd.length + selectedBuyers.length)) {
            checkedAllBuyers = true
        }
        //SET STATE
        setBuyers({ ...buyers, selectBuyersToAdd, checkedAllBuyers })

    }

    /**
     * ADD SELECTED BUYER TO RIGHT SIDE LIST
     */
    const addSelectedBuyers = () => {
        let { selectBuyersToAdd, selectedBuyers, buyerList, checkedAllBuyers } = buyers; 
        //ASSIGN ONLY IF ANY BUYER IS SELECTED
        if (selectBuyersToAdd.length) {
            //CREATED NEW ARRAY WITH ORIGINAL BUYERS DATA & SOME NEW FLAGS
            // selectedBuyers = []
            for (let buyer of buyerList) {
                //ADD SELECTED BUYERS DATA & NEW FLAGS checked & show
                //{check} FLAG IS FOR CHECK/UNCHECK HANDLING
                //{show} FLAG WILL HELP FOR GLOBAL SERACH FUNCTION 
                if (selectBuyersToAdd.includes(buyer.id))
                    selectedBuyers.push({ ...buyer, checked: false, show: true })
            }

            if (selectedBuyers.length) {
                props.passSelectedBuyers(selectedBuyers);
            }
            //RESET SELECTED BUYER ARRAY OF LEFT BOX
            selectBuyersToAdd = [];
            checkedAllBuyers = false;
            setBuyers({ ...buyers, selectedBuyers, selectBuyersToAdd, checkedAllBuyers })
        }


    }

    /**
     * CHECKBOX HANDLER FOR RIGHT SIDE SELECTED BUYERS BOX
     * @param {checkbox event} event 
     */
    const removeSelectedBuyersCheckbox = (event) => {
        let { selectedBuyers, checkedAllSelectedBuyers } = buyers;

        let selectedVal = event.target.value;

        //IF ALL SELECTED
        if (selectedVal === 'all') {
            //IF CHECKED THEN ENABLE checked FLAG ELSE DISABLE
            if (event.target.checked)
                selectedBuyers = selectedBuyers.map(buyer => { buyer['checked'] = true; return buyer });
            else
                selectedBuyers = selectedBuyers.map(buyer => { buyer['checked'] = false; return buyer });

        } else {
            //SINGLE BUYER CHECK/UNCHECK HANDLER

            //ON CHECK/UNCHECK ENABLE/DISABLE BUYER 
            if (event.target.checked)
                selectedBuyers = selectedBuyers.map(buyer => { if (+buyer.id === +selectedVal) buyer['checked'] = true; return buyer });
            else
                selectedBuyers = selectedBuyers.map(buyer => { if (+buyer.id === +selectedVal) buyer['checked'] = false; return buyer });

        }

        //DISABLE SELECT ALL FOR RIGHT SIDE LIST
        checkedAllSelectedBuyers = false

        //GET CHECKED BUYERS FROM RIGHT SIDE LIST 
        let getCheckedSelectedBuyersToRemove = selectedBuyers.filter(byer => byer.checked);

        //IF RIGHT SIDE LIST ASSIGNED BUYERS ARE EQUAL TO THE CHECKED ONCE THEN ENABLE SELECT ALL
        if (selectedBuyers.length === getCheckedSelectedBuyersToRemove.length) {
            checkedAllSelectedBuyers = true
        }
        setBuyers({ ...buyers, selectedBuyers, checkedAllSelectedBuyers })
    }

    /**
     * REMOVE CHECKED BUYERS FROM RIGHT SIDE LIST 
     */
    const removeSelectedBuyers = () => {
        let { selectBuyersToAdd, selectedBuyers, checkedAllSelectedBuyers } = buyers;

        //RETAIN ONLY UNSELECTED BUYERS
        selectedBuyers = selectedBuyers.filter(buyer => !buyer.checked)

        //RESET LEFT SIDE LIST ARRAY OF SELECTED BUYERS
        // selectBuyersToAdd = []

        if (!selectedBuyers.length) checkedAllSelectedBuyers = false;

        props.passSelectedBuyers(selectedBuyers);

        setBuyers({ ...buyers, selectedBuyers, selectBuyersToAdd, checkedAllSelectedBuyers })

    }

    /**
     * SEARCH FILTER FOR LEFT SIDE
     * @param {input event} event 
     */
    const searchBuyers = (event) => {
        let { buyerList } = buyers;

        let searchText = event.target.value;

        // buyerList = origBuyerList;
        buyerList = buyerList.map(buyer => { buyer['show'] = true; return buyer; })

        //FROM ORIGINAL BUYER LIST GET MATCHED BUYER BY ORGANIZATION & ID
        if (searchText)
            buyerList = buyerList.map(buyer => { if ((buyer.organization).toLowerCase().indexOf(searchText) === -1 && ('' + buyer['id']).indexOf(searchText) === -1) { buyer['show'] = false; } return buyer; })

        // buyerList = origBuyerList.filter(buyer=> ((buyer.organization).toLowerCase().indexOf(searchText)!==-1 || (''+buyer['id']).indexOf(searchText)!==-1) )


        setBuyers({ ...buyers, buyerList })

    }
    /**
     * SEARCH FILTER FOR RIGHT SIDE
     * @param {input event} event 
     */
    const searchSelectedBuyers = (event) => {
        let { selectedBuyers } = buyers;

        let searchText = event.target.value;
        //MANAGE {show} FLAG FOR BUYERS ACC. TO SEARCH FILTER
        selectedBuyers = selectedBuyers.map(buyer => { buyer['show'] = true; return buyer; })

        if (searchText)
            selectedBuyers = selectedBuyers.map(buyer => { if ((buyer.organization).toLowerCase().indexOf(searchText) === -1 && ('' + buyer['id']).indexOf(searchText) === -1) { buyer['show'] = false; } return buyer; })

        setBuyers({ ...buyers, selectedBuyers })
    }

    const { buyerList, checkedAllBuyers, selectBuyersToAdd, selectedBuyers, checkedAllSelectedBuyers } = buyers;
    let buyerListCount = (buyerList ? buyerList.length : 0) - (selectedBuyers ? selectedBuyers.length : 0);
    let selctedBuyerListCount = selectedBuyers ? selectedBuyers.length : 0;

    return (
        <div className="auction-buyer-sec">
            <div className="buyer-heading col-md-12">
                <h3>Manage Buyers</h3>
            </div>
            <div className="col-md-6">
                <span className="add-buyers-txt">Buyers to be Added ({buyerListCount})</span>
                <div className="buyer-list-bx">
                    <div className="select-all-search">
                        <li className="custom-control custom-checkbox">
                            <input id="all" type="checkbox" value='all' onChange={selectBuyersCheckbox} checked={checkedAllBuyers} className="custom-control-input" />
                            <label htmlFor="all" className="custom-control-label">Select All</label>
                        </li>
                        <fieldset className="search-bx">
                            <input id="" type="text" placeholder="Search " onChange={searchBuyers} name="" className="form-input" />
                            <i className="ic-search"></i>
                        </fieldset>
                    </div>
                    <div className="buyers-added-list">
                        <ul>
                            {
                                buyerList && buyerList.length
                                    ?
                                    buyerList.map((el, key) => {
                                        if (el && !selectedBuyers.find(byer => +byer.id === +el.id) && el.show) {

                                            return <li key={key} className="custom-control custom-checkbox">
                                                <input id={'buyer' + (key + 1)} type="checkbox" onChange={selectBuyersCheckbox} value={el.id} checked={checkedAllBuyers || (selectBuyersToAdd.includes(+el.id)) || ''} className="custom-control-input" />
                                                <label htmlFor={'buyer' + (key + 1)} className="custom-control-label">{el.organization}</label>
                                            </li>
                                        } else {
                                            return ''
                                        }
                                    })
                                    :
                                    ''
                            }

                        </ul>
                        <button className="btn-primary" id="addToSelectedList" onClick={addSelectedBuyers} disabled={isEnable}>Add</button>
                    </div>

                </div>
            </div>

            <div className="col-md-6">
                <span className="add-buyers-txt">Added Buyers ({selctedBuyerListCount})</span>
                <div className="buyer-list-bx">
                    <div className="select-all-search">
                        <li className="custom-control custom-checkbox">
                            <input id="all" type="checkbox" onChange={removeSelectedBuyersCheckbox} checked={checkedAllSelectedBuyers} value='all' className="custom-control-input" />
                            <label htmlFor="all" className="custom-control-label">Select All</label>
                        </li>
                        <fieldset className="search-bx">
                            <input id="" type="text" placeholder="Search " onChange={searchSelectedBuyers} name="" className="form-input" />
                            <i className="ic-search"></i>
                        </fieldset>
                    </div>
                    <div className="buyer-no-data-bx" style={(selectedBuyers && selectedBuyers.length) ? { display: "none" } : { display: "flex" }}>
                        {<img src={no_image} className="" alt = ""/>}
                        <span className="no-img-txt">No buyers are added</span>
                    </div>
                    <div className="buyers-added-list" style={(selectedBuyers && selectedBuyers.length) ? { display: "" } : { display: "none" }}>
                        <ul>

                            {
                                selectedBuyers && selectedBuyers.map((el, key) => {
                                    if (el.show) {
                                        return <li key={key} className="custom-control custom-checkbox">
                                            <input id={'selectedBuyer' + (key + 1)} type="checkbox" onChange={removeSelectedBuyersCheckbox} value={el.id} checked={(checkedAllSelectedBuyers || el.checked) || ''} className="custom-control-input" />
                                            <label htmlFor={'selectedBuyer' + (key + 1)} className="custom-control-label">{el.organization}</label>
                                        </li>
                                    } else {
                                        return ''
                                    }

                                })
                            }

                        </ul>
                        <button className="btn-primary" onClick={removeSelectedBuyers} disabled={props.expiredTime}>Remove</button>
                    </div>
                </div>
            </div>


        </div>




    );

}




export default ManageBuyers;