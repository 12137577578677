import { AuctionConstants } from '../constants/auction.constants';

export default function AuctionReducer(state='', action) { 
  switch (action.type) {
    
    case AuctionConstants.GET_INVENTORY_SUCCESS:
      let inventoryList = (state.inventoryList) ? { ...action.auctionInventoryList, ...state.inventoryList} : action.auctionInventoryList

      return {
        ...state,
        inventoryList
      };

    case AuctionConstants.GET_AUCTION_LIST_SUCCESS:
      return {
        auctionList: (action.resp && action.resp.status && action.resp.status === 200) ? {data: action.resp.data, pagination: action.resp.pagination} : {},
        ...state
      };

    case AuctionConstants.GET_AUCTION_LIST_FAILURE:
      return {
        auctionList: {},
        ...state
      };

    case AuctionConstants.GET_AUCTION_CITY_LIST_SUCCESS:
      return {
        ...state,
        auctionCityList:  (action.resp && action.resp.status && action.resp.status === 200) ? {data: action.resp.data} : {}
      }  
    case AuctionConstants.AUCTION_ADD_FLAG:  
      return { 
        ...state,
        isAuctionAdd: action.resp,
      };
    
    case AuctionConstants.AUCTION_UNSET_FLAG:  
      return {
        ...state,
        isAuctionAdd: action.resp
      };

    default:
      return state
  }
}