import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import auction_Illustration from "../../../webroot/images/auction_Illustration.svg";



class LoginOtp extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }


    render() {

        return (
            <div>
                <div className="container-fluid">
                    <div className="login-outer">
                        <div className="login-left-panel">
                            <div className="login-bg">
                                {<img src={auction_Illustration} className="" alt = ""/>}
                            </div>
                        </div>
                        <div className="login-right-panel">
                            <div className="login-heading">
                                <span>Login to</span>
                                <h1>Get Started</h1>
                            </div>
                            <Tab.Container id="" defaultActiveKey="first">
                                <div className="login-tab">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">By Email</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">By Mobile</Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="login-form">
                                                <fieldset class="">
                                                    <div class="material">
                                                        <input id="" type="text" placeholder=" " name="Email" class="form-input" />
                                                        <i className="ic-email1 icn-login"></i>
                                                        <label data-label="Email" class="form-label"></label>
                                                    </div>
                                                </fieldset>
                                                <fieldset class="">
                                                    <div class="material error">
                                                        <input id="" type="text" placeholder=" " name="Password" class="form-input" />
                                                        <i className="ic-password icn-login"></i>
                                                        <label data-label="Password" class="form-label"></label>
                                                        <span className="password-show"><i className="ic-remove_red_eyevisibility"></i></span>
                                                        <span className="erroe-txt">Please type correct password</span>
                                                    </div>
                                                </fieldset>
                                                <button type="button" className="btn-primary">Login</button>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="login-form">
                                                <fieldset class="">
                                                    <div class="material">
                                                        <input id="" type="text" placeholder=" " name="Mobile No." class="form-input" />
                                                        <i className="ic-mobile icn-login"></i>
                                                        <label data-label="Mobile No." class="form-label"></label>
                                                    </div>
                                                </fieldset>

                                                <button type="button" className="btn-primary">Get OTP</button>
                                            </div>
                                        </Tab.Pane>


                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div >


        );
    }
}


export default LoginOtp; 