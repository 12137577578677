import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class FilterTable extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableHeaders: [],
            page: 0,
            runPaginationFunc: false,
            totalLengthWithoutPagination: 0,
            htmlFormAction: '',
            loading: false,
            filterFields:{},
            dataSortObj:[],
            tableHtmlData:'',
            checkedAll: false
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }


    //STATIC METHOD TO SENT DATA TO PARENT AFTER SUCCESSFULL STATE UPDATE
    static updateProps = (props, params) => {
        props.parentCallback(params);
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const updatedData = { 'loading': false };
        updatedData['totalLengthWithoutPagination'] = nextProps.filterTableData.totalLengthWithoutPagination;

        if (nextProps.filterTableData.loading) {
            updatedData['loading'] = true;
        }
        if(nextProps.filterTableData.tab && +nextProps.filterTableData.tab === 2 && nextProps.filterTableData.tableHeadersUpcoming){ 
            updatedData['tableHeaders'] = nextProps.filterTableData.tableHeadersUpcoming;
        }else{
            updatedData['tableHeaders'] = nextProps.filterTableData.tableHeaders;
        } 
        
        if (nextProps.filterTableData && nextProps.filterTableData.tableHeaders.length !== prevState.tableHeaders.length) {
            updatedData['tableHeaders'] = nextProps.filterTableData.tableHeaders;
        }

        updatedData['htmlFormAction'] = nextProps.filterTableData.htmlFormAction;
        updatedData['tableHtmlData'] = nextProps.filterTableData.tableData;

        if (nextProps.filterTableData && nextProps.filterTableData.filterFields ) {
            updatedData['filterFields'] = nextProps.filterTableData.filterFields;
        }

        if (nextProps.filterTableData && nextProps.filterTableData.checkedAll !== prevState.checkedAll ) {
            updatedData['checkedAll'] = nextProps.filterTableData.checkedAll;
        }

        if (Object.keys(updatedData).length) {
            return (updatedData)
        } else {
            return null;
        }
    }

    handleInputChange = type => async (e) => {
        let checkedAllStatus = !this.state.checkedAll;
        
        this.setState({checkedAll: checkedAllStatus})
        this.props.checkedAll(checkedAllStatus)
    }

    render() {
        const { tableHtmlData, tableHeaders, checkedAll } = this.state; 
        return (
            <>
            <table>
                <thead>
                    <tr>
                        {
                            tableHeaders.map(el => {
                                return (<th key={el.key} className={el.sort ? ((el.sort === 'desc') ? "sorting sorting_desc" : "sorting sorting_asc") : ''}>
                                    {
                                        (el.key === 'selectAll')
                                        ?
                                            <li className="custom-control custom-checkbox">
                                                <input id="all" type="checkbox" onChange={this.handleInputChange('selectAll')} checked={checkedAll} name="selectAll" className="custom-control-input" />
                                                <label htmlFor="all" className="custom-control-label"></label>
                                            </li>
                                        :
                                            this.props.t(el.value)
                                    }
                                    </th>)
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                  
                    {tableHtmlData}
                </tbody>

            </table>

            </>
        )
    }
}


export default withTranslation('common')(withRouter(FilterTable));