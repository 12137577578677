import { CommonMethodService } from '../../service';
import {CommonMethodConstants} from '../constants';
import { default as config } from '../../config/config';

export const CommonMethodActions = {
    getFilter,
    getMMVList,
    getSellersList,
    getCityStateList,
    getBuyersList,
    getAuctionVehicleFilters,
    getAuctionFilter
};

function getFilter(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CommonMethodService.getInventoryFilters()
        .then(
          inventoryFilterList => { 
            if(inventoryFilterList && inventoryFilterList.data &&  inventoryFilterList.data.status === 200){

              let filterData = inventoryFilterList.data.data

              if(filterData){
                for(let filter of filterData){
                  if(filter['list'] && filter['list'].length){
                    filter['list'] =  filter['list'].map(list=> { return {"value": list.key, "label": list.value} } )
                  }
              }
              }
              
              dispatch(success(filterData));
              resolve(filterData);
            }
            else{
              resolve((inventoryFilterList && inventoryFilterList.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          inventoryFilterListError => {
            reject(inventoryFilterListError);
            dispatch(failure(inventoryFilterListError));
          }
        );
    });
  };

  function success(inventoryFilterData) { return { type: CommonMethodConstants.GET_INVENTORY_FILTER_SUCCESS, inventoryFilterData } }
  function failure(inventoryFilterListError) { return { type: CommonMethodConstants.GET_INVENTORY_FILTER_FAILURE, inventoryFilterListError } }

}



function getMMVList() {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CommonMethodService.getMMVList()
        .then(
          mmvList => {
            if(mmvList && mmvList.data &&  mmvList.data.status === 200){

              let { MODEL_ID_KEY_FOR_MODEL } = config.constants;

              let mmvData = mmvList.data.data

                  if(mmvData['make'] && mmvData['make'].length){
                    mmvData['make'] =  mmvData['make'].map(list=> { return {"value": list.id, "label": list.make} } )
                  }

                  if(mmvData['model'] && mmvData['model'].length){
                    mmvData['model'] =  mmvData['model'].map(list=> { return {"value": list[MODEL_ID_KEY_FOR_MODEL], "label": list.m, ...list} } )
                  }

                  if(mmvData['version'] && mmvData['version'].length){
                    mmvData['version'] =  mmvData['version'].map(list=> { return {"value": list.vn_id, "label": list.vn, ...list} } )
                  }
              
              dispatch(success(mmvData));
              resolve(mmvData);
            }
            else{
              resolve((mmvList && mmvList.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          mmvDataError => {
            reject(mmvDataError);
            dispatch(failure(mmvDataError));
          }
        );
    });
  };

  function success(mmvData) { return { type: CommonMethodConstants.GET_INVENTORY_MMV_SUCCESS, mmvData } }
  function failure(mmvDataError) { return { type: CommonMethodConstants.GET_INVENTORY_MMV_FAILURE, mmvDataError } }

}


function getSellersList(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CommonMethodService.getSellersList(postData)
        .then(
          sellerList => { 
            if(sellerList && sellerList.data &&  sellerList.data.status === 200){
                            
              dispatch(success(sellerList.data.data));
              resolve(sellerList.data.data);
            }
            else{
              resolve((sellerList && sellerList.data) ||{});
            }
          },
          sellerListError => {
            reject(sellerListError);
          }
        );
    });
  };

  function success(sellerList) { return { type: CommonMethodConstants.GET_SELLER_LIST_SUCCESS, sellerList } }

}

function getCityStateList(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CommonMethodService.getCityStateList(postData)
        .then(
          cityStateList => { 
            if(cityStateList && cityStateList.data &&  cityStateList.data.status === 200){
                            
              dispatch(success(cityStateList.data.data));
              resolve(cityStateList.data.data);
            }
            else{
              resolve((cityStateList && cityStateList.data) ||{});
            }
          },
          cityStateListError => {
            reject(cityStateListError);
          }
        );
    });
  };

  function success(cityStateList) { return { type: CommonMethodConstants.GET_CITY_STATE_LIST_SUCCESS, cityStateList } }

}


function getBuyersList(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CommonMethodService.getBuyersList(postData)
        .then(
          buyersList => { 
            if(buyersList && buyersList.data &&  buyersList.data.status === 200){
                            
              dispatch(success(buyersList.data.data));
              resolve(buyersList.data.data);
            }
            else{
              resolve((buyersList && buyersList.data) ||{});
            }
          },
          buyersListError => {
            reject(buyersListError);
          }
        );
    });
  };

  function success(buyerList) { return { type: CommonMethodConstants.GET_BUYERS_LIST_SUCCESS, buyerList } }

}


function getAuctionVehicleFilters(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CommonMethodService.getAuctionVehicleFilters(postData)
        .then(
          filterList => { 
            if(filterList && filterList.data &&  filterList.data.status === 200){
                            
              dispatch(success(filterList.data.data));
              resolve(filterList.data.data);
            }
            else{
              resolve((filterList && filterList.data) ||{});
            }
          },
          filterListError => {
            reject(filterListError);
          }
        );
    });
  };

  function success(filterList) { return { type: CommonMethodConstants.GET_AUCTION_VEHICLE_FILTER_LIST_SUCCESS, filterList } }

}


function getAuctionFilter() {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CommonMethodService.getAuctionFilters()
        .then(
          filterList => { 
            if(filterList && filterList.data && filterList.data.status === 200){
                            
              dispatch(success(filterList.data.data));
              resolve(filterList.data.data);
            }
            else{
              resolve((filterList && filterList.data) ||{});
            }
          },
          filterListError => {
            reject(filterListError);
          }
        );
    });
  };

  function success(filterList) { return { type: CommonMethodConstants.GET_AUCTION_FILTER_LIST_SUCCESS, filterList } }

}