import React, { Component } from 'react';
import { Calendra, Calendra2 } from '../components/DatePicker'
import MultiSelect from '../../common/MultiSelect'

const seller = [
    { value: 'Seller', label: 'seller' },
    { value: 'Seller1', label: 'seller1' },
    { value: 'Seller2', label: 'seller2' },
];

const model = [
    { value: 'Make & Model', label: 'Make & Model' },
    { value: 'Make & Model1', label: 'Make & Model1' },
    { value: 'Make & Model2', label: 'Make & Model2' },
];
const QCstatus = [
    { value: 'QC Status', label: 'QC Status' },
    { value: 'QC Status1', label: 'Make & Model1' },
    { value: 'QC Status2', label: 'QC Status2' },
];

const created = [
    { value: 'Created By', label: 'Created By' },
    { value: 'Created By1', label: 'Created By1' },
    { value: 'Created By2', label: 'Created By2' },
];


class TopSearchBar extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            sellerOption: null,
            sellermodel: null,
            Qcstatusopt: null,
            createdopt: null,




        };
    }
    handleChangeseller = (sellerOption) => {
        this.setState({ sellerOption }
        );
    };
    handleChangemodel = (sellermodel) => {
        this.setState({ sellermodel }
        );
    };
    handleChangeQcstatus = (Qcstatusopt) => {
        this.setState({ Qcstatusopt }
        );
    };
    handleChangecreated = (createdopt) => {
        this.setState({ createdopt }
        );
    };


    render() {
        const { sellerOption } = this.state;
        const { sellermodel } = this.state;
        const { Qcstatusopt } = this.state;
        const { createdopt } = this.state;

        return (
            <div>

                <div className="top-ribben-search">
                    <div className="vechile-list-form">
                        <fieldset class="search-bx">
                            <div class="material">
                                <input id="" type="text" placeholder="Search By VID and Reg. No." name="Search" class="form-input" />
                            </div>
                        </fieldset>
                        <fieldset class="seller-opt multiselect-dropDown">
                            <div class="material">
                              
                                <MultiSelect
                                    isMulti={true}
                                    options={seller}
                                    placeholder="Seller"
                                    value={sellerOption}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </fieldset>

                        <fieldset class="model-opt multiselect-dropDown">
                            <div class="material">
                               
                                <MultiSelect
                                    isMulti={true}
                                    options={model}
                                    placeholder="Make & Model"
                                    value={sellermodel}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </fieldset>

                        <fieldset class="create-date">
                            <div class="material">
                                <Calendra />
                            </div>
                        </fieldset>


                        <fieldset class="registartion-date">
                            <div class="material">
                                <Calendra2 />
                            </div>
                        </fieldset>

                        <fieldset class="seller-opt multiselect-dropDown">
                            <div class="material">
                               
                                 <MultiSelect
                                    isMulti={true}
                                    options={QCstatus}
                                    placeholder="QC Status"
                                    value={Qcstatusopt}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </fieldset>
                        <fieldset class="created-opt multiselect-dropDown">
                            <div class="material">

                                <MultiSelect
                                    isMulti={true}
                                    options={created}
                                    placeholder="Created By"
                                    value={createdopt}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </fieldset>

                        <div className="btn-submit-reset">
                            <button className="btn-primary">Submit</button>
                            <button className="btn-reset">Reset</button>
                        </div>
                    </div>
                </div>
                 

            </div>


        );
    }
}



export default TopSearchBar;