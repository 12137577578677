export const AuctionConstants = {
    GET_INVENTORY_SUCCESS: 'GET_INVENTORY_SUCCESS',
    GET_INVENTORY_FAILURE: 'GET_INVENTORY_FAILURE',

    SAVE_AUCTION_CAR_SUCCESS: 'SAVE_AUCTION_CAR_SUCCESS',
    SAVE_AUCTION_CAR_FAILURE: 'SAVE_AUCTION_CAR_FAILURE',

    GET_AUCTION_LIST_SUCCESS: 'GET_AUCTION_LIST_SUCCESS',
    GET_AUCTION_LIST_FAILURE: 'GET_AUCTION_LIST_FAILURE',

    GET_AUCTION_CARS_LIST_SUCCESS: 'GET_AUCTION_CARS_LIST_SUCCESS',
    GET_AUCTION_CARS_LIST_FAILURE: 'GET_AUCTION_CARS_LIST_FAILURE',

    GET_AUCTION_CITY_LIST_SUCCESS: 'GET_AUCTION_CITY_LIST_SUCCESS',
    GET_AUCTION_CITY_LIST_FAIL: 'GET_AUCTION_CITY_LIST_FAIL',

    SEND_SMS_SUCCESS: 'SEND_SMS_SUCCESS',
    SEND_SMS_FAIL: 'SEND_SMS_FAIL',

    GET_AUCTION_BADE_CAR_LIST_SUCCESS: 'GET_AUCTION_BADE_CAR_LIST_SUCCESS',
    GET_AUCTION_BADE_CAR_LIST_FAIL: 'GET_AUCTION_BADE_CAR_LIST_FAILURE',

    CANCEL_BID_CAR_SUCCESS: 'BID_CANCELED_SUCCESS',
    CANCEL_BID_CAR_FAIL:'BID_CANCELED_FAIL',
    AUCTION_ADD_FLAG :'AUCTION_ADD_FLAG',
    AUCTION_UNSET_FLAG:'AUCTION_UNSET_FLAG'

};