import { VehicleService } from '../../service';
import {VehicleConstants} from '../constants';

export const VehicleActions = {
    saveVehicle,
    uploadVehicleImages,
    getVehicleList,
    removeVehicleImage,
    getVehicleActivity,
    exportVehicleData,
    resetVehicleListFromState,
    getVehicleDetails,
    submitBulkUploadinventory,
    submitBulkUploadInventoryImages,
    submitBulkUploadVehicleImages,
    bulkInventoryDelete,
    bulkInventorySendToInspection
};


function saveVehicle(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.saveVehicle(postData)
        .then(
          saveVehicle => {
           resolve(saveVehicle)
          }
        ).catch(
          saveErr => {
            reject(saveErr);
          }
        )
    });
  };
}

function uploadVehicleImages(postData, headers) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.uploadVehicleImages(postData, headers)
        .then(
          saveVehicle => {
           resolve(saveVehicle.data || {})
          }
        ).catch(
          saveErr => {
            reject(saveErr);
          }
        )
    });
  };
}

function getVehicleList(postData,updateProps=true) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.getVehicleList(postData)
        .then(
          vehicleList => {
            if(vehicleList && vehicleList.data &&  vehicleList.data.status === 200){
              if(updateProps)
                dispatch(success(vehicleList.data));
              resolve(vehicleList.data);
            }
            else{
              resolve((vehicleList && vehicleList.data) ||{});
              if(updateProps)
                dispatch(failure('not_found'));
            }
          },
          vehicleDataError => {
            reject(vehicleDataError);
            if(updateProps)
              dispatch(failure(vehicleDataError));
          }
        );
    });
  };

  function success(vehicleList) { return { type: VehicleConstants.GET_VEHICLE_LIST_SUCCESS, vehicleList } }
  function failure(vehicleDataError) { return { type: VehicleConstants.GET_VEHICLE_LIST_FAILURE, vehicleDataError } }


}


function removeVehicleImage(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.removeVehicleImage(postData)
        .then(
          saveVehicle => {
           resolve(saveVehicle.data || {})
          }
        ).catch(
          saveErr => {
            reject(saveErr);
          }
        )
    });
  };
}


function getVehicleActivity(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.getVehicleActivity(postData)
        .then(
          getLog => {
           resolve(getLog.data || {})
          }
        ).catch(
          err => {
            reject(err);
          }
        )
    });
  };
}

function exportVehicleData(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.exportVehicleData(postData)
        .then(
          csvData => {
           resolve(csvData.data || {})
          }
        ).catch(
          err => {
            reject(err);
          }
        )
    });
  };
}

function resetVehicleListFromState() {
  return dispatch => {
    dispatch(success({}));
  }

  function success(vehicleList) { return { type: VehicleConstants.RESET_VEHICLE_LIST, vehicleList } }

}

function getVehicleDetails(postData) {
  
  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.getVehicleDetails(postData)
        .then(
          details => {
            if(details && details.data &&  details.data.status === 200){
              
              resolve(details.data);
            }
            else{
              resolve((details && details.data) ||{});
            }
          },
          vehicleDataError => {
            reject(vehicleDataError);
          }
        );
    });
  };
}

function submitBulkUploadinventory(postData, headers) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.submitBulkUploadinventory(postData, headers)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function submitBulkUploadInventoryImages(postData, headers) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.submitBulkUploadInventoryImages(postData, headers)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };

}

function submitBulkUploadVehicleImages(postData, headers) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.submitBulkUploadVehicleImages(postData, headers)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };

}

function bulkInventoryDelete(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.bulkInventoryDelete(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };

}

function bulkInventorySendToInspection(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      VehicleService.bulkInventorySendToInspection(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };

}