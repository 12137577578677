import { Component } from 'react';
import { default as config } from '../../config/config';
// import AuctionService from '../../../service/AuctionService';
import socketIOClient from "socket.io-client";

class Socket extends Component {
    
    _BiddingSocket = '';
    
    constructor(props) {
        
        super(props);

        this.state = {
            auctionId: '',
            dealerName: '',
            groupJoined: false,
            users:[]
        }


    }
    

    componentDidMount = () => {

        this.enableSocketEvents()
       
    }

    enableSocketEvents = () => {

        let { SOCKET_CONNECT_URL } = config.constants;
        let { REACT_APP_ENV } = process.env

        let socketServer = SOCKET_CONNECT_URL[REACT_APP_ENV]

        this._BiddingSocket = socketIOClient(socketServer, {
            upgrade: false,
            // reconnection: true
        });

        // this.joinRoom(this._BiddingSocket);

        this.disconnect(this._BiddingSocket);

        // this.updateRealTimeBidAmount(this._BiddingSocket)
        
        this.adminIncomingMsg(this._BiddingSocket)
        
        this.updateAuctionMonitorList(this._BiddingSocket);

        this.updateAuctionBidsData(this._BiddingSocket);
    }

    /**
     * LISTEN INCOMING ADMIN MSGS
     * @returns 
     */
     adminIncomingMsg = (socket) => {
        socket.on('newMessage', function (message) {
            // console.log('Message from Auction Admin. ', message);

        });
    }


    /**
     * UPDATE AUCTION MONITOR BIDS
     * @returns 
     */
     updateAuctionMonitorList = (socket) => {
        socket.on('updateAuctionMonitorBids',  (data) => {
            
            this.props.updatedRealTimeAuctionBidCars(data);

        });
    }


    /**
     * UPDATE AUCTION CAR BIDS DATA
     * @returns 
     */
     updateAuctionBidsData = (socket) => {
        socket.on('updateAuctionBidsData',  (data) => {
            this.props.updatedRealTimeAuctionCarBids(data);

        });
    }    

    /**
     * SOCKET DISCONNECT
     * @param {*} socket 
     */
    disconnect = (socket) => {
        socket.on('disconnectSocket',  () => {
            // console.log('Connection lost from server.');
        });
    }


    render() {
        return (
            ''
        )
    }
}

export default Socket;