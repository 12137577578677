
import React, { useEffect, useState } from 'react';
import Select from 'react-select'; 
import { default as config } from '../../../../config/config';
import { AuctionActions } from "../../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker"; 

import SingleSelectPlaceholderAnimate from '../../../elements/SingleSelectPlaceholderAnimate';
const Auction = [ 
  { value: '1', label: 'Auction Expired' },
];

function FilterBox(props) {
  const { VEHICLE_TYPE,MONTH_DIFF } = config.constants; 

  const [filterField, setFilterField] = useState({auction_type:1,  vehicle_type:config.constants.FourWheeler,  auction_id_hash_in:'', from_date:new Date(new Date().setMonth(new Date().getMonth()-MONTH_DIFF)), to_date: new Date() });
  const [auctionList, setAuctionList] = useState([]);
  

  let fromMinDate =  filterField.to_date ? new Date(new Date(filterField.to_date).setMonth(new Date(filterField.to_date).getMonth()-MONTH_DIFF)) : (filterField.from_date || new Date(new Date().setMonth(new Date().getMonth()-MONTH_DIFF))); 
  let fromMaxDate = filterField.to_date || new Date();
  let toMaxDateValue = filterField.from_date ? new Date(new Date(filterField.from_date).setMonth(new Date(filterField.from_date).getMonth()+MONTH_DIFF)) :  (filterField.to_date || new Date())
  toMaxDateValue = new Date(toMaxDateValue).getTime() > new Date().getTime() ? new Date() : toMaxDateValue

  useEffect(() => {
    props.actions.getAllAuctionList({ listForFilter: true, tab: '3' }).then(auctions => {
      if (auctions.data) {
        setAuctionList(auctions.data)
      }
    })

    //set default dates
    filterRecord({ ...filterField, from_date: fromMinDate, to_date: toMaxDateValue })
  // eslint-disable-next-line
  }, []);


  const changeHandler = (event, type) => { 
    if(type === 'auction_id_hash_in1'){
      let selectedId = event.map((item)=>item.value); 
      setFilterField({ ...filterField, [type]: selectedId });
    }else{
      setFilterField({ ...filterField, [type]: (event)? event.value:'' });
    } 
  }

  const filterRecord = (params=null)=>{
    props.submitFilter(params || filterField)
  }  
   
  const filterReset = async () => {
    let updateState = {
      auction_type:1,  
      vehicle_type:config.constants.FourWheeler,  
      auction_id_hash_in:'', 
      from_date:fromMinDate, 
      to_date:toMaxDateValue
    };
    
    setFilterField(updateState)

    filterRecord(updateState)

}
    
  return (
   
        <div className="dashboard-filter-sec">
          <fieldset className="single-select">
            <div className={(filterField.auction_type ) ? "active-label material animation-effect" : "material"}>        
              <Select components={{ ValueContainer: SingleSelectPlaceholderAnimate }}
                name="auction_type" 
                value={Auction.filter((item)=> +item.value === +filterField.auction_type)}
                onChange={(e)=>changeHandler(e,'auction_type')}
                options={Auction}
                classNamePrefix='auction-dropdown'
                className='auction-dropdown'
                placeholder={props.t('post_auction.auction_type')}

              />
            </div>
          </fieldset>
          <fieldset className="single-select">
            <div className="material animation-effect">
              <Select components={{ ValueContainer: SingleSelectPlaceholderAnimate }}
                name="vehicle_type"
                value={VEHICLE_TYPE.filter((item)=>item.value === filterField?.vehicle_type)}
                onChange={(e)=>changeHandler(e,'vehicle_type')}
                options={VEHICLE_TYPE}
                classNamePrefix='auction-dropdown'
               className='auction-dropdown'
               placeholder={props.t('post_auction.vehicle_type')}
              />
            </div>
          </fieldset>
          <fieldset className="date-filed-dashboard">
            <div className={"material " + (filterField['from_date'] || toMaxDateValue ? ' active-label' : '')}>

              <div className="datepicker">
                <DatePicker
                    name="from_date"
                    selected={filterField.from_date || fromMinDate}
                    onChange={(date) => setFilterField({ ...filterField, from_date: date })}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText={" "}
                    autoComplete="off"
                    minDate={fromMinDate}
                    maxDate={fromMaxDate} 
                    value={filterField.from_date}
                    
                /> 
                <label data-label="From Date" className="form-label"></label>

              </div>
            </div>

          </fieldset>
          <fieldset className="date-filed-dashboard">
            <div className={"material " + (filterField['to_date'] || toMaxDateValue ? ' active-label' : '')}>

              <div className="datepicker">
              <DatePicker
                    name="to_date"
                    selected={filterField.to_date || toMaxDateValue}
                    onChange={(date) => setFilterField({ ...filterField, to_date: date })}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText={props.t('post_auction.created_to')} 
                    autoComplete="off" 
                    minDate={fromMinDate}
                    maxDate={toMaxDateValue}
                    value={filterField.to_date}

                /> 
                <label data-label="To Date" className="form-label"></label>

              </div>
            </div>
          </fieldset>
          <fieldset className="single-select">
            <div className={(filterField.auction_id_hash_in ) ? "material animation-effect" : "material"} >      
                <Select components={{ ValueContainer: SingleSelectPlaceholderAnimate }}
                name="auction_id_hash_in" 
                onChange={(e)=>changeHandler(e,'auction_id_hash_in')}
                options={auctionList}
                value={auctionList.filter((item)=>filterField?.auction_id_hash_in.includes(item.value) )}
                classNamePrefix='auction-dropdown' 
                placeholder={props.t('post_auction.auction_name')}
                isClearable={true}
              />
            </div>
          </fieldset>
            <div className="btn-submit-reset-auction-tabs">
              {
                !props.componentData || !props.componentData.loading
                ?
                  <button type="submit" className="btn btn-primary" onClick={()=>filterRecord()}>Search</button>
                :
                  <button type="button" className="btn btn-primary" >searching...</button>
                  
              }

              <button type="reset" onClick={filterReset} className="btn-reset">{props.t('common.reset')}</button>

            </div>
        </div>


  )

}

const mapStateToProps = state => {
  return { 
      auctionVehicleFilterList: state.common.auctionVehicleFilterList || {} 
  }

};

const mapDispatchToProps = dispatch => {
  return {
      actions: { 
          getAllAuctionList: bindActionCreators(
              AuctionActions.getAuctionList,
              dispatch
          ),
          getPostAuctionDetail: bindActionCreators(
            AuctionActions.getPostAuctionDetail,
            dispatch
        )
      }
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterBox))); 