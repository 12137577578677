import React, { Component } from 'react';
import no_vechile_img from "../../../webroot/images/no_vechile_img.svg";

class AddVechileAuction extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }


    render() {

        return (
            <div class="auction-buyer-sec">
                <div className="buyer-heading col-md-12">
                    <h3>Add Vehicle</h3>
                </div>
                <div className="col-md-6">
                    <span className="add-buyers-txt">Add from Approved List</span>
                    <div className="buyer-list-bx">
                        <div className="select-all-search">
                            <li className="custom-control custom-checkbox">
                                <input id="all" type="checkbox" className="custom-control-input" />
                                <label for="all" className="custom-control-label">Select All</label>
                            </li>
                            <fieldset class="search-bx">
                                <input id="" type="text" placeholder="Search " name="" class="form-input" />
                                <i className="ic-search"></i>
                            </fieldset>
                        </div>
                        <div className="buyers-added-list">
                            <ul>
                                <li className="custom-control custom-checkbox">
                                    <input id="all1" type="checkbox" className="custom-control-input" />
                                    <label for="all1" className="custom-control-label"> Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>



                                <li className="custom-control custom-checkbox">
                                    <input id="all2" type="checkbox" className="custom-control-input" />
                                    <label for="all2" className="custom-control-label"> Tata Harrier Camzo (83HDD945) 12BG 453, Faridabad</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all3" type="checkbox" className="custom-control-input" />
                                    <label for="all3" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Gurgaon</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all4" type="checkbox" className="custom-control-input" />
                                    <label for="all4" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all5" type="checkbox" className="custom-control-input" />
                                    <label for="all5" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Faridabad</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all6" type="checkbox" className="custom-control-input" />
                                    <label for="all6" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Gurgaon</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all7" type="checkbox" className="custom-control-input" />
                                    <label for="all7" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>

                                <li className="custom-control custom-checkbox">
                                    <input id="all9" type="checkbox" className="custom-control-input" />
                                    <label for="all9" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>

                            </ul>
                            <button className="btn-primary" disabled>Add</button>
                        </div>

                    </div>
                </div>

                <div className="col-md-6">
                    <span className="add-buyers-txt">Added Vehicle</span>
                    <div className="buyer-list-bx">
                        <div className="select-all-search">
                            <li className="custom-control custom-checkbox">
                                <input id="all" type="checkbox" className="custom-control-input" />
                                <label for="all" className="custom-control-label">Select All</label>
                            </li>
                            <fieldset class="search-bx">
                                <input id="" type="text" placeholder="Search " name="" class="form-input" />
                                <i className="ic-search"></i>
                            </fieldset>
                        </div>
                        <div className="buyer-no-data-bx" style={{ display: "none" }}>
                            {<img src={no_vechile_img} className="" alt = ""/>}
                            <span className="no-img-txt">No buyers are added</span>
                        </div>
                        <div className="buyers-added-list">
                            <ul>
                                <li className="custom-control custom-checkbox">
                                    <input id="all1" type="checkbox" className="custom-control-input" />
                                    <label for="all1" className="custom-control-label"> Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>



                                <li className="custom-control custom-checkbox">
                                    <input id="all2" type="checkbox" className="custom-control-input" />
                                    <label for="all2" className="custom-control-label"> Tata Harrier Camzo (83HDD945) 12BG 453, Faridabad</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all3" type="checkbox" className="custom-control-input" />
                                    <label for="all3" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Gurgaon</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all4" type="checkbox" className="custom-control-input" />
                                    <label for="all4" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all5" type="checkbox" className="custom-control-input" />
                                    <label for="all5" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Faridabad</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all6" type="checkbox" className="custom-control-input" />
                                    <label for="all6" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Gurgaon</label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                    <input id="all7" type="checkbox" className="custom-control-input" />
                                    <label for="all7" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>

                                <li className="custom-control custom-checkbox">
                                    <input id="all9" type="checkbox" className="custom-control-input" />
                                    <label for="all9" className="custom-control-label">Tata Harrier Camzo (83HDD945) 12BG 453, Delhi</label>
                                </li>

                            </ul>
                            <button className="btn-primary" >Remove</button>
                        </div>
                    </div>
                </div>
            </div>




        );
    }
}




export default AddVechileAuction;