import MasterService from './MasterService';


export const UserService = {
    ...MasterService,
    
    login(params){
        return this.post('/auth/login',params)
    },
    loginCaptcha(){
        return this.get('/auth/getCaptchaImage')
    },
    authLogout() {
        return this.post('/auth/logout', {});
    },
    getSiteSettings() {
        return this.get('/auth/getSiteSettings');
    },
    getUserList(){
        return this.post('/admin/listAllUser') 
    },
    getCreatedByList() {
      return this.get('user/getAllCreatedByList')
    }

}