import React, { Component } from 'react';

import Navbar from "./Navbar";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { UserActions } from "../../store/action";
import { bindActionCreators } from 'redux';
import secureStorage from '../../config/encrypt';
import {default as config} from './../../config/config';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      redirect: 0,
      loginuser: '',
      menuOpen:false,
      headerSettings: null,
      language:''
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    }); 
    
  }

  componentDidMount = () => {
    if(!this.state.headerSettings){
      this.getSiteSettings();
    }
  }

  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
    document.body.classList.toggle('body-hidden');
  }
  
  handleLinkClick() {
    this.setState({menuOpen: false});
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if(nextProps.siteSettings && !prevState.siteSettings ){
        return ({ headerSettings: nextProps.siteSettings.header, language: nextProps.siteSettings.language })
    }
    return null;
  }

  getSiteSettings() {
    const { i18n } = this.props;
    let langData = config.constants.language;

    this.props.actions.getSiteSettings().then(res => {
      if(res.status===200){
        if(res.data['language']){
          //MATCH IF STORED CODE IS IN CONFIG FILE MATCHED
          let matchLang = langData.find(l=>{ return (l.iso_code === secureStorage.getItem('lang')) ? true :false });
          
          //UPDATE LANGUAGE IF NOT IN STORAGE OR NOT IN CONFIG FILE
          if(!secureStorage.getItem('lang') || !matchLang){
            secureStorage.setItem('lang', (res.data['language']));
            i18n.changeLanguage(res.data['language']);
          }
        }
      }
    });
  }

  render() {

      const { headerSettings } = this.state
      const currentPath = this.props.location.pathname;

      if(!currentPath.includes('login') )
        {
        return (
            <header>
                <Navbar romHeader={currentPath}  headerMenu={(headerSettings && headerSettings.menuLinks) || {}}/>
            </header>
        )
      }else{
        return("");
      }
  }
}



const mapStateToProps = state => {
  return {
     siteSettings: state.auth.siteSettings
  }
}

const mapDispatchToProps = dispatch => {
  return {
             actions: {
                getSiteSettings: bindActionCreators(
                  UserActions.getSiteSettings,
                  dispatch
                )
             }
          }
}

export default withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));