import Modal from './../elements/PopupModal';
import { withTranslation } from 'react-i18next';

const ConfirmationModal = (props) => {

    const submitResponse = (response) => {
        props.confirmationModalResponse({response, type: props.confirmationData.type});
    }

    const hideModalReplicate = () => {}
    
    let { show, confirmationText='' } = props.confirmationData;

    return (
        <div className="view-timeline-popup replicate-popup confirmation-popup">

            <Modal show={show} handleClose={hideModalReplicate} >
                <div className="modal-header">
                </div>
                <div className="modal-body" >
                    <span className="replace-txt">{confirmationText}</span>

                </div>
                <div className="yes-no-btn">
                    <button className="btn-line" onClick={()=>submitResponse(false)}>
                        {props.t('common.no')}
                    </button>
                    <button className="btn-primary" onClick={()=>submitResponse(true)}>{props.t('common.yes')}</button>
                </div>
            </Modal>
        </div>
    );
  };
  export default withTranslation('common')(ConfirmationModal);
  