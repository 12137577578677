import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import ActiveUsers from './activeUsers';
import Messages from './messages';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {default as config } from '../../../../config/config';

// import LoadingScreen from 'react-loading-screen';

var socket;
const initialState = {
    users: [],
    messages: [],
    newMsg: '',
    fetchingLocation: false
}

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        }
    }

    componentWillUnmount(){
        const param = {
            name: this.props.match.params.name,
            room: this.props.match.params.room
        }
        socket.emit('leave', param);
        this.setState({...initialState});
    }

    componentDidMount() {
        const scopeThis = this;
        const params = {
            name: this.props.match.params.name,
            room: this.props.match.params.room
        }

        socket = io(config.constants.CORE_URL)
        // console.log('socket', socket)

        socket.emit('join', params, function (err) {
            // console.log('params', params)
            if (err) {
                this.props.history.push('/');
            }
        });

        socket.on('updateUserList', function (users) { 
            scopeThis.setState({
                users
            });
        });

        socket.on('newMessage', (message) => { 
            var formattedTime = moment(message.createdDate).format('h:mm a');
            let newMsg = {
                text: message.text,
                from: message.from,
                room: message.room,
                createdDate: formattedTime
            }
            let results = scopeThis.state.messages;
            results.push(newMsg);
            scopeThis.setState({
                messages: results
            });

            var msgArr = scopeThis.state.messages.filter(message => message.room === this.props.match.params.room);
            
            if (msgArr.length > 3) {
                scopeThis.scrollToBottom();
            }
        });

        socket.on('createLocationMsg', (message) => {
            var formattedTime = moment(message.createdDate).format('h:mm a');
            let newMsg = {
                url: message.url,
                from: message.from,
                room: message.room,
                createdDate: formattedTime
            }
            let results = scopeThis.state.messages;
            results.push(newMsg);
            scopeThis.setState({
                messages: results,
                fetchingLocation: false
            });
        });

        socket.on('disconnect', function () {
            console.log('Connection lost from server.');
        });

    }

    scrollToBottom() {
        // selectors
        // var listHeight = document.querySelector('.messages #list');
        var messagesList = document.querySelector('.messages #list');
        var newMessage = document.querySelector('.messages #list .msg_history > div:last-child');
        // heights
        // var messagesWrapperHeight = listHeight.clientHeight || 0;
        var clientHeight = messagesList.clientHeight || 0;
        var scrollTop = messagesList.scrollTop || 0;
        var scrollHeight = messagesList.scrollHeight || 0;
        var newMessageHeight = newMessage.offsetHeight || 0;
        var lastMessageHeight = newMessage.previousSibling.offsetHeight || 0;

        if (clientHeight + scrollTop + newMessageHeight + lastMessageHeight >= scrollHeight) {
            // document.querySelector('#list').scrollTo(0, document.getElementById('msgsList').scrollHeight)
            document.querySelector('#list .msg_history >  div:last-child').scrollIntoView()
        }

    }

    clearForm() {
        this.setState({
            newMsg: ''
        });
    }

    inputUpdate(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    newMessage(e) {
        e.preventDefault()
        var obj = {
            'text': this.state.newMsg
        };
        socket.emit('createMessage', obj, function (data) { });
        this.clearForm();
    }

    sendLocation() {
        this.setState({
            fetchingLocation: true
        });
        if (!navigator.geolocation) {
            return alert('GeoLocation not supported by your browser');
        }
        navigator.geolocation.getCurrentPosition((pos) => {
            socket.emit('createLocationMsg', {
                lat: pos.coords.latitude,
                lon: pos.coords.longitude
            });
        }, () => {
            alert('Unable to fetch location');
        });
    }

    render() {

        const { newMsg } = this.state;

        let msgArr = this.state.messages.filter(message => message.room === this.props.match.params.room);

        return (
            <div className="messaging">
                <div className="inbox_msg">
                    <ActiveUsers users={this.state.users} />
                    
                    <div className="mesgs">
                        <Messages messages={msgArr||[]} room={this.props.match.params.room} />
                        
                        <div className="type_msg">
                            <form onSubmit={(e) => this.newMessage(e)}>

                                <div className="input_msg_write">
                                <input type="text" name="newMsg" className="write_msg" autoComplete="off" placeholder="Type a message" value={newMsg} onChange={this.inputUpdate.bind(this)}/>
                                <button className="msg_send_btn" type="submit"><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                
                                
                </div>
        )
    }
}


const mapStateToProps = state => {
    return {
       auth: state.auth.auth
    }
 }

const mapDispatchToProps = dispatch => {
    return {}
}

export default withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat)));