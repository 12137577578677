import React, { Component } from 'react';
import { DateTimePicker, EndDateTimePicker } from './DatePicker'
import Pagination from "./pagination"
import MultiSelect from '../../common/MultiSelect'

const BankAuction = [
    { value: 'Tata Harrier Camzo (83HDD945) 12BG 453, Delhi', label: 'Tata Harrier Camzo (83HDD945) 12BG 453, Delhi' },
    { value: 'Tata Harrier Camzo (83HDD945) 12BG 453, Nodia', label: 'Tata Harrier Camzo (83HDD945) 12BG 453, Nodia' },
    { value: 'Tata Harrier Camzo (83HDD945) 12BG 453, Jaipur', label: 'Tata Harrier Camzo (83HDD945) 12BG 453, Jaipur' },
];

class InventoriesTable extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            Replicate: false,


        };
    }
    handleChangeBankAuction = (BankAuctionOption) => {
        this.setState({ BankAuctionOption }
        );
    };

    showModalReplicate = () => {
        this.setState({ Replicate: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalReplicate = () => {
        this.setState({ Replicate: false });
        document.body.classList.remove("overflow-hidden");
    }




    render() {
        const { BankAuctionOption } = this.state;
        return (
            <div>
                <div className="inventrios-data-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Vehicle Name  & Information</th>
                                <th>Start/End Date & TIme</th>
                                <th>BID</th>
                                <th>Price</th>
                                <th>Auto Time Increment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="cars-detail">
                                        <span>Ford Figo </span>
                                        <ul>
                                            <li>Vid: 1234567</li>
                                            <li>Gurgaon</li>
                                            <li>HR 87B 9994</li>
                                            <li>Diesel</li>
                                            <li>2018 Model</li>
                                            <li>45,000 kms</li>
                                            <li>1st Owner</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="date-time-sec">
                                        <fieldset class="create-date m-lg-b">
                                            <div class="material">
                                                <DateTimePicker />
                                            </div>
                                        </fieldset>
                                        <fieldset class="create-date">
                                            <div class="material">
                                                <EndDateTimePicker />
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment Amount" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Bid Count" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Reserved Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Base Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Expected Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed auto-time-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Cycles" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Trigger (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <li className="custom-control custom-checkbox" onClick={this.showModalReplicate}>
                                            <input id="all" type="checkbox" className="custom-control-input" />
                                            <label for="all" className="custom-control-label">Replicate</label>
                                        </li>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="cars-detail">
                                        <span>Ford Figo </span>
                                        <ul>
                                            <li>Vid: 1234567</li>
                                            <li>Gurgaon</li>
                                            <li>HR 87B 9994</li>
                                            <li>Diesel</li>
                                            <li>2018 Model</li>
                                            <li>45,000 kms</li>
                                            <li>1st Owner</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="date-time-sec">
                                        <fieldset class="create-date m-lg-b">
                                            <div class="material">
                                                <DateTimePicker />
                                            </div>
                                        </fieldset>
                                        <fieldset class="create-date">
                                            <div class="material">
                                                <EndDateTimePicker />
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment Amount" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Bid Count" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Reserved Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Base Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Expected Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Cycles" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Trigger (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="cars-detail">
                                        <span>Ford Figo </span>
                                        <ul>
                                            <li>Vid: 1234567</li>
                                            <li>Gurgaon</li>
                                            <li>HR 87B 9994</li>
                                            <li>Diesel</li>
                                            <li>2018 Model</li>
                                            <li>45,000 kms</li>
                                            <li>1st Owner</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="date-time-sec">
                                        <fieldset class="create-date m-lg-b">
                                            <div class="material">
                                                <DateTimePicker />
                                            </div>
                                        </fieldset>
                                        <fieldset class="create-date">
                                            <div class="material">
                                                <EndDateTimePicker />
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment Amount" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Bid Count" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Reserved Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Base Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Expected Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Cycles" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Trigger (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="cars-detail">
                                        <span>Ford Figo </span>
                                        <ul>
                                            <li>Vid: 1234567</li>
                                            <li>Gurgaon</li>
                                            <li>HR 87B 9994</li>
                                            <li>Diesel</li>
                                            <li>2018 Model</li>
                                            <li>45,000 kms</li>
                                            <li>1st Owner</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="date-time-sec">
                                        <fieldset class="create-date m-lg-b">
                                            <div class="material">
                                                <DateTimePicker />
                                            </div>
                                        </fieldset>
                                        <fieldset class="create-date">
                                            <div class="material">
                                                <EndDateTimePicker />
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment Amount" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Bid Count" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Reserved Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Base Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Expected Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Cycles" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Trigger (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="cars-detail">
                                        <span>Ford Figo </span>
                                        <ul>
                                            <li>Vid: 1234567</li>
                                            <li>Gurgaon</li>
                                            <li>HR 87B 9994</li>
                                            <li>Diesel</li>
                                            <li>2018 Model</li>
                                            <li>45,000 kms</li>
                                            <li>1st Owner</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="date-time-sec">
                                        <fieldset class="create-date m-lg-b">
                                            <div class="material">
                                                <DateTimePicker />
                                            </div>
                                        </fieldset>
                                        <fieldset class="create-date">
                                            <div class="material">
                                                <EndDateTimePicker />
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment Amount" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Bid Count" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Reserved Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Base Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Expected Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Cycles" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Trigger (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="cars-detail">
                                        <span>Ford Figo </span>
                                        <ul>
                                            <li>Vid: 1234567</li>
                                            <li>Gurgaon</li>
                                            <li>HR 87B 9994</li>
                                            <li>Diesel</li>
                                            <li>2018 Model</li>
                                            <li>45,000 kms</li>
                                            <li>1st Owner</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="date-time-sec">
                                        <fieldset class="create-date m-lg-b">
                                            <div class="material">
                                                <DateTimePicker />
                                            </div>
                                        </fieldset>
                                        <fieldset class="create-date">
                                            <div class="material">
                                                <EndDateTimePicker />
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment Amount" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Bid Count" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Reserved Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Base Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Expected Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Cycles" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Trigger (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="cars-detail">
                                        <span>Ford Figo </span>
                                        <ul>
                                            <li>Vid: 1234567</li>
                                            <li>Gurgaon</li>
                                            <li>HR 87B 9994</li>
                                            <li>Diesel</li>
                                            <li>2018 Model</li>
                                            <li>45,000 kms</li>
                                            <li>1st Owner</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="date-time-sec">
                                        <fieldset class="create-date m-lg-b">
                                            <div class="material">
                                                <DateTimePicker />
                                            </div>
                                        </fieldset>
                                        <fieldset class="create-date">
                                            <div class="material">
                                                <EndDateTimePicker />
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment Amount" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Bid Count" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Reserved Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Base Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Expected Price" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-sec data-filed">
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Increment (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="m-lg-b">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Cycles" class="form-label"></label>
                                            </div>
                                        </fieldset>
                                        <fieldset class="">
                                            <div class="material">
                                                <input type="text" className="form-input" placeholder=" " />
                                                <label data-label="Trigger (s)" class="form-label"></label>
                                            </div>
                                        </fieldset>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="pagination-outer">
                        <Pagination />

                    </div>
                    <div class="btn-add-auction update-btn"><button class="btn-primary">Update & Save</button></div>
                </div>
                <div className="view-timeline-popup replicate-popup">
                    <Modal show={this.state.Replicate} handleClose={this.hideModalReplicate} >
                        <div className="modal-header">

                        </div>
                        <div className="modal-body" >
                            <span className="replace-txt">Do you want to replicate all the auto increment details?</span>

                        </div>
                    </Modal>
                </div>

                <div className="view-timeline-popup add-vechile-popup">
                    <Modal show={this.state.AddVehile} handleClose={this.hideModalAddVehile} >
                        <div className="modal-header">
                            <h2>Add Vehicle</h2>
                        </div>
                        <div className="modal-body" >
                            <fieldset class=" multiselect-dropDown">
                                <div class="material">
                                    <label className="option-txt">Select from Approved List</label>
                                   
                                     <MultiSelect
                                                        isMulti={true}
                                                        options={BankAuction}
                                                        placeholder="Select from List"
                                                        value={BankAuctionOption}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                    />
                                </div>
                            </fieldset>

                        </div>
                    </Modal>
                </div>
            </div>




        );
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                {/* <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button> */}
                <div className="yes-no-btn">
                    <button className="btn-line" onClick={handleClose}>
                        No
                    </button>
                    <button className="btn-primary" >Yes</button>
                </div>
            </section>
        </div>
    );
};



export default InventoriesTable;