import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DateFormat from 'dateformat';
import { bindActionCreators } from 'redux';
import { AuctionActions } from "../../../store/action";
import ContentLoader from '../../common/ContentLoader';

function AuctionActivityLog(props) {
    const [activity, setActivity] = useState([]);

    useEffect(() => {
        setActivity({loading: true });
        //GET ACTIVITY LOGS
        let params = {
            auction_id: ''+props.auctionId
        }
        props.actions.getAuctionActivity(params).then(timelineData=>{
            setActivity({loading: false, timelineDetails: timelineData.data || []})

        }).catch(err=>{
            setActivity({loading: false})
        })

    }, [props]);

    const { timelineDetails,loading } = activity;
    
        return (

            <> 

                <div className="modal-header">
                <h2>{props.t('pages.auction.edit.timeline')}</h2>
                </div>
                {/* CONTENT LOADER */}
                <ContentLoader show={loading} />

                <div className="modal-body" style={{display: (loading?'none':'block') }}>
                    {
                        timelineDetails && timelineDetails.length
                        ?
                            <table>
                                <tbody>
                                {timelineDetails.map((tlData, k) => {

                                        return <tr key={k}>
                                            <td>
                                                <span className="date">{ DateFormat(tlData.created_at, "dd mmm, yyyy")}</span>
                                                <span className="time">{ DateFormat(tlData.created_at, "hh:MM TT")}</span>
                                            </td>
                                            <td>
                                                <span className="heading">{tlData.comment}</span>
                                                <span className="Subheading">{(tlData.user && tlData.user.name)}</span>
                                            </td>
                                            <td>{(tlData.user && tlData.user.name)}</td>
                                        </tr>
                                    
                                    })
                                }
                                </tbody>
                            </table>
                        :
                            <p style={{textAlign:'center'}}>{props.t('common.no_records_found')}</p>

                    }
                    
                </div>
            
            </>
        );
}

const mapStateToProps = (state, ownProps) => ({

    
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getAuctionActivity:bindActionCreators(
                AuctionActions.getAuctionActivity,
                dispatch
            ),
        }
    }
}

export default  withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AuctionActivityLog));
