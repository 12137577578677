import React, { Component } from 'react';
import App from '../components/FileUpload'
import EditVechileform from '../components/EditVechileDetailForm'


class EditVechile extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {


        };
    }

    render() {
        return (
            <div className="container-fluid-full">
                <div className="edit-vechile-heading">
                    <span>Edit Vehicle</span>
                    <h1>Ford Freestyle Titanium P</h1>
                </div>
                <div className="edit-vehile-sec">
                    <div className="edit-file-upload-bx">
                        <App />
                    </div>
                    <div className="edit-vechile-list">
                        <EditVechileform />
                    </div>
                </div>

            </div>


        );
    }
}


export default EditVechile;