import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import auction_Illustration from "../../../../webroot/images/auction_Illustration.svg";
import InputField from '../../../elements/InputField';
import { connect } from 'react-redux';
import { UserActions } from "../../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import Captcha from '../../../common/Captcha';
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import secureStorage from './../../../../config/encrypt';
import { default as config } from '../../../../config/config';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();

        this.state = {
            fields: {
                "userId": "",
                "password": "",
                "captcha": "",
            },
            errors: {},
            submitted: false,
            componentIteration: 1,
            searchParam: new URLSearchParams(window.location.search),
            passwordShow: false
        };
    }



    componentDidMount = async () => {
        if (document.getElementById("userId")) document.getElementById("userId").focus();
        this._isMounted = true;
    }

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    handleInputChange = async (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        if (this._isMounted) {
            await this.setState({
                fields
            });
        }

    }

    /**
     * Login Submit Handler
     */
    submitLogin = async (e) => {
        e.preventDefault();
        if (this._isMounted) this.setState({ submitted: true });
        const { userId, password, captcha } = this.state.fields;
        if (await this.validateForm()) {

            let user_type = 'auction'
            if (this.state.searchParam.get('type') && this.state.searchParam.get('type') === 'seller') {
                user_type = 'seller'
            }
            const loginParams = { username: userId, password, captcha, hash: this.props.captchaValue, user_type }

            this.props.actions.loginSuccess(loginParams).then(async (resp) => {
                if (this._isMounted) this.setState({ submitted: false });
                if (this._isMounted) this.setState({ componentIteration: 1 })

                if (resp && resp.status === 200) {
                    //DELETE CAPTCHA HASH FROM CACHE
                    secureStorage.removeItem("loginCaptchaHash");

                }
            });
        }
    }

    captchaFiledHandler = (childData) => {
        let fields = this.state.fields;
        fields['captcha'] = childData;

        if (this._isMounted) {
            this.setState({
                fields
            });
        }

    }

    /**
     * Form Validations
     */

    validateForm = async function () {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let emailRegEx = /\S+@\S+\.\S+/

        if (this._isMounted) this.child.resetCaptchaError(); // RESET CAPTCHA ERROR

        if (!emailRegEx.test(fields["userId"].trim())) {
            formIsValid = false;
            errors["userId"] = this.props.t('login.enter_valid_email');
        }

        if (!fields["password"].trim()) {
            formIsValid = false;
            errors["password"] = this.props.t('login.enter_password');
        }

        if (!fields['captcha'].trim()) {
            formIsValid = false;
            errors["captcha"] = this.props.t('login.captcha_not_matched');

            this.child.loadCaptchaImage(errors);  //Load captcha again
        }

        if (this._isMounted) {
            await this.setState({
                errors: errors,
                submitted: formIsValid
            });
        }

        return formIsValid;
    }

    componentDidUpdate() {
        const { invalidLogin } = this.props;

        if (this._isMounted && invalidLogin && this.state.componentIteration === 1) {
            this.child.loadCaptchaImage();  //Load captcha again
            this.setState({ componentIteration: 2 })
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (this._isMounted && nextProps.invalidLogin !== prevState.invalidLogin) {
            return ({ submitted: false })
        }
        return null;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClickShowPassword = () => {
        this.setState({ passwordShow: !this.state.passwordShow })
    }

    render() {
        const { redirectTo } = this.props;
        const { submitted, passwordShow, errors } = this.state;
        let authUser = secureStorage.getItem('authUserInfo') || {};
        let dashboardRedirect = (authUser && authUser.dashboardRedirect) || ''; //DASHBOARD REDIRECT ROUTE CHECK

        if (typeof redirectTo != 'undefined' || Object.keys(authUser).length) {

            return <Redirect to={'/' + dashboardRedirect} />
        }

        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="login-outer">
                            <div className="login-left-panel">
                                <div className="login-bg">
                                    {<img src={auction_Illustration} className="" alt="" />}
                                    <div className="logo-img">
                                        {<img src={config.constants.LOGIN_PAGE_LOGO.default} className="" alt="" />}
                                    </div>
                                </div>
                            </div>
                            <div className="login-right-panel">
                                <div className="login-heading">
                                    <span>{this.props.t('login.login_to')}</span>
                                    <h1>{this.props.t('login.get_started')}</h1>
                                </div>
                                <Tab.Container id="" defaultActiveKey="first">
                                    <div className="login-tab">
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">{this.props.t('login.by_email')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">{this.props.t('login.by_mobile')}</Nav.Link>
                                            </Nav.Item>
                                        </Nav>

                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <div className="login-form">
                                                    <fieldset className="">
                                                        <div className={errors.userId ? "material error" : "material"}>

                                                            <InputField
                                                                inputProps={{
                                                                    id: "userId",
                                                                    type: "text",
                                                                    name: "userId",
                                                                    placeholder: " ",
                                                                    value: this.state.fields.userId,
                                                                    className: "form-input"
                                                                }}
                                                                onChange={this.handleInputChange}
                                                            />

                                                            <i className="ic-email1 icn-login"></i>
                                                            <label data-label="Email" className="form-label"></label>
                                                            <span className="erroe-txt">{errors.userId}</span>

                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="">
                                                        <div className={errors.password ? "material error" : "material"}>
                                                            <InputField
                                                                inputProps={{
                                                                    id: "password",
                                                                    type: passwordShow ? "text" : "password",
                                                                    name: "password",
                                                                    placeholder: " ",
                                                                    value: this.state.fields.password,
                                                                    className: "form-input password-txt"

                                                                }}
                                                                onChange={this.handleInputChange}
                                                            />

                                                            <i className="ic-password icn-login"></i>
                                                            {/* <a className="forget-password" href="">{this.props.t('login.forgot_password')}</a> */}
                                                            <label data-label="Password" className="form-label"></label>
                                                            <span className="password-show" onClick={this.handleClickShowPassword}><i className="ic-hide-password"></i>
                                                                <i className="ic-show-password">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                            </span>
                                                            <span className="erroe-txt">{errors.password}</span>

                                                        </div>

                                                    </fieldset>

                                                    <fieldset className=" captcha-filed">
                                                        <Captcha onKeyPressCaptcha={this.captchaFiledHandler} childRef={Ref => this.child = Ref} />
                                                    </fieldset>

                                                    {
                                                        !submitted
                                                            ?
                                                            <button type="button" onClick={this.submitLogin} className="btn-primary">{this.props.t('login.login')}</button>
                                                            :
                                                            <button type="button" className="btn-primary">{this.props.t('login.please_wait')}</button>
                                                    }

                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className="login-form">
                                                    <fieldset className="">
                                                        <div className="material">
                                                            <input id="mobile" type="text" placeholder=" " name="Mobile No." className="form-input" disabled />
                                                            <i className="ic-mobile icn-login"></i>
                                                            <label data-label="Mobile No." className="form-label"></label>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="">
                                                        <div className="material">
                                                            <input id="otp" type="text" placeholder=" " name="Password" className="form-input" disabled />
                                                            <i className="ic-otp icn-login">
                                                                <i className="path1"></i>
                                                                <i className="path2"></i>
                                                                <i className="path3"></i>
                                                                <i className="path4"></i>
                                                            </i>
                                                            <label data-label="OTP" className="form-label"></label>
                                                            <span className="otp-time">00:49</span>
                                                            <div className="otp-success-icon" style={{ display: "none" }} >
                                                                <i className="ic-check"></i>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <button type="button" className="btn-primary">Get OTP</button>
                                                </div>
                                            </Tab.Pane>


                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}


const mapStateToProps = state => {
    return {
        captchaValue: state.auth.captchaValue,
        invalidLogin: state.auth.invalidLogin,
        siteSettings: state.auth.siteSettings
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            loginSuccess: bindActionCreators(
                UserActions.login,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));