import React from "react";
import InputField from "../elements/InputField";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UserActions } from "../../store/action";
import { bindActionCreators } from "redux";

class Captcha extends React.Component {
  _isMount = false
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      captcha: "",
      captchaInvalid: ""
    };
  }

  componentDidMount() {
    this._isMount = true
    const { childRef } = this.props;
    childRef(this);
    this.loadCaptchaImage();
  }

  componentWillUnmount() {
    this._isMount = false
    const { childRef } = this.props;
    childRef(undefined);
  }

  /**
   *  Load Captcha Image
   */

  loadCaptchaImage = (err = []) => {
    if(this._isMount){
      this.setState({
        captcha: ""
      });
  
      if (typeof err.captcha !== "undefined") {
        this.setState({
          captchaInvalid: err.captcha
        });
      }
      this.props.actions.loginCaptcha();
    }
    
  };

  /**
   * Input Handler
   * @param e (synthatic element)
   */
  handleInputChange = async e => {
    if(this._isMount){
      await this.setState({
        captcha: e.target.value
      });
      this.props.onKeyPressCaptcha(this.state.captcha);
    }
    
  };

  /**
   * Reset Captcha Error
   */
  resetCaptchaError = async () => {
    if(this._isMount){
      await this.setState({
        captchaInvalid: ""
      });
    }
    
  };

  render() {
    const { captchaImage, captchaError } = this.props;
    const { captchaInvalid } = this.state;

    return (
      <>
        <div className={captchaInvalid ? "material error": "material"}>
            {/* <div className={"col-md-6"}> */}
              {/* <div className={"col-md-2"}> */}
                <InputField
                  inputProps={{
                    id: "captcha",
                    type: "text",
                    name: "captcha",
                    placeholder: " ",
                    value: this.state.captcha,
                    className: "form-input"
                  }}
                  onChange={this.handleInputChange}
                />

                <i className="ic-captcha icn-login"></i>
                <label data-label="Captcha" className="form-label"></label>
                <span className="erroe-txt">{captchaInvalid||''}</span>

                <span className="password-show" style={{ "fontSize": '20px'}}>
                  {captchaError ? (
                    ''
                  ) : (
                    <>
                      {/* <img src={captchaImage} className="captchaimg" alt="" style={{width:'80%'}}/> */}
                        <img src={`data:image/svg+xml;base64,${window.btoa(captchaImage)}`} className="captchaimg" alt=""  />

                      <span
                        className="reload"
                        onClick={this.loadCaptchaImage}
                        title="reload captcha"
                      >
                        &#x21bb;
                      </span>
                    </>
                  )}
                </span>
          
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth,
    loggingIn: state.auth.loggingIn,
    captchaImage: state.auth.captchaImage,
    captchaValue: state.auth.captchaValue,
    captchaError: state.auth.captchaError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      loginCaptcha: bindActionCreators(UserActions.loginCaptcha, dispatch)
    }
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Captcha)
);
