import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

function Previews(props) {
    const [files, setFiles] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [sliderIndex, setSliderIndex] = useState({photoIndex: 0});
    const [imageLightbox, setImageLightbox] = useState({isOpen: false});
    
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            let validreq = true;
            acceptedFiles.forEach((file) => {
                let image_size = parseInt(file.size, 10);
                if(!['image/jpg','image/jpeg','image/png'].includes(file.type)){
                    toast.error(props.t('pages.vehicle.add.select_valid_image_file'));
                    validreq = false;
                }
                else if (image_size < 25000 || image_size > 8000000) {
                    toast.error(props.t('pages.vehicle.add.image_size_valid'));
                    validreq = false;
                }
            })
            let uploadFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            if (validreq) {
                setFiles(
                    files.concat(uploadFiles)
                );
            }
        }
    });

  
    if (!props.componentData.removeAllImageFlag && props.preUploadedImages && props.preUploadedImages.length && firstLoad) { 
        const preUploadedImages = props.preUploadedImages.map((file, index) => {
            return {
                preview: file.url,
                filename: file.image_name,
                index:index
            }
        });
        if (!files.length) {
            setFiles(
                preUploadedImages
            )
            setFirstLoad(false);

        }
    }
    else if (props.componentData && props.componentData.removeAllImageFlag ) {  //DELETE ALL IMAGES IF FLAG IS TRUE
        if (files.length) { 
            setFiles(
                []
            )
        }
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        // files.forEach(file => URL.revokeObjectURL(file.preview)); 
        //CHECK IF NEW FILE EXISTS
        let newFiles = false  
        for(let file of files){
            if(file.path) newFiles = true
        }
        //IF ANY IMAGE REMOVED THEN UPDATE newFiles FLAG
        if(files.length !== props.preUploadedImages.length)  newFiles = true;

        props.handelFilesUpload(files, newFiles);
    // eslint-disable-next-line
    }, [files]);

    const deleteImage = (e, eleIndex) => {
        e.stopPropagation();
        files.forEach((file, idx) => {
            if (eleIndex === idx) {
                URL.revokeObjectURL(file.preview);
            }
        });
        let filesArr = [...files];
        filesArr.splice(eleIndex, 1);
        setFiles(
            filesArr
        );
    }


    let { isOpen } = imageLightbox;
   // eslint-disable-next-line
    let {photoIndex} = sliderIndex;
    const images =  files && files.length ? files.map(img=> img.preview) : [];
 
    const set_image_index = (isOpen, Index) => {
        setSliderIndex({ photoIndex: Index });
        setImageLightbox({ isOpen: isOpen });
    }

    return (

            <>
                {
                    files && files.length
                    ?
                        <div className="vechile-gallery">
                            <ul>
                                {
                                    files.map((file, key)=>
                                        <li className="vehicle-lightbox-slider" key={key} onClick={() => set_image_index(true,key)}>
                                            <img src={file.preview} name={file.filename} className="" alt= ""/>
                                            {(props.loginType ==='seller')?null :
                                            <i className="ic-clearclose"  onClick={(e) => { deleteImage(e, key) }}></i>
                                            } 
                                        </li>
                                    )
                                }

                                <li className="add-more-vechile" style={(props.loginType==='seller')? {display:'none'}: null}>

                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input type="file" accept="image/*" {...getInputProps()} className="upload-image-input" id="upload-image-input"/>
                                        {/* <h4>{props.t('pages.vehicle.add.drag_drop_here')}</h4>      */}
                                        <i className="ic-add"> </i>
                                        <span>Upload More Image</span>

                                    </div>
                                    

                                </li> 
                                
                            </ul>
                        </div>
                    :
                        <div style={{ textAlign: "center" }}>

                            <div className="image-uploader-wrapper">
                                <div className={'display-box'}>
                                    <div className="icon-text-box">
                                        <div className="upload-icon">
                                            <i className="ic-file-icon" aria-hidden="true" />
                                        </div>

                                        <div className="upload-text">

                                            <div className="upload-txt">
                                
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input type="file" accept="image/*" {...getInputProps()} className="upload-image-input" id="upload-image-input"/>
                                                    <h4>{props.t('pages.vehicle.add.drag_drop_here')}</h4>     
                                                    <span>{props.t('pages.vehicle.add.drop_image_files_title')}</span>   

                                                    <div className="or-txt">
                                                    or
                                                </div>
                                                <button className="btn-line file-btn" > Browse File </button>        
                                                </div>
                                                

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                }


            {isOpen && images?.length > 0 && (
                <Lightbox
                    open={isOpen} 
                   close={() => setImageLightbox({ isOpen: false })} 
                   slides={
                    images.map((val)=>{ return { src: val }} )
                }
                />
            )}
           
            </>
            
        
    );
}

export default withTranslation('common')(Previews);
