import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import InputField from '../../elements/InputField';
import { default as config } from '../../../config/config';
import { withTranslation } from 'react-i18next';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import DateFormat from 'dateformat';

const AUCTION_TL = [
    { value: '1', label: 'Ravinder Singh' },
    { value: '2', label: 'Anil Kumar' },
    { value: '3', label: 'Abhay Jain' },

];


function AuctionBasicDetail(props) {


    const [auction, setAuction] = useState({ ...props.basicDetails });
    let [openSelectBox, setOpenSelectBox] = useState([]);

    function handleChange(key, event) {

        // let { formFields, errors } = auction ;
        let { formFields, errors } = props.basicDetails;

        if (['start_date_time', 'end_date_time'].includes(key)) {
            formFields[key] = event ? new Date(event).toISOString() : '';
        } else {
            formFields[key] = event ? (event.target ? event.target.value : (event.value || '')) : ''
        }

        if ('province_id' === key) {
            formFields['province_name'] = event ? event.label : ''
        }
        if ('city_id' === key) {
            formFields['city_name'] = event ? event.label : ''
        }

        //REMOVE FIELD ERROR
        if (errors[key]) delete errors[key]


        setAuction({ ...auction, formFields, errors });

        //SELECT BOX DESIGN CLASS
        let getSelectRef = key + '_div';
        if (getSelectRef !== 'name_div') {
            if (!document.getElementById(getSelectRef).classList.contains('active-label') || formFields[key]) {
                document.getElementById(getSelectRef).classList.add('active-label')
            } else {
                if(!openSelectBox.includes(key)) document.getElementById(getSelectRef).classList.remove('active-label')
            }
        }


        props.updateBasicDetails(formFields)
    };



    /**
     * 
     * ON SELECT CLICK HANDLER 
     */
    const onSelectOpen  = (el) => {
        // let {openSelectBox} = this.state;
        let selectedDiv = el+'_div';

        // this[selectedDiv].current.classList.add('active-label')
        document.getElementById(selectedDiv).classList.add('active-label')

        //ADD TO OPEN SELECTBOX ARRAY

        openSelectBox= openSelectBox.filter(select=> select!==el)
        openSelectBox.push(el);
        setOpenSelectBox(openSelectBox)

    }

    /**
     * ON SELECT MENU HIDE HANDLER 
     */
    const onSelectClose = (el) => {
        // let {openSelectBox, formFields} = this.state;
        let selectedDiv = el+'_div';

        //REMOVE IF EMPTY
        if(!formFields[el]){
            // this[selectedDiv].current.classList.remove('active-label')
            document.getElementById(selectedDiv).classList.remove('active-label')

            openSelectBox= openSelectBox.filter(select=> select!==el)
            setOpenSelectBox(openSelectBox)


        }
    }

    const { REGION, FORMAT_TYPE, VEHICLE_TYPE } = config.constants;
    const { stateList, cityList, BUSINESS_TYPE } = props.basicDetails;
    const { formFields, errors } = props.basicDetails;
    
    let setFromMin = setHours(setMinutes(new Date(), 0), 0); 
    let startTime =  DateFormat(formFields.start_date_time, config.constants.dateFormatYMD)
    let endTime =  (formFields.end_date_time)? DateFormat(formFields.end_date_time, config.constants.dateFormatYMD) : null;
    if(startTime === endTime || !formFields.end_date_time){ 
        var d = new Date(formFields.start_date_time);
        var start_date_time = new Date(formFields.start_date_time);
        start_date_time.setMinutes(d.getMinutes()+30);
       
        setFromMin = setHours(setMinutes(new Date(start_date_time), new Date(start_date_time).getMinutes()), new Date(start_date_time).getHours())   
    }
    /*****start date min condition*********/
    let setToMin = setHours(setMinutes(new Date(), 0), 0); 
    let currentTime =  DateFormat(new Date(), config.constants.dateFormatYMD)
    if(startTime === currentTime){ 
        setToMin = setHours(setMinutes(new Date(), new Date().getMinutes()), new Date().getHours())   
    }
    /*******************************************/ 

    return (
        <div className="add-auction-form">
            <div className="row">
                <div className="col-md-12">
                    <h3>Basic Details</h3>
                </div>
                <div className="col-md-4">
                    <fieldset className="">
                        <div className={"material animation-effect " + (errors['name'] ? 'error' : '')} id={"name_div"}>
                            <InputField
                                inputProps={{
                                    id: "name",
                                    type: "text",
                                    name: "name",
                                    placeholder: " ",
                                    value: formFields['name'],
                                    className: "form-input"
                                }}
                                onChange={handleChange.bind(this, 'name')}
                            />
                            <label data-label="Auction Name*" className="form-label"></label>
                            {
                                errors['name']
                                    ?
                                    <span className="error-txt">{errors['name']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>

                <div className="col-md-4">
                    <fieldset className="form-select-bx">
                        <div className={"material animation-effect" + (formFields['format'] ? ' active-label' : '') + (errors['format'] ? ' error' : '')} id={"format_div"} onClick={()=>onSelectOpen('format')}>

                            <Select
                                id="format"
                                name="format"
                                onChange={handleChange.bind(this, 'format')}
                                options={FORMAT_TYPE || []}
                                value={formFields['format'] && FORMAT_TYPE && FORMAT_TYPE.filter((type) => Number(type.value) === Number(formFields.format))}
                                // isClearable={true}
                                placeholder=" "
                                classNamePrefix='auction-dropdown'
                                onMenuClose={()=>onSelectClose('format')}

                            />

                            <label data-label="Format*" className="form-label"></label>
                            {
                                errors['format']
                                    ?
                                    <span className="error-txt">{errors['format']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>

                <div className="col-md-4">
                    <fieldset className=" form-select-bx"> 
                        <div className={"material animation-effect" + (formFields['vehicle_type'] ? ' active-label' : '') + (errors['vehicle_type'] ? ' error' : '')} id={"vehicle_type_div"} onClick={()=>onSelectOpen('vehicle_type')}>


                            <Select
                                id="vehicle_type"
                                name="vehicle_type"
                                onChange={handleChange.bind(this, 'vehicle_type')}
                                options={VEHICLE_TYPE || []}
                                value={formFields['vehicle_type'] && VEHICLE_TYPE && VEHICLE_TYPE.filter((type) => Number(type.value) === Number(formFields.vehicle_type))}
                                // isClearable={true}
                                placeholder=" "
                                classNamePrefix='auction-dropdown'
                                onMenuClose={()=>onSelectClose('vehicle_type')}

                            />

                            <label data-label="Vehicle Type*" className="form-label"></label>
                            {
                                errors['vehicle_type']
                                    ?
                                    <span className="error-txt">{errors['vehicle_type']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>

                <div className="col-md-4">
                    <fieldset className=" form-select-bx">
                        <div className={"material animation-effect" + (formFields['business_type'] ? ' active-label' : '') + (errors['business_type'] ? ' error ' : '')} id={"business_type_div"} onClick={()=>onSelectOpen('business_type')}>

                            <Select
                                id="business_type"
                                name="business_type"
                                onChange={handleChange.bind(this, 'business_type')}
                                options={BUSINESS_TYPE || []}
                                value={formFields['business_type'] && BUSINESS_TYPE && BUSINESS_TYPE.filter((type) => Number(type.value) === Number(formFields.business_type))}
                                // isClearable={true}
                                placeholder=" "
                                classNamePrefix='auction-dropdown'
                                onMenuClose={()=>onSelectClose('business_type')}
                                onFocusin={()=>onSelectClose('focus')}

                            />

                            <label data-label="Business Type*" className="form-label"></label>
                            {
                                errors['business_type']
                                    ?
                                    <span className="error-txt">{errors['business_type']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className=" form-select-bx">
                        <div className={"material animation-effect" + (formFields['region'] ? ' active-label' : '') + (errors['region'] ? ' error ' : '')} id={"region_div"} onClick={()=>onSelectOpen('region')}>

                            <Select
                                id="region"
                                name="region"
                                onChange={handleChange.bind(this, 'region')}
                                options={REGION || []}
                                value={formFields['region'] && REGION && Object.keys(REGION).length && REGION.filter((type) => Number(type.value) === Number(formFields.region))}
                                // isClearable={true}
                                placeholder=" "
                                classNamePrefix='auction-dropdown'
                                onMenuClose={()=>onSelectClose('region')}

                            />

                            <label data-label="Region*" className="form-label"></label>
                            {
                                errors['region']
                                    ?
                                    <span className="error-txt">{errors['region']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className=" form-select-bx">
                        <div className={"material animation-effect" + (formFields['province_id'] ? ' active-label' : '') + (errors['province_id'] ? ' error ' : '')} id={"province_id_div"} onClick={()=>onSelectOpen('province_id')}>

                            <Select
                                id="province_id"
                                name="province_id"
                                onChange={handleChange.bind(this, 'province_id')}
                                options={stateList || []}
                                value={formFields && stateList && stateList.filter((type) => Number(type.value) === Number(formFields.province_id))}
                                // isClearable={true}
                                placeholder=" "
                                classNamePrefix='auction-dropdown'
                                onMenuClose={()=>onSelectClose('province_id')}

                            />

                            <label data-label="Province*" className="form-label"></label>
                            {
                                errors['province_id']
                                    ?
                                    <span className="error-txt">{errors['province_id']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className=" form-select-bx">
                        <div className={"material animation-effect" + (formFields['city_id'] ? ' active-label' : '') + (errors['city_id'] ? ' error ' : '')} id={"city_id_div"} onClick={()=>onSelectOpen('city_id')}>
                            <Select
                                id="city_id"
                                name="city_id"
                                onChange={handleChange.bind(this, 'city_id')}
                                options={cityList || []}
                                value={formFields && cityList && cityList.filter((type) => Number(type.value) === Number(formFields.city_id))}
                                // isClearable={true}
                                placeholder=" "
                                classNamePrefix='auction-dropdown'
                                onMenuClose={()=>onSelectClose('city_id')}

                            />

                            <label data-label="City*" className="form-label"></label>
                            {
                                errors['city_id']
                                    ?
                                    <span className="error-txt">{errors['city_id']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className=" form-select-bx">
                        <div className={"material animation-effect" + (formFields['auction_tl'] ? ' active-label' : '') + (errors['auction_tl'] ? ' error ' : '')} id={"auction_tl_div"} onClick={()=>onSelectOpen('auction_tl')}>
                            <Select
                                id="auction_tl"
                                name="auction_tl"
                                onChange={handleChange.bind(this, 'auction_tl')}
                                options={AUCTION_TL || []}
                                value={formFields && AUCTION_TL && AUCTION_TL.filter((type) => Number(type.value) === Number(formFields.auction_tl))}
                                // isClearable={true}
                                placeholder=" "
                                classNamePrefix='auction-dropdown'
                                onMenuClose={()=>onSelectClose('auction_tl')}

                            />

                            <label data-label="Auction TL*" className="form-label"></label>
                            {
                                errors['auction_tl']
                                    ?
                                    <span className="error-txt">{errors['auction_tl']}</span>
                                    :
                                    ''
                            }
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-select-bx ">
                        <div className={"material animation-effect" + (formFields['start_date_time'] ? ' active-label' : '') + (errors['start_date_time'] ? ' error ' : '')} id={"start_date_time_div"}>
                            <div className="datepicker">

                                <DatePicker
                                    className="form-input"
                                    onChange={handleChange.bind(this, 'start_date_time')}
                                    placeholderText={" "}
                                    dateFormat={"dd-MM-yyyy HH:mm"}
                                    selected={formFields.start_date_time ? new Date(formFields.start_date_time) : null}
                                    startDate={formFields.start_date_time || null}
                                    minDate={new Date() || null}
                                    isClearable
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    disabled={props.expiredTime}
                                    minTime={setToMin}
                                    
                                    maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                />
                                <label data-label="Start Date & Time*" className="form-label"></label>
                                {
                                    errors['start_date_time']
                                        ?
                                        <span className="error-txt">{errors['start_date_time']}</span>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-select-bx">
                        <div className={"material animation-effect" + (formFields['end_date_time'] ? ' active-label' : '') + (errors['end_date_time'] ? ' error ' : '')} id={"end_date_time_div"}>
                            <div className="datepicker">

                                <DatePicker
                                    className="form-input"
                                    onChange={handleChange.bind(this, 'end_date_time')}
                                    placeholderText={" "}
                                    dateFormat={"dd-MM-yyyy HH:mm"}
                                    onKeyDown={e => e.preventDefault()}
                                    minDate={new Date(formFields.start_date_time)}
                                    selected={formFields.end_date_time ? new Date(formFields.end_date_time) : null}
                                    isClearable
                                    // maxDate={new Date()}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    disabled={props.expiredTime}
                                    minTime={setFromMin}
                                    maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                />
                                <label data-label="End Date & Time*" className="form-label"></label>
                                {
                                    errors['end_date_time']
                                        ?
                                        <span className="error-txt">{errors['end_date_time']}</span>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>

    );
}


export default withTranslation('common')(AuctionBasicDetail);
