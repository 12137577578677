import React, { Component } from 'react';
import no_vechile_img from "../../../webroot/images/no_vechile_img.svg";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { VehicleActions } from "../../../store/action";
import { withRouter } from "react-router-dom";
import ContentLoader from '../../common/ContentLoader';
import { default as config } from '../../../config/config';
import ConfirmationModal from '../../common/ConfirmationModal';

class AddVechileAuction extends Component {


    _isMount = false
    constructor(props) {
        super(props);


        this.state = {
            inventoryFilterState: { page_number: 1,auction_qc_status:config.constants.vehicleApproved, showCarsNotAddedInAuction: true,page_limit:config.constants.VEHICLE_ADD },
            inventoryList: [],
            inventoryData: {},
            initialInventoryData: null,
            selectInventoryToAdd: [],
            selectedInventory: [],
            checkedAllInventory: false,
            checkedAllSelectedInventory: false,
            totalInventoryCount: 0,
            loading: false,
            messageBox:false,
            isAll:false
        };
    }

    componentDidMount = async () => {
        this._isMount = true;
        //LOAD ALL INVENTORIES
        this.listInventory()
    }

    componentWillUnmount = () => {
        this._isMount = false
    }

    listInventory = async (params = { page_number: 1 }) => {
        let { inventoryData, inventoryList, initialInventoryData, inventoryFilterState } = this.state;

        //FOR FIRST TIME CHECK IF DATA EXISTS IN PROPS ELSE CALL SERVICE
        // if (params.page_number === 1 && !params.keyword && this.props.inventoryData && this.props.inventoryData.allInventoryData && Object.keys(this.props.inventoryData.allInventoryData).length) {
        //     inventoryData = this.props.inventoryData.allInventoryData
        // } else {
            if (this._isMount) this.setState({ loading: true });

            if (typeof params.keyword !== 'undefined' && params.keyword.trim() === '') delete params.keyword; 
            let queryOb = {...inventoryFilterState, ...params};
            if(!params.search_text || params.search_text ===''){ 
                delete queryOb.search_text;
            }   
            inventoryData = await this.props.actions.getVehicleList(queryOb, false);
        // }

        if (inventoryData.status === 200 && inventoryData.data.length) {
            let updatedData = inventoryData.data.map(inventory => { inventory['show'] = true; return inventory; })

            //CREATE DEFAULT LIST
            if (!params.keyword && +params.page_number === 1) {
                initialInventoryData = inventoryData;
            }

            if (this._isMount) {
                this.setState({
                    inventoryList: inventoryData.pagination.cur_page === 1 ? updatedData : [...inventoryList, ...updatedData],
                    initialInventoryData,
                    // defaultData,
                    inventoryData,
                    loading: false
                })
            }

        } else {
            if (this._isMount) {

                this.setState({
                    inventoryList: params.page_number === 1 ? [] : inventoryList,
                    inventoryData,
                    loading: false
                })
            }
        }

    }

    /**
     * INVENTORY SELECTION BOX LEFT PANEL CHECKBOX HANDLER
     * @param {checkbox event} event 
     */
    selectInventoryCheckbox = (event) => {
        let { inventoryList, selectInventoryToAdd, checkedAllInventory,selectedInventory,inventoryData } = this.state;

        let selectedVal = event.target.value;

        //IF SELECT ALL CHECKED
        if (selectedVal === 'all') {
            if(event.target.checked){ 
                let totalVehicle = (inventoryData.pagination && inventoryData.pagination.total) || 0; 
                if(inventoryList.length !== totalVehicle){
                    this.showModalBiddingPopup();
                } 
            }
            //ASSIGN INVENTORY IDs TO DIFF ARRAY & RESET IF DESELECTED ALL
            selectInventoryToAdd = []
            if (event.target.checked){
                let selectedVehicleId = selectedInventory.map(inventory=>inventory.id);
                selectInventoryToAdd = inventoryList.map(inventory => inventory.id).filter((item)=>!selectedVehicleId.includes(item)) 
            }
               
        } else {
            //ELSE ADD SINGLE INVENTORY TO ARRAY & REMOVE FROM ARRAY ON UNCHECKED
            if (event.target.checked)
                selectInventoryToAdd = [...selectInventoryToAdd, +selectedVal]
            else
                selectInventoryToAdd = selectInventoryToAdd.filter(inventory => +inventory !== +selectedVal)

        }
        //DEFAULT FALSE
        checkedAllInventory = false

        //CHECK IF ALL SELECTED INVENTORY THEN ENABLE checkedAllInventory FLAG
        if (inventoryList.length === (selectInventoryToAdd.length+ selectedInventory.length)) {
            checkedAllInventory = true
        }

        if (this._isMount) {
            //SET STATE
            this.setState({ selectInventoryToAdd, checkedAllInventory })
        }

    }

    /**
     * ADD SELECTED INVENTORY TO RIGHT SIDE LIST
     */
    addselectedInventory = () => {
        let { selectInventoryToAdd, selectedInventory, inventoryList, checkedAllInventory,inventoryData,isAll,inventoryFilterState } = this.state;

        //ASSIGN ONLY IF ANY INVENTORY IS SELECTED
        if (selectInventoryToAdd.length) {
            //CREATED NEW ARRAY WITH ORIGINAL inventory DATA & SOME NEW FLAGS
            // selectedInventory = []
            for (let inventory of inventoryList) {
                //ADD SELECTED inventory DATA & NEW FLAGS checked & show
                //{check} FLAG IS FOR CHECK/UNCHECK HANDLING
                //{show} FLAG WILL HELP FOR GLOBAL SERACH FUNCTION 
                if (selectInventoryToAdd.includes(inventory.id))
                    selectedInventory.push({ ...inventory, checked: false, show: true })
            }

            if (selectedInventory.length) {
                this.props.passSelectedInventory(selectedInventory)
            }
            //RESET SELECTED INVENTORY ARRAY OF LEFT BOX
            selectInventoryToAdd = [];
            checkedAllInventory = false;

            if (this._isMount) {
                if (+inventoryList.length !== +inventoryData.pagination.total && isAll) {
                    this.listInventory({ ...inventoryFilterState, page_number: inventoryData.pagination.cur_page + 1 })
                }
                this.setState({ selectedInventory, selectInventoryToAdd, checkedAllInventory,isAll:false });
            }
        }


    }

    /**
     * CHECKBOX HANDLER FOR RIGHT SIDE SELECTED inventory BOX
     * @param {checkbox event} event 
     */
    removeSelectedInventoryCheckbox = (event) => {
        let { selectedInventory, checkedAllSelectedInventory } = this.state;

        let selectedVal = event.target.value;

        //IF ALL SELECTED
        if (selectedVal === 'all') {
            //IF CHECKED THEN ENABLE checked FLAG ELSE DISABLE
            if (event.target.checked)
                selectedInventory = selectedInventory.map(inventory => { inventory['checked'] = true; return inventory });
            else
                selectedInventory = selectedInventory.map(inventory => { inventory['checked'] = false; return inventory });

        } else {
            //SINGLE INVENTORY CHECK/UNCHECK HANDLER

            //ON CHECK/UNCHECK ENABLE/DISABLE INVENTORY 
            if (event.target.checked)
                selectedInventory = selectedInventory.map(inventory => { if (+inventory.id === +selectedVal) inventory['checked'] = true; return inventory });
            else
                selectedInventory = selectedInventory.map(inventory => { if (+inventory.id === +selectedVal) inventory['checked'] = false; return inventory });

        }

        //DISABLE SELECT ALL FOR RIGHT SIDE LIST
        checkedAllSelectedInventory = false

        //GET CHECKED inventory FROM RIGHT SIDE LIST 
        let getCheckedselectedInventoryToRemove = selectedInventory.filter(inventory => inventory.checked);

        //IF RIGHT SIDE LIST ASSIGNED inventory ARE EQUAL TO THE CHECKED ONCE THEN ENABLE SELECT ALL
        if (selectedInventory.length === getCheckedselectedInventoryToRemove.length) {
            checkedAllSelectedInventory = true
        }

        if (this._isMount) {
            this.setState({ selectedInventory, checkedAllSelectedInventory });
        }
        //UPDATE SELECTED INVENTORY TO SAVE 
        this.props.passSelectedInventory(selectedInventory)
    }

    /**
     * REMOVE CHECKED inventory FROM RIGHT SIDE LIST 
     */
    removeSelectedInventory = () => {
        let { selectInventoryToAdd, selectedInventory, checkedAllSelectedInventory } = this.state;

        //RETAIN ONLY UNSELECTED inventory
        selectedInventory = selectedInventory.filter(inventory => !inventory.checked)

        if (!selectedInventory.length) checkedAllSelectedInventory = false;

        if (this._isMount) {
            this.setState({ selectedInventory, selectInventoryToAdd, checkedAllSelectedInventory })
        }
        
        //UPDATE SELECTED INVENTORY TO SAVE 
        this.props.passSelectedInventory(selectedInventory)
    }

    /**
     * SEARCH FILTER FOR LEFT SIDE
     * @param {input event} event 
     */
    searchInventory = (event) => {
        let { inventoryData, inventoryList,  inventoryFilterState } = this.state;

        let searchText = event.target.value.toLowerCase();

        //DEFAULT FILTER DATA
        inventoryFilterState = { page_number: 1, search_text: searchText,showCarsNotAddedInAuction:true,auction_qc_status:'4' }

        if (event.key === 'Enter') {
            if (!searchText) { 
                delete inventoryFilterState.search_text;
            }
            this.listInventory(inventoryFilterState)
            inventoryList = [] //RESET LIST
           
            
        }

        if (this._isMount) {
            this.setState({ inventoryData, inventoryList, inventoryFilterState });
        }

    }
    /**
     * SEARCH FILTER FOR RIGHT SIDE
     * @param {input event} event 
     */
    searchSelectedInventory = (event) => {
        let { selectedInventory } = this.state;

        let searchText = event.target.value.toLowerCase();
        //MANAGE {show} FLAG FOR inventory ACC. TO SEARCH FILTER
        selectedInventory = selectedInventory.map(inventory => { inventory['show'] = true; return inventory; })

        if (searchText) {
            selectedInventory = selectedInventory.map(inventory => {

                let carName = inventory.make + ' ' + inventory['modelVersion']

                if (carName.toLowerCase().indexOf(searchText) === -1) {
                    inventory['show'] = false;
                }
                return inventory;
            })
        }
        if (this._isMount) {
            this.setState({ selectedInventory })
        }
    }

    /**
     * SCROLL EVENT ON LEFT SIDE INVENTORY LIST
     * @param {scroll event} event 
     */
    inventoryScroll = (event) => {
        let { loading, inventoryData, inventoryList, inventoryFilterState } = this.state;

        var element = document.getElementById('actualInventoryList');
        //IF SOME DATA EXISTS AND SCROLLER IS AT END OF THE LIST THEN IT IS TRUE
        if (!loading && element.scrollTop >= 1 && (element.scrollHeight - element.scrollTop === element.clientHeight)) {
            // element is at the end of its scroll, load more content if total count not reached
            if (+inventoryList.length !== +inventoryData.pagination.total) {
                this.listInventory({ ...inventoryFilterState, page_number: inventoryData.pagination.cur_page + 1 })
            }
        }
    }
    showModalBiddingPopup = () => { 
        this.setState({messageBox:true,isAll:true})        
    } 
    confirmationModalResponse = async (response)=>{ 
        let ob = {messageBox:false}; 
        if(response && response.response){  
            ob.checkedAllInventory = false;
            ob.selectInventoryToAdd = []; 
        }  
        this.setState(ob)
    }
    render() {
        
        const { loading, inventoryList, checkedAllInventory, selectInventoryToAdd, selectedInventory, checkedAllSelectedInventory, inventoryData, messageBox } = this.state; 
        let inventoryListCount = ((inventoryData.pagination && inventoryData.pagination.total) || 0) - (selectedInventory.length || 0);
        let selctedinventoryListCount = selectedInventory.length; 
        return (
            <div className="auction-buyer-sec">
                <div className="buyer-heading col-md-12">
                    <h3>Add Vehicle</h3> 
                </div>
                <div className="col-md-6">
                    <span className="add-buyers-txt">Add from Approved List ({inventoryListCount})</span>
                    <div className="buyer-list-bx">
                        <div className="select-all-search">
                            <li className="custom-control custom-checkbox">
                                <input id="all_vehicle" type="checkbox" value='all' onChange={this.selectInventoryCheckbox} checked={checkedAllInventory} className="custom-control-input" />
                                <label htmlFor="all_vehicle" className="custom-control-label">Select All</label>
                            </li>
                            <fieldset className="search-bx">
                                <input id="" type="text" placeholder="Search " onKeyDown={this.searchInventory} disabled={loading} name="" className="form-input" />
                                <i className="ic-search"></i>
                            </fieldset>
                        </div>
                        <div className="buyers-added-list" >
                            <ul id="actualInventoryList" onScroll={this.inventoryScroll}>
                                {
                                    inventoryList && inventoryList.length
                                        ?
                                        inventoryList.map((el, key) => {
                                            if (el && !selectedInventory.find(inventory => +inventory.id === +el.id) && el.show) {

                                                return <li key={key} className="custom-control custom-checkbox">
                                                    <input id={'inventory' + (key + 1)} type="checkbox" onChange={this.selectInventoryCheckbox} value={el.id} checked={checkedAllInventory || (selectInventoryToAdd.includes(+el.id)) || ''} className="custom-control-input" />
                                                    <label htmlFor={'inventory' + (key + 1)} className="custom-control-label">{el.make + ' ' + el.modelVersion+' ('+el.reg_no+')'}</label>
                                                </li>
                                            } else {
                                                return ''
                                            }
                                        })
                                        :
                                        (!loading) ? this.props.t('common.no_records_found') : ''
                                }
                                <ContentLoader show={loading} />

                            </ul>
                            <button className="btn-primary" onClick={this.addselectedInventory} >Add</button>
                        </div>

                    </div>
                </div>

                <div className="col-md-6">
                    <span className="add-buyers-txt">Added Vehicle ({selctedinventoryListCount})</span>
                    <div className="buyer-list-bx">
                        <div className="select-all-search">
                            <li className="custom-control custom-checkbox">
                                <input id="all_selected" type="checkbox" onChange={this.removeSelectedInventoryCheckbox} checked={checkedAllSelectedInventory} value='all' className="custom-control-input" />
                                <label htmlFor="all_selected" className="custom-control-label">Select All</label>
                            </li>
                            <fieldset className="search-bx">
                                <input id="" type="text" placeholder="Search " onChange={this.searchSelectedInventory} name="" className="form-input" />
                                <i className="ic-search"></i>
                            </fieldset>
                        </div>
                        <div className="buyer-no-data-bx no-vechile-img" style={(selectedInventory && selectedInventory.length) ? { display: "none" } : { display: "flex" }}>
                            {<img src={no_vechile_img} className="" alt="" />}
                            <span className="no-img-txt">No inventory are added</span>
                        </div>
                        <div className="buyers-added-list" style={(selectedInventory && selectedInventory.length) ? { display: "" } : { display: "none" }}>
                            <ul>

                                {
                                    selectedInventory && selectedInventory.map((el, key) => {
                                        if (el.show) {
                                            return <li key={key} className="custom-control custom-checkbox">
                                                <input id={'selectedInventory' + (key + 1)} type="checkbox" onChange={this.removeSelectedInventoryCheckbox} value={el.id} checked={(checkedAllSelectedInventory || el.checked) || ''} className="custom-control-input" />
                                                <label htmlFor={'selectedInventory' + (key + 1)} className="custom-control-label">{el.make + ' ' + el.modelVersion}</label>
                                            </li>
                                        } else {
                                            return ''
                                        }

                                    })
                                }

                            </ul>
                            <button className="btn-primary" onClick={this.removeSelectedInventory}>Remove</button>
                        </div>
                    </div>
                </div> 
                
                <ConfirmationModal confirmationData={{ show: messageBox, confirmationText: `Out of ${inventoryListCount} you can only ${inventoryList.length-selctedinventoryListCount} data selected for get more data please scroll vehicle list.` }} confirmationModalResponse={this.confirmationModalResponse} />
            </div>




        );
    }

} 

const mapStateToProps = (state, ownProps) => ({
    allVehicleList: state.vehicle.vehicleList || {},

});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getVehicleList: bindActionCreators(
                VehicleActions.getVehicleList,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddVechileAuction)));

