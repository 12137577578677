import MasterService from './MasterService';

export const CommonMethodService = {
  ...MasterService,

  getInventoryFilters() {
    return this.get('/common/getInventoryFilters');
  },
  getMMVList() {
    return this.get('/common/getMMVList');
  },
  getSellersList(postData) {
    return this.post('common/getSellersList', postData)
  },
  getCityStateList(postData) {
    return this.post('common/getCityStateList', postData)
  },
  getBuyersList(postData) {
    return this.post('common/getBuyersList', postData)
  },
  getAuctionVehicleFilters() {
    return this.get('common/getAuctionVehicleFilters')
  },
  getAuctionFilters(){
    return this.get('/common/getAuctionFilters');
  }


}
export default CommonMethodService;