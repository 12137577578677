import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class Messages extends Component {
    render() {
        const { authUserInfo } = this.props;
        
        return (
            <div className="messages">
                <div id="list">
                            
                        <div className="msg_history"  id="msgsList">
                            {this.props.messages.filter(message => message.room === this.props.room).map((message, index) => {
                                
                                return(
                                
                                    (authUserInfo && authUserInfo.authUser.name !== message.from)
                                    ?
                                        <div className="incoming_msg" key={index}>
                                            <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="" /> </div>
                                            <div className="received_msg">
                                                <div className="received_withd_msg">
                                                <h4>{message.from}</h4>
                                                <p>{message.text}</p>
                                                {/* <span className="time_date"> 11:01 AM    |    June 9</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div className="outgoing_msg" key={index}>
                                            <div className="sent_msg">
                                            <p>{message.text}</p>
                                            <span className="time_date"> {message.createdDate}</span> </div>
                                        </div>
                                )
                            })
                        }

                        </div>
                        
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authUserInfo: state.auth.authUser
    }
};


export default  withTranslation('common') (connect(mapStateToProps)(Messages));