import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { AuctionActions } from '../../../store/action';
import AddAuctionCar from './AddAuctionCar';
import Pagination from '../../elements/Pagination';
import InputField from '../../elements/InputField';
import Modal from '../../elements/PopupModal';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import * as HELPER from './../../../config/helper';
import CurrencyInputField from '../../elements/CurrencyInputField';
import FullPageLoader from '../../common/FullPageLoader';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import ConfirmationModal from '../../common/ConfirmationModal';
import { AuctionService } from '../../../service';

class InventoriesTable extends Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }
  initialState = () => {
    return {
      loading: false,
      resultList: [],
      pagination: {},
      totalLengthWithoutPagination: 0,
      runPaginationFunc: false,
      formAction: 'auctionInventoryList',
      filterData: { 'auction_id': this.props.match.params.id, page_number: 1 },
      tableFormData: [],
      showAddVehicleModal: false,
      confirmationReplicateModel: false,
      isReplicate: false,
      saveInventoryLoader: false,
      checkedAll: false,
      multiSelectedVehicles: [],
      isDelete:false
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    //CALL AUCTION CAR SERVICE
    this.getAuctionCarsList({ page_number: 1 }) 
    this.props.actions.unSetAuctionFlag(false);  
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  /**
   * GET SAVE INVENTORY
   */
  getAuctionCarsList = async (data = {}) => {
    if (this._isMounted) await this.setState({ loading: true });

    data = { ...this.state.filterData, ...data }
    let searchResult = await this.props.actions.getAuctionCarsList(data)

    if (this._isMounted) {

      let auctionCarsData = (searchResult && searchResult.data ? searchResult.data : []);

      //UPDATE FORM FIELDS
      this.updateInventoryFormFields(auctionCarsData);

      //SEND COUNT TO PARENT
      this.props.getTotalInventory((searchResult && searchResult.pagination && searchResult.pagination.total) || 0);

      await this.setState({
        loading: false,
        resultList: auctionCarsData || [],
        pagination: (searchResult && searchResult.pagination) ? searchResult.pagination : {},
        totalLengthWithoutPagination: ((searchResult && searchResult['pagination'] && searchResult['pagination']['total']) || 0),
        runPaginationFunc: true
      });
    }

  }

  /**
   * UPDATE INVENTORY FORM FIELDS
   */
  updateInventoryFormFields = (finalResult) => {
    let tableFormData = [];

    for (let inventory of finalResult) {


      let individualCarData = {
        ...inventory,
        // "auction_id": this.state.auctionId,
        "auction_inventory_id": inventory.id,
        "reg_car_city_name": inventory.car_details && inventory.car_details.reg_car_city_name,
        "make_year": inventory.car_details && inventory.car_details.make_year,
        "fuel_type": inventory.car_details && inventory.car_details.fuel_type,
        "display_owner_type": inventory.car_details && inventory.car_details.display_owner_type,
      }

      delete individualCarData['car_details'];
      delete individualCarData['created_by'];
      delete individualCarData['created_at'];
      delete individualCarData['updated_at'];
      delete individualCarData['updated_by'];
      delete individualCarData['status'];

      tableFormData.push(individualCarData)
    }

    this.setState({ tableFormData })
  }

  /**
   * FORM FIELDS CHANGE HANDLER
   * @param {click event} event 
   * @param {selected field} key 
   * @param {auctionInventoryId} auctionInventoryId 
   */
  handleChange = async (event, key, auctionInventoryId) => {
    let { tableFormData } = this.state;

    //UPDATE PARTICULAR INVENTORY DATA
    for (let inventory of tableFormData) {
      if (inventory.auction_inventory_id === auctionInventoryId) {

        if (['start_date_time', 'end_date_time'].includes(key)) {
          inventory[key] = event ? new Date(event).toISOString() : '';
        } else {
          inventory[key] = event ? (event.target ? event.target.value : (event.value || '')) : ''
        }

        inventory['is_changed'] = true; //TO UPDATE ONLY THIS INVENTORY IN DATABASE
      }
    }

    this.setState({ tableFormData });
  }

  /**
   * SAVE INVENTORY
   */

  updateAuctionInventory = async () => {
    let { tableFormData } = this.state;

    this.setState({ saveInventoryLoader: true })
    for (let inventory of tableFormData) {
      inventory['expected_price'] = HELPER.removeCurrencyFormatting(inventory['expected_price']);
      inventory['base_price'] = HELPER.removeCurrencyFormatting(inventory['base_price']);
      inventory['reserved_price'] = HELPER.removeCurrencyFormatting(inventory['reserved_price']);
      inventory['increment_amount'] = HELPER.removeCurrencyFormatting(inventory['increment_amount']);

    }

    this.props.actions.updateAuctionInventory({ auction_id: this.state.auctionCarId, auctionInventory: tableFormData }).then(response => {
      if(response.status===200) toast.success(response.message);
      this.setState({ saveInventoryLoader: false })

    })
  }

  /**
   * REPLICATE INVENTORY
   */
  replicateInventory = (isReplicate) => {
    this.hideModalReplicate();

    let { tableFormData, resultList } = this.state;
    //get first record to replicate
    let replicateRecord = tableFormData[0];

    for (let [index, inventory] of tableFormData.entries()) {
      if (index === 0) {
        inventory['replicate'] = '1'

      } else {
        //IF NO REPLICATE THEN REVERT TO ORIGINAL VALUES
        if (!isReplicate) {
          replicateRecord = resultList.filter(car => +car.id === +inventory.auction_inventory_id)
          replicateRecord = replicateRecord[0]
        }

        inventory['increment_amount'] = replicateRecord['increment_amount']
        inventory['increments'] = replicateRecord['increments']
        inventory['bid_count'] = replicateRecord['bid_count']
        inventory['bid_increment'] = replicateRecord['bid_increment']
        inventory['cycles'] = replicateRecord['cycles']
        inventory['trigger'] = replicateRecord['trigger']
        inventory['is_changed'] = true; //TO UPDATE ONLY THIS INVENTORY IN DATABASE

      }
    }

    this.setState({ tableFormData, isReplicate })
  }

  /**
   * SHOW REPLICATE INVENTORY CONFIRMATION MODAL
   */
  showModalReplicate = () => {
    this.setState({ confirmationReplicateModel: true, isReplicate: true });
  }

  /**
   * HIDE REPLICATE INVENTORY CONFIRMATION MODAL
   */
  hideModalReplicate = () => {
    this.setState({ confirmationReplicateModel: false });
    document.body.classList.remove("overflow-hidden");
  }

  /**
   * SHOW ADD NEW INVENTORY MODAL
   */
  showAddVehicleModal = () => {
    this.setState({ showAddVehicleModal: true });
  }

  /**
   * HIDE ADD NEW INVENTORY MODAL
   */
  hideModalAddVehile = () => {
    this.setState({ showAddVehicleModal: false });
    document.body.classList.remove("overflow-hidden");
  }

  /**
   * PAGINATION HANDLER
   */
  listInventory = (params, pagination) => {
    this.props.listInventory(params, pagination)
  }

  /**
   * SEARCH HANDLER ON SAVED INVENTORY
   */
  searchSavedInventory = (e) => {

    if (e.key === 'Enter') {
      let searchKeyword = e.target.value || undefined;

      this.setState({ filterData: { ...this.state.filterData, searchKeyword } })

      this.getAuctionCarsList({ page_number: 1, searchKeyword })
    }
  }

  handleRequestData = async (data) => {

    if (this._isMounted) {

      this.getAuctionCarsList(data);

    }
  }
  handleInputChange = type => async (e) => { 
    let checkedAllStatus = !this.state.checkedAll; 
    let selectedIds = [];
    if(e.target.checked){
      this.state.resultList.forEach((item)=>{
        if(!item.bidId){
          selectedIds.push(item.id)
        } 
      }) 
    } 
    this.setState({checkedAll: checkedAllStatus ,multiSelectedVehicles:selectedIds}) 
  } 

  handleSingleCheckboxChange = (event,vehicleData) => {
    let { checkedAll, resultList, multiSelectedVehicles } = this.state;
    //ADD REMOVE INVENTORY ID FROM ARRAY ON CHECK/UNCHECK 
    if(event.target.checked){
      multiSelectedVehicles.push(vehicleData.id)
    }else{
      multiSelectedVehicles = multiSelectedVehicles.filter(ids => +ids !== +vehicleData.id)
    }  

    if (checkedAll && resultList.length !== multiSelectedVehicles.length) {
      checkedAll = false
    } else if (!checkedAll && resultList.length === multiSelectedVehicles.length) {
      checkedAll = true
    } 
    this.setState({ multiSelectedVehicles, checkedAll }); 
  }
  confirmationModalResponse = async (response)=>{  
    this.setState({isDelete:false}); 
    if(response && response.response){ 
      let result = await AuctionService.removeAuctionCarIds({
        auction_id:this.props.match.params.id,
        car_ids:this.state.multiSelectedVehicles
      }); 
      if(result.data.status === 200) { 
        toast.success(result.data.message);
        this.getAuctionCarsList(); 
      } 
    }
     
  }
 
  render() {  
    const { saveInventoryLoader, filterData, pagination, showAddVehicleModal, confirmationReplicateModel, loading, totalLengthWithoutPagination, resultList, page, runPaginationFunc, tableFormData, isReplicate,checkedAll,multiSelectedVehicles,isDelete } = this.state; 
    let { auctionData,expiredTime } = this.props; 
    return (
      <div>
        <FullPageLoader show={loading} />
        <div className="top-right-btn"> 
          <fieldset className="search-bx search-top-bar">
            <input id="" type="text" placeholder="Search " disabled={loading} name="" className="form-input" onKeyDown={this.searchSavedInventory} />
            <i className="ic-search"></i>
          </fieldset>
          {multiSelectedVehicles.length && !expiredTime && tableFormData.length ? 
            <div className="action-btn">
              <button className="btn-line" onClick={()=>this.setState({isDelete:true})} disabled={expiredTime}>
                <i className="ic-delete-icn"></i><span>Delete</span>
              </button> 
            </div>:
            null
          } 
          <div className="action-btn">
            <button className="btn-line" onClick={this.showAddVehicleModal} disabled={expiredTime}>
              <i className="ic-add"></i>
              <span>Add Vehicle</span>
            </button>

          </div>

        </div>
        <div className="inventrios-data-table auction-inventory-cls">
          <table>
            <thead>
              <tr>
                <th>
                  <li className="custom-control custom-checkbox">
                      <input id="all" type="checkbox" onChange={this.handleInputChange('selectAll')} checked={checkedAll && tableFormData.length} name="selectAll" className="custom-control-input" />
                      <label htmlFor="all" className="custom-control-label"></label>
                  </li>
                </th>
                <th>Vehicle Name  & Information</th>
                <th>Start/End Date & TIme</th>
                <th>BID</th>
                <th>Price</th>
                <th>Auto Time Increment</th>
              </tr>
            </thead>
            <tbody>
              {
                tableFormData && tableFormData.map((inventory, key) => {
                  let saveStartDate = new Date(auctionData.saved_auction_data.start_date_time);
                  let saveEndDate = new Date(auctionData.saved_auction_data.end_date_time);  
                  let startTime = new Date(inventory.start_date_time);
                  let endTime = new Date(inventory.end_date_time);
                  let setMinTime = '';
                  let setMaxTime = setHours(setMinutes(new Date(), 30), 23); 

                  let setMinTime2 = setHours(setMinutes(new Date(), '00'), '00'); 
                  //------- Start Time -----//

                  if(startTime.getUTCFullYear() !== saveStartDate.getUTCFullYear() || startTime.getUTCMonth() !== saveStartDate.getUTCMonth() || startTime.getUTCDate() !== saveStartDate.getUTCDate()){  
                    setMinTime = setHours(setMinutes(new Date(), 0), 0)
                  }else{
                    setMinTime = setHours(setMinutes(new Date(auctionData.saved_auction_data.start_date_time), new Date(auctionData.saved_auction_data.start_date_time).getMinutes()), new Date(auctionData.saved_auction_data.start_date_time).getHours()) 
                  }   

                  if(startTime.getUTCFullYear() === endTime.getUTCFullYear() && startTime.getUTCMonth() === endTime.getUTCMonth() && startTime.getUTCDate() === endTime.getUTCDate()){
                    // setMinTime = setHours(setMinutes(new Date(), 0), 0) 
                    setMaxTime = setHours(setMinutes(new Date(endTime), new Date(endTime).getMinutes()), new Date(endTime).getHours())  

                    setMinTime2 = setHours(setMinutes(new Date(startTime), new Date(startTime).getMinutes()), new Date(startTime).getHours())  
                  } 
                  //----- END TIME -------//
                  let setMaxTime2 = setHours(setMinutes(new Date(auctionData.saved_auction_data.end_date_time), new Date(auctionData.saved_auction_data.end_date_time).getMinutes()), new Date(auctionData.saved_auction_data.end_date_time).getHours())
          
                  if(endTime.getUTCFullYear() !== saveEndDate.getUTCFullYear() || endTime.getUTCMonth() !== saveEndDate.getUTCMonth() || endTime.getUTCDate() !== saveEndDate.getUTCDate()) {
                    setMaxTime2 = setHours(setMinutes(new Date(), 30), 23); 
                  }
                  //------- END  ------/

                  return <tr key={key}>
                  <td>
                  {
                    (!inventory.bidId) ? 
                    <li className="custom-control custom-checkbox">
                      <input id="all" type="checkbox" className="custom-control-input" onChange={(event) => this.handleSingleCheckboxChange(event,inventory)} checked={multiSelectedVehicles.includes(inventory.id) || checkedAll} />
                      <label htmlFor="all" className="custom-control-label"></label>
                    </li>:
                    null
                  }
                    
                  </td>
                    <td>
                      <div className="cars-detail">
                        <span>{inventory.make_name + ' ' + inventory.model_name} </span>
                        <ul>
                          <li>Vid: {inventory.vid}</li>
                          {
                            inventory.reg_car_city_name
                              ?
                              <li>{inventory.reg_car_city_name}</li>
                              :
                              ''
                          }
                          {
                            inventory.reg_no
                              ?
                              <li>{inventory.reg_no}</li>
                              :
                              ''
                          }
                          {
                            inventory.fuel_type
                              ?
                              <li>{inventory.fuel_type}</li>
                              :
                              ''
                          }
                          {
                            inventory.make_year
                              ?
                              <li>{inventory.make_year + ' Model'}</li>
                              :
                              ''
                          }
                          {
                            inventory.km_driven
                              ?
                              <li>{inventory.km_driven}</li>
                              :
                              ''
                          }
                          {
                            inventory.display_owner_type
                              ?
                              <li>{inventory.display_owner_type}</li>
                              :
                              ''
                          }
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div className="date-time-sec">
                        <fieldset className="create-date m-lg-b">
                          <div className="material">
                            <div className="datepicker">

                              <DatePicker
                                // className="form-input"
                                onChange={(e) => this.handleChange(e, 'start_date_time', inventory.auction_inventory_id)}
                                placeholderText=" "
                                dateFormat={"dd-MM-yyyy HH:mm"}
                                selected={inventory.start_date_time ? new Date(inventory.start_date_time) : null}
                                startDate={inventory.start_date_time || null}
                                minDate={auctionData.saved_auction_data ? new Date(auctionData.saved_auction_data.start_date_time) : null}
                                maxDate={auctionData.saved_auction_data ? new Date(inventory.end_date_time) :new Date(auctionData.saved_auction_data.end_date_time)}
                                minTime={setMinTime}
                                maxTime={setMaxTime}
                                // isClearable
                                showTimeSelect
                                timeFormat="HH:mm"
                              />
                              <label data-label="Start Date & Time" className="form-label"></label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="create-date">
                          <div className="material">
                            <div className="datepicker">

                              <DatePicker
                                // className="form-input"
                                onChange={(e) => this.handleChange(e, 'end_date_time', inventory.auction_inventory_id)}
                                placeholderText=" "
                                dateFormat={"dd-MM-yyyy HH:mm"}
                                onKeyDown={e => e.preventDefault()}
                                selected={inventory.end_date_time ? new Date(inventory.end_date_time) : null}
                                minDate={new Date(inventory.start_date_time) || null}
                                maxDate={auctionData.saved_auction_data ? new Date(auctionData.saved_auction_data.end_date_time) : null}
                                minTime={setMinTime2}
                                maxTime={setMaxTime2}
                                // isClearable
                                showTimeSelect
                                timeFormat="HH:mm"
                              />
                            </div>
                            <label data-label="End Date & Time" className="form-label"></label>

                          </div>
                        </fieldset>
                      </div>
                    </td>
                    <td>
                      <div className="input-sec">
                        <fieldset className="m-lg-b">
                          <div className="material">
                            {/* <input type="text" className="form-input" placeholder=" " /> */}

                            <CurrencyInputField
                              inputProps={{
                                id: "increment_amount",
                                type: "text",
                                name: "increment_amount",
                                placeholder: " ",
                                value: inventory['increment_amount'],
                                className: "form-input"
                              }}
                              onChange={(e) => this.handleChange(e, 'increment_amount', inventory.auction_inventory_id)}
                            />

                            <label data-label="Increment Amount" className="form-label"></label>
                          </div>
                        </fieldset>
                        <fieldset className="">
                          <div className="material">
                            {/* <input type="text" className="form-input" placeholder=" " /> */}

                            <InputField
                              inputProps={{
                                id: "bid_count",
                                type: "text",
                                name: "bid_count",
                                placeholder: " ",
                                value: inventory['bid_count'],
                                className: "form-input"
                              }}
                              onChange={(e) => this.handleChange(e, 'bid_count', inventory.auction_inventory_id)}
                            />
                            <label data-label="Bid Count" className="form-label"></label>
                          </div>
                        </fieldset>
                      </div>
                    </td>
                    <td>
                      <div className="input-sec data-filed">
                        <fieldset className="m-lg-b">
                          <div className="material">

                            <CurrencyInputField
                              inputProps={{
                                id: "reserved_price",
                                type: "text",
                                name: "reserved_price",
                                placeholder: " ",
                                value: inventory['reserved_price'],
                                className: "form-input"
                              }}
                              onFocus={() => { }}
                              onBlur={() => { }}
                              onChange={(e) => this.handleChange(e, 'reserved_price', inventory.auction_inventory_id)}
                            />

                            <label data-label="Reserved Price" className="form-label"></label>
                          </div>
                        </fieldset>
                        <fieldset className="m-lg-b">
                          <div className="material">

                            <CurrencyInputField
                              inputProps={{
                                id: "base_price",
                                type: "text",
                                name: "base_price",
                                placeholder: " ",
                                value: inventory['base_price'],
                                className: "form-input"
                              }}
                              onFocus={() => { }}
                              onBlur={() => { }}
                              onChange={(e) => this.handleChange(e, 'base_price', inventory.auction_inventory_id)}
                            />

                            <label data-label="Base Price" className="form-label"></label>
                          </div>
                        </fieldset>
                        <fieldset className="">
                          <div className="material">

                            <CurrencyInputField
                              inputProps={{
                                id: "expected_price",
                                type: "text",
                                name: "expected_price",
                                placeholder: " ",
                                value: inventory['expected_price'],
                                className: "form-input",
                              }}
                              onFocus={() => { }}
                              onBlur={() => { }}
                              onChange={(e) => this.handleChange(e, 'expected_price', inventory.auction_inventory_id)}
                            />

                            <label data-label="Expected Price" className="form-label"></label>
                          </div>
                        </fieldset>
                      </div>
                    </td>
                    <td>
                      <div className="input-sec data-filed auto-time-sec">
                        <fieldset className="m-lg-b">
                          <div className="material">

                            <InputField
                              inputProps={{
                                id: "increments",
                                type: "text",
                                name: "increments",
                                placeholder: " ",
                                value: inventory['increments'],
                                className: "form-input",
                                readOnly:true
                              }}
                              onChange={(e) => this.handleChange(e, 'increments', inventory.auction_inventory_id)}
                            />
                            <label data-label="Increment (s)" className="form-label"></label>
                          </div>
                        </fieldset>
                        <fieldset className="m-lg-b">
                          <div className="material">

                            <InputField
                              inputProps={{
                                id: "cycles",
                                type: "text",
                                name: "cycles",
                                placeholder: " ",
                                value: inventory['cycles'],
                                className: "form-input",
                                readOnly:true
                              }}
                              onChange={(e) => this.handleChange(e, 'cycles', inventory.auction_inventory_id)}
                            />
                            <label data-label="Cycles" className="form-label"></label>
                          </div>
                        </fieldset>
                        <fieldset className="">
                          <div className="material">
                            <InputField
                              inputProps={{
                                id: "trigger",
                                type: "text",
                                name: "trigger",
                                placeholder: " ",
                                value: inventory['trigger'],
                                className: "form-input",
                                readOnly:true
                              }}
                              onChange={(e) => this.handleChange(e, 'trigger', inventory.auction_inventory_id)}
                            />
                            <label data-label="Trigger (s)" className="form-label"></label>
                          </div>
                        </fieldset>
                        <div>
                          {
                            key === 0
                              ?
                              <li className="custom-control custom-checkbox"  >
                                <input id="all" type="checkbox" className="custom-control-input" checked={isReplicate} onChange={this.showModalReplicate}/>
                                <label htmlFor="all" className="custom-control-label">Replicate</label>
                              </li>

                              :
                              ''
                          }
                          <li className="custom-control custom-checkbox"  >
                            <input id="all" type="checkbox" className="custom-control-input" disabled={true}/>
                            <label htmlFor="all" className="custom-control-label">Auto Bid</label>
                          </li>
                        </div>

                      </div>
                    </td>
                  </tr>
                })


              }


            </tbody>

          </table>


          {
            !loading && !tableFormData.length
              ?
              <p className="t-center">{this.props.t('common.no_records_found')}</p>
              :
              ''
          }

          <div className="pagination-outer">

            <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': resultList, totalLengthWithoutPagination, vehicleListPagination: pagination }} sendRequestDataToParent={this.handleRequestData} />


          </div>
          {
            tableFormData && tableFormData.length
              ?
              saveInventoryLoader
                ?
                <div className="btn-add-auction update-btn"><button className="btn-primary">{this.props.t('common.please_wait')}</button></div>
                :
                <div className="btn-add-auction update-btn"><button className="btn-primary" onClick={this.updateAuctionInventory} disabled={expiredTime}>Update & Save</button></div>

              :
              ''
          }
        </div>
        <div className="view-timeline-popup replicate-popup">
          <Modal show={confirmationReplicateModel} handleClose={this.hideModalReplicate} >
            <div className="modal-header">
            </div>
            <div className="modal-body" >
              <span className="replace-txt">Do you want to replicate all the auto increment details?</span>

            </div>
            <div className="yes-no-btn">
              <button className="btn-line" onClick={() => this.replicateInventory(false)}>
                No
              </button>
              <button className="btn-primary" onClick={() => this.replicateInventory(true)}>Yes</button>
            </div>
          </Modal>
        </div>

        <div className="view-timeline-popup add-vechile-popup">
          {
            showAddVehicleModal
              ?
              <Modal show={showAddVehicleModal} handleClose={this.hideModalAddVehile} >

                <AddAuctionCar inventoryData={{ auctionId: filterData.auction_id, allInventoryData: this.props.auctionData.inventoryList, saved_auction_data: this.props.auctionData.saved_auction_data }} listInventory={this.listInventory} handleClose={this.hideModalAddVehile} getAuctionCarsList={this.getAuctionCarsList} />
              </Modal>
              :
              ''

          }

        </div>

        {
          isDelete
              ?
              <ConfirmationModal confirmationData={{ show: isDelete, confirmationText: "Are you sure you want to delete selected inventory?" }} confirmationModalResponse={this.confirmationModalResponse} />
              :
              null
        }
      </div>




    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuctionAdd: state.auction.isAuctionAdd || false
  }
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      getAuctionCarsList: bindActionCreators(
        AuctionActions.getAuctionCarsList,
        dispatch
      ),
      updateAuctionInventory: bindActionCreators(
        AuctionActions.updateAuctionInventory,
        dispatch
      ),
      unSetAuctionFlag: bindActionCreators(
        AuctionActions.unSetAuctionFlag,
        dispatch
      ),
    }
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoriesTable)));