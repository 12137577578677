
import React, { Component } from 'react';
import { default as config } from '../../../../config/config';
import { AuctionActions } from "../../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom"; 
import secureStorage from '../../../../config/encrypt';
import FilterBox from './filter-box';
import FullPageLoader from '../../../common/FullPageLoader';
import DateFormat from 'dateformat';


class PostAuction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postList: {
        all_vehicle: 0,
        no_bid: 0,
        approval_pending: 0,
        bid_approved: 0,
        payment_detail_shared: 0,
        vehicle_delivered: 0,
        rejected: 0,
        reauctioned: 0
      },
      filterField: {}
    };
  }
  componentDidMount = () => {
    // this.submitFilter({}) 
    
  }


  submitFilter = (params) => {
    this.setState({ filterField: params, loading: true })
    this.props.actions.getPostAuctionDetail(params).then(postAuc => {
      if (postAuc.data) {
        this.setState({ postList: postAuc.data.items });
      }
      this.setState({ loading: false })
    }).catch(err => this.setState({ loading: false }))
  }

  redirectToVehicleList = (auctionStatus = null) => {
    let { filterField } = this.state; 
    let queryParams = '';
    for (let field of Object.keys(filterField)) {
      if (field === 'auction_id_hash_in' && filterField[field].length) {
        queryParams += field + '=' + filterField[field] + '&'

      }else if((field === 'from_date' || field === 'to_date') && filterField[field]){   
        let v1 = new Date(filterField[field]).toISOString();  
        queryParams += field + '='+DateFormat(v1, config.constants.dateFormatYMD)+'&' 
      } else if (field !== 'auction_id_hash_in' && filterField[field]) {
        queryParams += field + '=' + filterField[field] + '&'
      } 
    }
    queryParams += 'auction_status=expired&type=post_auction';
    if (auctionStatus) {
      queryParams += '&post_auction_status=' + auctionStatus;
    }
    return '/vehicle-list?' + queryParams

  } 
  render() {
    const { postList, loading } = this.state;
    const authUserInfo = secureStorage.getItem('authUserInfo') || {};

    return (
      <div className={"dashboard-bg " + (loading ? 'loading' : '')}>

        <FullPageLoader show={loading} />  
        <div className="dashboard-outer">
          <div className="heading">
            <span className="subheading">Hello {(authUserInfo && authUserInfo.authUser && authUserInfo.authUser.name ? authUserInfo.authUser.name : '')}</span>
            <h1>{this.props.t('post_auction.welcome_post_auction')}</h1>
          </div>

          <FilterBox submitFilter={this.submitFilter} componentData={{ loading }} />

          <div className="dashboard-card">
            <div className="card color-one">
              <div className="roung-bg">
                <i className="ic-vehicle">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                  <i className='path4'></i>
                </i>
              </div>
              <span className="count-txt">{
                postList.all_vehicle
                  ?
                  <Link to={() => this.redirectToVehicleList()} > {postList.all_vehicle}</Link>
                  :
                  "0"
              }</span>
              <span className="card-txt">{this.props.t('post_auction.all_vehicle')}</span>
            </div>



            <div className="card color-two">
              <div className="roung-bg">
                <i className="ic-no-bids">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                  <i className='path4'></i>
                  <i className='path5'></i>
                  <i className='path6'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.no_bid ?
                    <Link to={() => this.redirectToVehicleList(1)} > {postList.no_bid}</Link>
                    :
                    "0"
                }
              </span>
              <span className="card-txt">{this.props.t('post_auction.no_bids')}</span>
            </div>

            <div className="card color-three">
              <div className="roung-bg">
                <i className="ic-approval-pending">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                  <i className='path4'></i>
                  <i className='path5'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.approval_pending ?
                    <Link to={() => this.redirectToVehicleList(2)} > {postList.approval_pending}</Link>
                    :
                    "0"
                }
              </span>
              <span className="card-txt">{this.props.t('post_auction.approval_pending')}</span>
            </div>

            <div className="card color-four">
              <div className="roung-bg">
                <i className="ic-approval">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.bid_approved ?
                    <Link to={() => this.redirectToVehicleList(3)} > {postList.bid_approved}</Link>
                    :
                    "0"
                }
              </span>
              <span className="card-txt">{this.props.t('post_auction.bid_approved')}</span>
            </div>

            {/* <div className="card color-five">
              <div className="roung-bg">
                <i className="ic-cancel">
                </i>
              </div>
              <span className="count-txt">{postList.bid_rejected}</span>
              <span className="card-txt">{this.props.t('post_auction.bid_rejected')}</span>
            </div> */}

            <div className="card color-three">
              <div className="roung-bg">
                <i className="ic-payment-shared">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.payment_detail_shared ?
                    <Link to={() => this.redirectToVehicleList(4)} > {postList.payment_detail_shared}</Link>
                    :
                    "0"
                }
              </span>
              <span className="card-txt">{this.props.t('post_auction.payment_detail_shared')}</span>
            </div>

            <div className="card color-four">
              <div className="roung-bg">
                <i className="ic-delivered">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                  <i className='path4'></i>
                  <i className='path5'></i>
                  <i className='path6'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.vehicle_delivered ?
                    <Link to={() => this.redirectToVehicleList(5)} > {postList.vehicle_delivered}</Link>
                    :
                    "0"
                } 
                </span>
              <span className="card-txt">{this.props.t('post_auction.vehicle_delivered')}</span>
            </div>

            <div className="card color-five ">
              <div className="roung-bg">
                <i className="ic-reject">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.rejected ?
                    <Link to={() => this.redirectToVehicleList(6)} > {postList.rejected}</Link>
                    :
                    "0"
                }  
              </span>
              <span className="card-txt">{this.props.t('post_auction.rejected')}</span>
            </div>

            <div className="card color-one ">
              <div className="roung-bg">
                <i className="ic-dislike">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.rejected_other ?
                    <Link to={() => this.redirectToVehicleList(7)} > {postList.rejected_other}</Link>
                    :
                    "0"
                }  
              </span>
              <span className="card-txt">{this.props.t('post_auction.rejected_other')}</span>
            </div>

            <div className="card color-two ">
              <div className="roung-bg">
                <i className="ic-reauction">
                  <i className='path1'></i>
                  <i className='path2'></i>
                  <i className='path3'></i>
                  <i className='path4'></i>
                  <i className='path5'></i>
                  <i className='path6'></i>
                </i>
              </div>
              <span className="count-txt">
                {
                  postList.reauctioned ?
                    <Link to={() => this.redirectToVehicleList(8)} > {postList.reauctioned}</Link>
                    :
                    "0"
                }  
              </span>
              <span className="card-txt">{this.props.t('post_auction.reauctioned')}</span>
            </div>

          </div>

        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    auctionVehicleFilterList: state.common.auctionVehicleFilterList || {}
  }

};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      getAllAuctionList: bindActionCreators(
        AuctionActions.getAuctionList,
        dispatch
      ),
      getPostAuctionDetail: bindActionCreators(
        AuctionActions.getPostAuctionDetail,
        dispatch
      )
    }
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(PostAuction))); 