import React, { Component, createRef} from 'react';
import Select from 'react-select';
import Modal from '../../elements/PopupModal';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { AuctionActions } from './../../../store/action';
import { toast } from 'react-toastify';
import InputField from '../../elements/InputField';
import MultiSelect from '../../common/MultiSelect'
import secureStorage from '../../../config/encrypt';

// email encrypt then decrypt
let CCto = 'U2FsdGVkX19v0hlqUbNva6ADcWeRCxLgumeniI4rF4ex2LZ2R/79aR9lYpo/gsrTANRJWlrFL2OuAlPJg9i/2+p11eEdM356iYGIWls67fygimBybuV6z8WZYhYo4/dCFgxhO0eZ8zbVLZOLQd7OoT3JlLBRU6e9f16xOAbpZ5DGYRncioGTvTZPx+TiNippwjN+KuCRzDbb7n9DCGJ8Op2Fpd2kdrIa4PJXr0gtSjs=';
CCto = JSON.parse(secureStorage.decrypt(CCto));


const Template = [
    { value: 'Change in Auction Time', label: 'Change in Auction Time' },
    { value: 'others', label: 'Others' }
];

const Title = [
    { value: 'time left to end', label: 'Time left to end' }

];



class ShareDetailPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sellerOption: null,
            CCtoOption: null,
            sharePopup: { show: false },
            sellerList: props.sellerList,
            selectedSeller: [],
            toremoveSellerId: [],
            message: '',
            loading: false
        };
        this.cc_div = createRef();
        this.buyer_div = createRef();
        this.templt_div = createRef();
        this.title_div = createRef();
    }

    handleChangeseller = (sellerOption) => {
        this.setState({ sellerOption });
    };

    handleChangecc = (CCtoOption) => {
        this.setState({ CCtoOption });
        this.cc_div.current.classList.add('active-label');
    };
    handleChangeTemplate = (TemplateOption) => {
        this.setState({ TemplateOption, TitleOption: '' });
        this.templt_div.current.classList.add('active-label')
        this.title_div.current.classList.remove('active-label')
    };
    handleChangeTitle = ( type, TitleOption) => {
        if(type === 'text'){
            let freeTitle = {value: TitleOption.target.value, label: TitleOption.target.value}
            this.setState({ TitleOption : freeTitle });

        }else{
            this.setState({ TitleOption });
            this.title_div.current.classList.add('active-label')
        }
    };
    hideModalsharePopup = () => {
        this.props.closeModalsharePopup();
        
        //RESET SELECTED VALUES
        this.resetSelectedValues();

    }
    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ sharePopup: { ...this.state.sharePopup, ...props.sharePopup }, sellerList: props.sellerList });
    }
    onSelect(key, selectedList, selectedItem) {
        this.setState({ selectedSeller: selectedList });
        if(selectedList && selectedList.length){
            this.buyer_div.current.classList.add('active-label')
        }else{
            this.buyer_div.current.classList.remove('active-label')
        }
    }
    removeSeller = (selllerid) => {
        let removedIds = this.state.toremoveSellerId;
        removedIds.push(selllerid);
        this.setState({ toremoveSellerId: removedIds })
        let selectedSellers = this.state.selectedSeller;
        let sellerListAfterRemove = selectedSellers.filter(seller => !removedIds.includes(seller.value));
        this.setState({ selectedSeller: sellerListAfterRemove });

    }
    handleChange = (event) => {
        this.setState({ message: event.target.value });
    }
    sendMail = () => {

        this.setState({loading: true});
        
        let postdata = {};
        postdata.dealers = this.state.selectedSeller.map(dealer => dealer.value);        
        postdata.template = (this.state.TemplateOption)?this.state.TemplateOption.value : '';
        postdata.cc = (this.state.CCtoOption)?[this.state.CCtoOption.value] : [];
        postdata.title = (this.state.TitleOption)?this.state.TitleOption.value : '';
        postdata.message = this.state.message;
        postdata.auction_id = this.state.sharePopup.auctionId;
        this.props.actions.submitEmailForm(postdata).then(res => {
            this.setState({loading: false});
            this.resetSelectedValues();

            if (res.status === 200) {
                toast.success(res.message);
                this.setState({ selectedSeller: [], TemplateOption: '', CCtoOption:'', TitleOption:'',message:''});
                this.props.closeModalsharePopup();

            }
        }).catch(err => {
            toast.error(err);
            this.setState({ loading: false});

        });
    }

    resetSelectedValues = () => {
        this.setState({ selectedSeller: [], TemplateOption: '', CCtoOption:'', TitleOption:'',message:'', loading: false});
        this.title_div.current.classList.remove('active-label')
        this.buyer_div.current.classList.remove('active-label')
        this.templt_div.current.classList.remove('active-label')
        this.cc_div.current.classList.remove('active-label')
    }


    /**
     * 
     * ON SELECT CLICK HANDLER 
     */
    onSelectOpen  = (el) => {
        let selectedDiv = el+'_div';

        //ADD CLASS ON CLICK

        //IN CASE OF TITLE
        if(el === 'title'){
            if(!this.state.TemplateOption || (this.state.TemplateOption && this.state.TemplateOption.value !== 'others')){
                if(this[selectedDiv] && this[selectedDiv].current) this[selectedDiv].current.classList.add('active-label')
            }

        }else{
            if(this[selectedDiv] && this[selectedDiv].current) this[selectedDiv].current.classList.add('active-label')
        }

    }

    /**
     * ON SELECT MENU HIDE HANDLER 
     */
    onSelectClose = (el) => {
        let selectedDiv = el+'_div';
        let { CCtoOption, TemplateOption, TitleOption } = this.state; 
        //REMOVE IF EMPTY
        if(this[selectedDiv] && ( (el === 'templt' && !TemplateOption ) || (el === 'cc' && !CCtoOption ) || (el === 'title' && !TitleOption )) ){
            this[selectedDiv].current.classList.remove('active-label');
        }  

    }

    render() {
        const { show } = this.state.sharePopup;
        const { CCtoOption, TemplateOption, TitleOption, sellerList, selectedSeller, loading, message } = this.state;
        let list = [];
        if (selectedSeller && selectedSeller) {
            list = selectedSeller;
        }
        return (
            <div>
                <div>
                    <div className="Auction-share-detail-popup">
                        <Modal show={show} handleClose={this.hideModalsharePopup} >
                                <div>
                                    <div className="modal-header">
                                        <h2>Email</h2>
                                    </div>
                                    <div className="modal-body" >
                                        <div className="mail-form-sec sms-email-sec">
                                            <fieldset className="multiselect-dropDown buyer-dropdown">
                                                <div className="material animation-effect" ref={this.buyer_div}>
                                                    <MultiSelect
                                                        isMulti={true}
                                                        options={sellerList}
                                                        placeholder={""}
                                                        value={selectedSeller}
                                                        onChange={this.onSelect.bind(this, 'seller')}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                    />
                                                    <label data-label={this.props.t('pages.auction.popup.mail.to')} className="form-label sms-to-label"></label>
                                                </div>
                                            </fieldset>
                                            <div className="select-item-list">
                                                <ul>
                                                    {list.map((item, index) => (
                                                        (index <= 2) ?
                                                            <li key={item.value}><span> {item.label}</span><i className="ic-clearclose" onClick={() => this.removeSeller(item.value)}></i></li>
                                                            : (+index === 3) ? <li key="morethan3" className="more-data"><span> + {(list.length - 3)} more</span></li> : ''

                                                    )
                                                    )}
                                                </ul>
                                            </div>
                                            <fieldset className="buyer-dropdown form-select-bx ">
                                                <div className="material animation-effect" ref={this.cc_div}>
                                                    <Select
                                                        value={CCtoOption}
                                                        onChange={this.handleChangecc}
                                                        onFocus={()=>this.onSelectOpen('cc')}
                                                        options={CCto}
                                                        placeholder=""
                                                        classNamePrefix='auction-dropdown'
                                                        onMenuClose={()=>this.onSelectClose('cc')}

                                                    />

                                                    <label data-label={this.props.t('pages.auction.popup.mail.cc_to')}  className="form-label"></label>
                                                </div>
                                            </fieldset>

                                            <fieldset className="buyer-dropdown form-select-bx">
                                                <div className="material animation-effect" ref={this.templt_div}>
                                                    <Select
                                                        value={TemplateOption}
                                                        onChange={this.handleChangeTemplate}
                                                        onFocus={()=>this.onSelectOpen('templt')}
                                                        options={Template}
                                                        placeholder=""
                                                        classNamePrefix='auction-dropdown'
                                                        onMenuClose={()=>this.onSelectClose('templt')}

                                                    />
                                                    <label data-label={this.props.t('pages.auction.popup.mail.template')}  className="form-label"></label>
                                                </div>
                                            </fieldset>

                                            <fieldset className="buyer-dropdown form-select-bx">
                                                <div className="material animation-effect" ref={this.title_div}>
                                                    {
                                                        TemplateOption && TemplateOption.value === 'others'
                                                        ?
                                                            <InputField
                                                                inputProps={{
                                                                    id: "name",
                                                                    type: "text",
                                                                    name: "name",
                                                                    placeholder: " ",
                                                                    value: (TitleOption && TitleOption.value) || '',
                                                                    className: "form-input others-title"
                                                                }}
                                                                onChange={this.handleChangeTitle.bind(this, 'text')}
                                                            />
                                                        :
                                                            <Select
                                                                value={TitleOption}
                                                                onChange={this.handleChangeTitle.bind(this, 'dropdown')}
                                                                onFocus={()=>this.onSelectOpen('title')}
                                                                options={Title}
                                                                placeholder=""
                                                                classNamePrefix='auction-dropdown'
                                                                onMenuClose={()=>this.onSelectClose('title')}

                                                            />
                                                    }
                                                    

                                                    
                                                    <label data-label={this.props.t('pages.auction.popup.mail.title')}  className="form-label"></label>
                                                </div>
                                            </fieldset>
                                            <fieldset className="">
                                                <div className="material">

                                                    <textarea className="form-input" placeholder=" " value={message} onChange={this.handleChange}></textarea>
                                                    <label data-label="Message" className="form-label"></label>
                                                </div>
                                            </fieldset>
                                            <div className="btn-send">
                                                {
                                                    loading
                                                    ?
                                                        <button className="btn-primary" disabled>Sending...</button>
                                                    :
                                                        <button className="btn-primary" onClick={this.sendMail}>Send</button>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </Modal>                      
                    </div>
                </div>
            </div>




        );
    }
}

const mapStateToProps = state => ({
    // getBlockedClosedLeads: state.lead.makeModelVersionList
})

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            submitEmailForm: bindActionCreators(
                AuctionActions.submitEmailForm,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareDetailPopup)));