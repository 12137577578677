import React from 'react';
import { connect } from 'react-redux';
import { UserActions } from "../../store/action";
import { bindActionCreators } from 'redux';
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import secureStorage from '../../config/encrypt';

class Logout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            "id": secureStorage.getItem('authUserInfo') || ""
        };
        
    }


    componentDidMount = () => {
        this.authLogout();
    }
    componentWillUnmount = ()=>{
        this.authLogout();
    }

    authLogout = () => {
        this.props.actions.authLogout();
    }

 

    render() {
        const { loggedOut } = this.props;
        const { login_type } = this.state.id;
       
        if(typeof loggedOut !== 'undefined'){
            if(login_type==='seller'){
                return <Redirect to='/login?type=seller' />
            }else{
                return <Redirect to='/login' />
            }
            
        }
        
        return (
            ''
        );
    }
}

const mapStateToProps = state => {
    return {
       auth: state.auth.auth,
       loggedOut: state.auth.loggedOut
    }
 }

const mapDispatchToProps = dispatch => {
    return {
               actions: {
                  authLogout: bindActionCreators(
                    UserActions.logout,
                    dispatch
                  )
               }
            }
}

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout)));