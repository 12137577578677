import React, { Component } from 'react';


class EditUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            
        }
      
    }

    componentDidMount = async () => {
        this._isMounted = true;
        
    }


    //TEAM_LEAD_ROLE_ID
    render() {
          
        return (
            <div className="container">
                <h1>Edit</h1>                
           </div>
        )
    }
}


export default EditUser;
