import React, { Component } from 'react';
import FileUpload from '../components/FileUpload'
import InventoryBulkImageUpload from '../components/InventoryBulkImageUpload';
import Modal from '../../elements/PopupModal';
import { NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import ConfirmationModal from '../../common/ConfirmationModal';
import csvsample from "./../../../webroot/csv/inventory-sample.csv";

class TopActionBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUploadInventory: false,
            BankPaymentUploadError: false,
            showBulkDeleteConfirmationModal: false
        };
    }

    showInventoryUploadPopup = () => {
        this.setState({ showUploadInventory: true });
        document.body.classList.add("overflow-hidden");
    }

    hideInventoryUploadPopup = () => {
        this.setState({ showUploadInventory: false });
        document.body.classList.remove("overflow-hidden");
    }
    // showModalBankPaymentUploadError = () => {
    //     this.setState({ BankPaymentUploadError: true });
    //     document.body.classList.add("overflow-hidden");
    // }

    // hideModalBankPaymentUploadError = () => {
    //     this.setState({ BankPaymentUploadError: false });
    //     document.body.classList.remove("overflow-hidden");
    // }

    /**
     * DELETE BULK INVENTORY
     */
    bulkInventoryDelete = async () => {
        // this.props.bulkInventoryDelete()
        this.setState({ showBulkDeleteConfirmationModal: true })
    }

    /**
     * EXPORT INVENTORY
     * @returns 
     */
    exportXls = () => {
        this.props.exportXls()
    }

    /**
     * HANDLE CONFIRMATION MODAL RESPONSE
     * @param {TRUE/FALSE} response 
     */
    confirmationModalResponse = (params) => {
        //IF SELECTED YES THEN SEND DATA TO PARENT TO TRIGGER BULK DELETE SERVICE
        if (params.response && params.type === 'bulkDelete') {
            this.props.bulkInventoryDelete()
        } else if (params.response && params.type === 'bulkSendToInspection') {
            this.props.bulkInventorySendToInspection()
        } else if (!params.response && params.type === 'bulkSendToInspection') {
            this.props.resetChekboxSelection()
        }

        this.setState({ showBulkDeleteConfirmationModal: false, showSendToInspectionConfirmationModal: false })

    }

    /**
     * 
     */
    sendToInspection = () => {
        this.setState({ showSendToInspectionConfirmationModal: true })
        
    }

    render() {

        const { multiSelectedVehicles } = this.props.componentData;
        let { showBulkDeleteConfirmationModal, showSendToInspectionConfirmationModal } = this.state;

        return (
            <div>
                <div className="top-heading-action-sec">
                    <h1>{this.props.t('pages.vehicle.list.title')}</h1>
                    <div className="action-btn">
                        {
                            multiSelectedVehicles.length && (this.props.loginType !== 'seller') 
                                ?
                                <button disabled className="btn-line">
                                    <i className="ic-share1"></i>
                                    <span> {this.props.t('pages.vehicle.list.share')}</span>
                                </button>
                                :
                                ''
                        }

                        {
                            multiSelectedVehicles.length && (this.props.loginType !== 'seller')
                                ?
                                <button disabled className="btn-line" /*onClick={this.bulkInventoryDelete}*/>
                                    <i className="ic-delete-icn"></i>
                                    <span> {this.props.t('pages.vehicle.list.delete')}</span>
                                </button>
                                :
                                ''
                        }

                        {
                            multiSelectedVehicles.length
                                ?
                                <button className="btn-line" onClick={this.sendToInspection}>
                                    <i className="ic-send-icn"></i>
                                    <span> {this.props.t('pages.vehicle.list.send_to_inspection')}</span>
                                </button>
                                :
                                ''
                        }


                        <button className="btn-line" onClick={() => this.exportXls()}>
                            <i className="ic-pdf-file"></i>
                            <span>{this.props.t('pages.vehicle.list.download_vehicle_list')} </span>
                        </button> 
                        {
                            (this.props.loginType === 'seller') ? null : 
                            <>
                                <button className="btn-line" onClick={this.showInventoryUploadPopup}>
                                    <i className="ic-upload"></i>
                                    <span> {this.props.t('pages.vehicle.bulk_upload.upload_vehicle')}</span>
                                </button>
                                <NavLink className="btn-line" to={"/add-vehicle"} >
                                    <i className="ic-add"></i>
                                    <span> {this.props.t('pages.vehicle.list.add_vehicle')}</span>
                                </NavLink>

                            </>
                        }
                        
                        {/* </button> */}
                        
                    </div>
                </div>
                <div className="upload-vehicle-popup">
                    <Modal show={this.state.showUploadInventory} handleClose={this.hideInventoryUploadPopup} >
                        <div className="modal-header">
                            <h2>{this.props.t('pages.vehicle.bulk_upload.upload_vehicle')}</h2>
                        </div>
                        <div className="modal-body" >
                            <div className="upload-inventory-panel upload-heading">
                                <h3>{this.props.t('pages.vehicle.bulk_upload.upload_vehicle_file')}</h3>
                                <FileUpload hideInventoryUploadPopup={this.hideInventoryUploadPopup}/>
                                <div className="download-txt">
                                    {this.props.t('pages.vehicle.bulk_upload.download_input_format')} <a href={csvsample} download>
                                        {this.props.t('pages.vehicle.bulk_upload.click_here')}
                                    </a>
                                </div>

                            </div>
                            <div className="upload-image-panel upload-heading">
                                <h3>{this.props.t('pages.vehicle.bulk_upload_images.upload_vehicle_images')}</h3>
                                <InventoryBulkImageUpload hideInventoryUploadPopup={this.hideInventoryUploadPopup} />
                            </div>
                        </div>


                    </Modal>
                </div>

                {/* BULK DELETE MODAL */}
                {
                    showBulkDeleteConfirmationModal
                        ?
                            <ConfirmationModal confirmationData={{ type: "bulkDelete", show: showBulkDeleteConfirmationModal, confirmationText: this.props.t("pages.vehicle.list.delete_inventory_confirmation") }} confirmationModalResponse={this.confirmationModalResponse} />
                        :
                            ''
                }

                {/* BULK SEND TO INSPECTION MODAL */}
                {
                    showSendToInspectionConfirmationModal
                        ?
                            <ConfirmationModal confirmationData={{ type: "bulkSendToInspection", show: showSendToInspectionConfirmationModal, confirmationText: this.props.t("pages.vehicle.list.bulk_send_to_inspection_update_confirmation") }} confirmationModalResponse={this.confirmationModalResponse} />
                        :
                            ''
                }
            </div>
        )
    }
}

const container = document.createElement('div');
document.body.appendChild(container);

export default withTranslation('common')(TopActionBtn);
