import MasterService from './MasterService';

export const VehicleService = {
  ...MasterService,

  saveVehicle(postData) {
    return this.post('/vehicle/saveVehicle', postData);
  },
  uploadVehicleImages(postData, headers) {
    return this.post('vehicle/uploadVehicleImages', postData, headers)
  },
  getVehicleList(postData) {
    return this.post('vehicle/getVehicleList', postData)
  },
  getVehicleDetails(postData) {
    return this.post('vehicle/getVehicleDetails', postData)
  },
  removeVehicleImage(postData) {
    return this.post('vehicle/removeVehicleImage', postData)
  },
  getVehicleActivity(postData) {
    return this.post('vehicle/getVehicleActivity', postData)
  },
  exportVehicleData(postData) {
    return this.post('vehicle/exportVehicleData', postData)
  },
  submitBulkUploadinventory(postData, headers) {
    return this.post('vehicle/uploadCSV', postData, headers)
  },
  submitBulkUploadInventoryImages(postData, headers) {
    return this.post('vehicle/bulkUploadInventoryImage', postData, headers)
  },
  submitBulkUploadVehicleImages(postData, headers) {
    return this.post('vehicle/bulkUploadVehicleImage', postData, headers)
  }, 
  bulkInventoryDelete(postData) {
    return this.post('vehicle/bulkInventoryDelete', postData)
  },
  bulkInventorySendToInspection(postData) {
    return this.post('vehicle/bulkInventorySendToInspection', postData)
  }

}
export default VehicleService;