export const CommonMethodConstants = {
    GET_INVENTORY_FILTER_SUCCESS: 'GET_INVENTORY_FILTER_SUCCESS',
    GET_INVENTORY_FILTER_FAILURE: 'GET_INVENTORY_FILTER_FAILURE',

    GET_INVENTORY_MMV_SUCCESS: 'GET_INVENTORY_MMV_SUCCESS',
    GET_INVENTORY_MMV_FAILURE: 'GET_INVENTORY_MMV_FAILURE',

    GET_SELLER_LIST_SUCCESS: 'GET_SELLER_LIST_SUCCESS',
    
    GET_CITY_STATE_LIST_SUCCESS: 'GET_CITY_STATE_LIST_SUCCESS',

    GET_BUYERS_LIST_SUCCESS: 'GET_BUYERS_LIST_SUCCESS',

    GET_AUCTION_VEHICLE_FILTER_LIST_SUCCESS: 'GET_AUCTION_VEHICLE_FILTER_LIST_SUCCESS',

    GET_AUCTION_FILTER_LIST_SUCCESS: 'GET_AUCTION_FILTER_LIST_SUCCESS'

};