import React, { Component } from 'react';

class ActiveUsers extends Component {

    render() {
        
        let activeUsers  = this.props.users
        activeUsers = [...new Set(activeUsers)];

        return (
            <div className="inbox_people">
                <div className="headind_srch">
                    <div className="recent_heading">
                    <h4>Recent</h4>
                    </div>
                    <div className="srch_bar">
                    <div className="stylish-input-group">
                        <input type="text" className="search-bar"  placeholder="Search" />
                        <span className="input-group-addon">
                        <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                        </span>
                    </div>
                    </div>
                </div>  
                <div className="inbox_chat">
                    {activeUsers.map((user, index) => (
                            <div key={index} className="chat_list active_chat">
                                <div className="chat_people">
                                <div className="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="" /> </div>
                                <div className="chat_ib">
                                <h5>{user} <span className="chat_date">{new Date().toLocaleDateString()}</span></h5>
                                </div>
                                </div>
                            </div>
                        ))}
                    
                </div>
            </div>
        )
    }
}

export default ActiveUsers;