import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { UserReducer, AuctionReducer, VehicleReducer,CommonMethodReducer } from './store/reducer';

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import common_id from "./translations/id/common.json";
import common_en from "./translations/en/common.json";

import secureStorage from './config/encrypt';

const loggerMiddleware = createLogger();
const current_lang = (secureStorage.getItem('lang')) ? secureStorage.getItem('lang') : process.env.REACT_APP_DEFAULT_LANG;
i18next.init({
    interpolation: { escapeValue: false },
    lng: current_lang,
    resources: {
      en: {
            common: common_en    
        },
        id: {
            common: common_id
        }
    },
});

const appReducer = combineReducers({ auth: UserReducer, auction: AuctionReducer, vehicle: VehicleReducer, common: CommonMethodReducer });

//WHEN USER LOGOUT CLEAR ALL REDUX STATE
const rootReducer = (state, action) => {
  if (action.type === 'USERS_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

let middleware = [];
if (process.env.NODE_ENV === 'dev') { //set development to show errors
  middleware = [...middleware, thunkMiddleware, loggerMiddleware];
} else {
  middleware = [...middleware, thunkMiddleware];
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(...middleware)),
);

ReactDOM.render(<I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
