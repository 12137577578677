import { UserService } from '../../service';
import {UserConstants} from '../constants/user.constants';
import { toast } from 'react-toastify';
import secureStorage from '../../config/encrypt';

export const UserActions = {
    login,
    logout,
    loginCaptcha,
    getSiteSettings,
    getCreatedByList
  };

function login(params) {

    return dispatch => {
      return new Promise((resolve, reject) => {
        UserService.login(params)
          .then(
            user => {
              if(user && user.data && user.data.status === 200){
                user.data.data['authUser'] = Object.assign({},user.data.data.user_data);
                delete user.data.data.user_data;
                secureStorage.setItem('authUserInfo', (user.data.data));
                toast.success(user.data.message);
                dispatch(success(user.data.data));
                return resolve(user.data)
              }
              else{
                dispatch(failure('Invalid credentials '));
                return resolve(user && user.data)
              }
            },
            error => {
              dispatch(failure(error));
              return reject(error);
            }
          );
      });
    };

    function success(user) { return { type: UserConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: UserConstants.LOGIN_FAILURE, error } }

}

function logout() {
    UserService.authLogout();
    secureStorage.removeItem('authUserInfo');

    return { type: UserConstants.LOGOUT };
}

function loginCaptcha() {
    return dispatch => {

      UserService.loginCaptcha()
        .then(
          captcha => {
            if(captcha){
              if(captcha.data && captcha.data.status === 200){
                dispatch(success(captcha.data.data));
              }else{
                dispatch(failure(captcha.data.message));
              }
            }else{
              dispatch(failure('error'));
            }
            
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  
    function success(captcha) { return { type: UserConstants.LOGIN_CAPTCHA, captcha } }
    function failure(error) { return { type: UserConstants.LOGIN_CAPTCHA, error } }
}

function getSiteSettings() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      UserService.getSiteSettings()
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data.data));
              resolve(resp.data);
            }else{
              dispatch(failure('data_not_found'));
              resolve(resp ? resp.data : [])
            }
          },
          error => {
            reject(error)
            dispatch(failure(error));
          }
        );
    });
  };

  function success(data) { return { type: UserConstants.SITE_SETTINGS_SUCCESS, data } }
  function failure(error) { return { type: UserConstants.SITE_SETTINGS_ERROR, error } }
}

function getCreatedByList() {

  return dispatch => {
    return new Promise((resolve, reject) => {
      UserService.getCreatedByList()
        .then(
          adminList => {
            if(adminList && adminList.data &&  adminList.data.status === 200){
              
              dispatch(success(adminList.data || []));
              resolve(adminList.data);
            }
            else{
              resolve((adminList && adminList.data) ||{});
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };

  function success(createdByList) { return { type: UserConstants.GET_CREATED_BY_SUCCESS, createdByList } }
  function failure(createdByError) { return { type: UserConstants.GET_CREATED_BY_FAILURE, createdByError } }

}
