import { CommonMethodConstants } from '../constants/commonMethod.constants';

export default function VehicleReducer(state='', action) {
  switch (action.type) {

    case CommonMethodConstants.GET_INVENTORY_FILTER_SUCCESS:
      return {
        inventoryFilterList: action.inventoryFilterData,
        ...state
      }

    case CommonMethodConstants.GET_INVENTORY_MMV_SUCCESS:
      return {
        mmvList: action.mmvData || {},
        ...state
      }

    case CommonMethodConstants.GET_SELLER_LIST_SUCCESS:
      return {
        sellerList: action.sellerList || {},
        ...state
      }

    case CommonMethodConstants.GET_CITY_STATE_LIST_SUCCESS:
      return {
        cityStateList: action.cityStateList || {},
        ...state
      }

    case CommonMethodConstants.GET_BUYERS_LIST_SUCCESS:
      return {
        ...state,
        buyerList: action.buyerList || {}
      }
      
    case CommonMethodConstants.GET_AUCTION_VEHICLE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        auctionVehicleFilterList: action.filterList || {}
      };
    
    case CommonMethodConstants.GET_AUCTION_FILTER_LIST_SUCCESS:
      return {
        ...state,
        auctionFilterList:  action.filterList || {}
      }

  

    default:
      return state
  }
}