import axios from 'axios';
import {default as config} from './../config/config';
import secureStorage from './../config/encrypt';
import { toast } from 'react-toastify';

const { API_URL  } = config

axios.interceptors.response.use(
    (response) => { 
        if (response && response.data && response.data.status !== 200) {
        }
        return response;
    },
    (error) => {
        // handle error
        if (error.response) {
            if (error.response.status === 503) {
                // toast.error(`${error.response.status} - ${error.response.statusText}`);
            } else if (error.response.status === 401) {
                window.location.href = '/logout';
            } else if (error.response.data.status === 400 && (error.response.data.message === 'invalid_refresh_token' || error.response.data.message === 'access_token_expired')) {
                //toast.error(`${error.response.data.status} - ${error.response.data.message}`);
                // window.location.href = '/logout';
            } else if (error.response.data.status === 400){
                toast.error(error.response.data.message);
            }
        }

        return error.response;
    });

window.commonErrorToast = function (error) {
    if(typeof error === "string") toast.error(error);
    else if(error.message) toast.error(error.message);
}

const services = {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        return (secureStorage.getItem('authUserInfo') && secureStorage.getItem('authUserInfo')['token'])
            ? secureStorage.getItem('authUserInfo')['token']
            : ''
    },
    getUserId(){
        return (secureStorage.getItem('authUserInfo'))
            ? secureStorage.getItem('authUserInfo')['authUser']['id']
            : ''
    },
    delete(URL, body, headers) {
        headers = headers || {};
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        headers['userId'] = this.getUserId();
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return axios.delete(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    post(URL, body, headers) {
        headers = headers || {};
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        headers['userId'] = this.getUserId();
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return axios.post(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    }, 
    put(URL, body, headers) {
        headers = headers || {};
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        headers['userId'] = this.getUserId();
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return axios.put(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    get(URL, query, headers) {
        headers = headers || {};
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        headers['userId'] = this.getUserId();
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return axios.get(`${API_URL.GETWAY_API}${URL}`, { params: query, headers })
    },
    postDC(URL, body, headers) {
        headers = headers || {};
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        headers['userId'] = this.getUserId();
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return axios.post(`${API_URL.DC_API_URL}${URL}`, body, {headers})
    }
}

export default services