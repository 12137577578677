import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
//Custom component

// import Header from "./../view/common/Header";
import FooterAuction from "./../view/common/Footer";
import Login from "../view/auth/login/component/Login";
import Chat from "../view/home/components/new/chat";
import Logout from "../view/auth/Logout";
import AddUser from "../view/administration/user/editUser";
import ListUser from "../view/administration/user/userList";
import AddAuction from "../view/auction/components/AddAuction";
import ListAuction from "../view/auction/components/ListAuction";
import ManageAuction from "../view/auction/components/ManageAuction";
import AddAuctionCar from "../view/auction/components/AddAuctionCar";
import VehicleList from "../view/vehicle/components/VehicleList";
import SaveVehicle from "../view/vehicle/components/SaveVehicle";
import BeforeLogin from "./beforeLogin";
import PrivateRoute from "./privateRoute";
import AuctionMonitor from "../view/auction/components/AuctionMonitor";


import Header from "../view/common/Header"
import BankAuction from "../view/html/components/BankAuction"
import EditVechile from "../view/html/components/EditVechile"
import EditVechileGallery from "../view/html/components/EditVechileGallery"
import LoginHtml from "../view/html/components/LoginHtml"
import LoginOtp from "../view/html/components/LoginOtp"
import AuctionListAdmin from "../view/html/components/AuctionLIstAdmin"
import AuctionAdd from "../view/html/components/AuctionAdd"
import EditAuction from "../view/html/components/EditAuction"
import AuctionMonitoring from "../view/html/components/AuctionMonitoring"
import PostAuction from "../view/auction/components/post-auction/PostAuction"
// import FooterAuction from "../view/html/Common/FooterAuction"

import secureStorage from './../config/encrypt';

class CustomRouter extends Component {
  constructor(props) {
    super(props);
    this.state = { ht: 500 };
  }
  componentDidMount() {
    this.setState({
      ht: window.innerHeight - 127,
    });
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          ht: window.innerHeight - 127,
        });
      },
      false
    );
  }

  render() {
    let authUser = secureStorage.getItem('authUserInfo') || {};
    let defaultPage = (authUser.login_type ==='seller')?'vehicle-list':'list-auction';
    let dashboardRedirect = (authUser && authUser.dashboardRedirect) || defaultPage; //DASHBOARD REDIRECT ROUTE CHECK

    return (
      <Router>
        <Header />

        <div style={{ minHeight: this.state.ht }}>

          <BeforeLogin path="/login" component={Login} />
          <BeforeLogin path="/logout" component={Logout} />
          <BeforeLogin path="/bank-auction" component={BankAuction} />
          <BeforeLogin path="/edit-vehile" component={EditVechile} />
          <BeforeLogin path="/edit-vehile-gallery" component={EditVechileGallery} />
          <BeforeLogin path="/login-html" component={LoginHtml} />
          <BeforeLogin path="/login-otp-error" component={LoginOtp} />

          <BeforeLogin path="/auction-list-admin" component={AuctionListAdmin} />
          <BeforeLogin path="/auction-add" component={AuctionAdd} />
          <BeforeLogin path="/Edit-auctions" component={EditAuction} />
          <BeforeLogin path="/auction-monitoring" component={AuctionMonitoring} />
          <BeforeLogin path="/post-auction" component={PostAuction} />


          {/* DASHBOARD ROUTE  */}
          <Route exact path="/">
            <Redirect to={'/' + dashboardRedirect} />
          </Route>

          {/* DASHBOARD ROUTE END */}

          <PrivateRoute path="/chat/:name/:room" component={Chat} />

          {/* USER ROUTE STARTS */}
          <PrivateRoute path="/add-users" component={AddUser} />
          <PrivateRoute path="/list-users" component={ListUser} />
          {/* USER ROUTE ENDS */}

          {/* AUCTION ROUTE  */}
          <PrivateRoute path="/add-auction" component={AddAuction} />
          <PrivateRoute exact path="/edit-auction/:id" component={AddAuction} />
          <PrivateRoute path="/list-auction" component={ListAuction} />
          <PrivateRoute path="/manage-auction/:id" component={ManageAuction} />
          {/* AUCTION ROUTE ENDS */}

          <PrivateRoute path="/add-auction-car" component={AddAuctionCar} />

          <PrivateRoute path="/vehicle-list" component={VehicleList} />
          <PrivateRoute path="/add-vehicle" component={SaveVehicle} />
          <PrivateRoute path="/edit-vehicle/:id" component={SaveVehicle} />

          <PrivateRoute path="/auction-monitor" component={AuctionMonitor} />



        </div>
        <FooterAuction />
        {/* <Footer /> */}
      </Router>
    );
  }
}

export default CustomRouter;
