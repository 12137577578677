import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Tab, Nav } from 'react-bootstrap';
import Select from 'react-select';
import { default as config } from '../../../config/config';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { AuctionActions } from './../../../store/action';
import FilterTable from '../../helpers/filter-table';
import InputField from '../../elements/InputField';
import DateFormat from 'dateformat';
import Pagination from '../../elements/Pagination';
import * as HELPER from './../../../config/helper';
import AuctionActivityLog from './AuctionActivityLog';
import { toast } from 'react-toastify';
import ShareDetailPopup from './../components/AuctionShareDetailPopup';
import SMSPopup from './../components/SMSPopup';
import ModalPopup from './../../elements/PopupModal';
import { CommonMethodActions } from '../../../store/action';
import FullPageLoader from '../../common/FullPageLoader'; 

const SearchBy = [
  { value: 'name', label: 'Auction Name' },
  { value: 'reg_no', label: 'Reg No.' },
  { value: 'vid', label: 'VID' }
];

const StartEndTimeTd = ({tab,list,timeRemaining}) =>{
  return ( 
       <React.Fragment>
        {
          (+tab === 2)?
            <React.Fragment>
            <td>{list.start_date_time ? DateFormat(list.start_date_time, config.constants.dateFormatDDMMMYYYYHM) : ''}</td>
            {/* <td>{list.end_date_time ? DateFormat(list.end_date_time, config.constants.dateFormatDDMMMYYYYHM) : ''}</td> */}
            <td>{timeRemaining}</td>
            </React.Fragment>
            :
            <React.Fragment>
            <td>{list.start_date_time ? DateFormat(list.end_date_time, config.constants.dateFormatDDMMMYYYYHM) : ''}</td>
            <td>{timeRemaining}</td>
            </React.Fragment>
        } 
       </React.Fragment>
  )
} 

class Auction extends Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState = () => {
    return {
      loading: false,
      resultList: [],
      pagination: {},
      totalLengthWithoutPagination: 0,
      runPaginationFunc: false,
      formAction: 'auctionList',
      tableHeaders: [
        { key: 'bucketId', value: 'pages.auction.list.bucket_id' },
        { key: 'bucketName', value: 'pages.auction.list.auction_name' },
        { key: 'region', value: 'pages.auction.list.region' },
        { key: 'format', value: 'pages.auction.list.format' },
        { key: 'vehicleType', value: 'pages.auction.list.vehicle_type' },
        { key: 'endDateTime', value: 'pages.auction.list.end_date_time' },
        { key: 'timeRemaining', value: 'pages.auction.list.time_remaining' },
        { key: 'vehicleLeft', value: 'pages.auction.list.vehicle_left' },
        { key: 'auctionList', value: 'pages.auction.list.actions' }
      ],
      tableHeadersUpcoming: [
        { key: 'bucketId', value: 'pages.auction.list.bucket_id' },
        { key: 'bucketName', value: 'pages.auction.list.auction_name' },
        { key: 'region', value: 'pages.auction.list.region' },
        { key: 'format', value: 'pages.auction.list.format' },
        { key: 'vehicleType', value: 'pages.auction.list.vehicle_type' },
        { key: 'startDateTime', value: 'pages.auction.list.start_date_time' },
        // { key: 'endDateTime', value: 'pages.auction.list.end_date_time' },
        { key: 'timeRemaining', value: 'pages.auction.list.time_remaining' },
        { key: 'vehicleLeft', value: 'pages.auction.list.vehicle_left' },
        { key: 'auctionList', value: 'pages.auction.list.actions' }
      ],
      filterData: {},
      tableHtmlData: [],
      upcommingAuctionTotal: 0,
      expiredAuctionTotal: 0,
      liveAuctionTotal: 0,
      tab: 1,
      sharePopup: { show: false },
      search: '',
      searchType:'',
      ViewTimeLine: { show: false, auctionId: 0, tab: 0 },
      smsPopup: { show: false, auctionId: 0, tab: 0 },
      sellerList: [],
      datesArray: []
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.getAuctionList({ page_number: 1, tab: 1 })
    this.sellerList();
  }

  sellerList = async () => {
    let filter = {};
    filter['auction_buyer'] = [1];
    this.props.actions.getBuyersList({ filter: filter }).then(resp => {
      this.setState({ sellerList: resp });
    });
  }

  componentWillUnmount = () => {
    this._isMounted = false
    clearInterval(this.intervalManger);
  }

  getAuctionList = async (data = {}) => {
    if (this._isMounted) await this.setState({ loading: true });
    let searchResult = await this.props.actions.getAuctionList(data);
    if (this._isMounted) {
      let finalResult = (searchResult && searchResult.data ? searchResult.data : []);
      await this.setState({
        loading: false,
        resultList: [...finalResult] || [],
        pagination: (searchResult && searchResult.pagination) ? searchResult.pagination : {},
        totalLengthWithoutPagination: ((searchResult && searchResult['pagination'] && searchResult['pagination']['total']) || 0), runPaginationFunc: true,
        vehicleListPagination: searchResult && searchResult.pagination ? searchResult.pagination : {},
        liveAuctionTotal: ((searchResult && searchResult['pagination'] && searchResult['pagination']['total_live_auction_count']) || 0),
        upcommingAuctionTotal: ((searchResult && searchResult['pagination'] && searchResult['pagination']['total_upcomming_auctions']) || 0),
        expiredAuctionTotal: ((searchResult && searchResult['pagination'] && searchResult['pagination']['total_expired_auctions']) || 0)
      });
    }

    this.generateTableData();
    //RUN TIMER
    this.initTimer();
  }

  showModalViewTimeLine = (tab, auctionId) => {
    this.setState({ ViewTimeLine: { tab, show: true, auctionId } });
    document.body.classList.add("overflow-hidden");
  }
  showModalSMSPopup = (tab, auctionId) => {
    this.setState({ smsPopup: { tab, show: true, auctionId } });
    document.body.classList.add("overflow-hidden");
  }

  getAuctions = async (data = {}) => {
    clearInterval(this.intervalManger);

    if (this._isMounted) await this.setState({ loading: true });
    this.setState({ tab: data });

    let postData = { page_number: 1, tab: data }
    if (data['search']) postData['search'] = this.state.search 
    if (this.state['search']) postData['search'] = this.state.search
    if (this.state['searchType']) postData['search_type'] = this.state.searchType 


    this.getAuctionList(postData)
  }

  // getFilterList = async (searchParams) => {
  //   let validateForm = await this.validateFilterObj(searchParams);
  //   let postData = this.state.filterData;
  //   postData['page_number'] = searchParams['page_number'];
  //   postData['tab'] = searchParams['tab'];
  //   this.setState({ filterData: postData });
  //   if (validateForm) {
  //     this.getAuctionList(searchParams);
  //   }
  // }

  validateFilterObj = async (params) => {
    return true;
  }

  //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
  callbackFunction = async (childData) => {
    if (this._isMounted) await this.setState({ runPaginationFunc: childData['runPaginationFunc'], tab: this.state.tab });
  }

  handleRequestData = async (data) => {

    let page = data.page_number;
    data.tab = this.state.tab;  
    if (this.state['search']) data['search'] = this.state.search
    if (this.state['searchType']) data['search_type'] = this.state.searchType 
 
    if (this._isMounted) {

      await this.setState({
        formAction: '',
        loading: true,
        page,
        pagination: {},
        totalLengthWithoutPagination: 0,
        runPaginationFunc: false,
        tab: this.state.tab
      });
      this.getAuctionList(data);
     // this.getFilterList(data);

    }
  }
  handleClick = async (data, type) => {
    if (HELPER.isUserHasAccessPage('edit-auction') && type === 'edit') {
      this.props.history.push("/edit-auction/" + data)
    }else if (HELPER.isUserHasAccessPage('vehicle-list') && type === 'view') {
      this.props.history.push("/vehicle-list?auction_id_hash="+data+"")
    }
  }
  addAucton = async () => {
    if (HELPER.isUserHasAccessPage('add-auction')) {
      this.props.history.push("/add-auction")
    }
  } 

  generateTableData = async () => {
    let html = []
    
    const { resultList, tableHeaders, tab } = this.state;  
    for (let [key, list] of resultList.entries()) {
      let region = await HELPER.getRegionName(list.region);
      let formatName = await HELPER.getFormatName(list.format);
      let timeRemaining = (tab === 1) ? await HELPER.getRemainingTime(list.end_date_time) : await HELPER.getRemainingTime(list.start_date_time);
      let vehiclType = await HELPER.getVehicleTypeName(list.vehicle_type);
    
      html.push(
        <tr key={key}>
          <td>{list.id}</td>
          <td>{list.name}</td>
          <td>{region}</td>
          <td>{formatName}</td>
          <td>{vehiclType}</td>
           <StartEndTimeTd tab={tab} list={list} timeRemaining={timeRemaining} /> 
          <td>{ ((list.inventory - list.bidding_count) > 0 ? (list.inventory - list.bidding_count) : 0) + ' of ' + list.inventory + ' left'}</td>
          <td colSpan="3">
            <div className="action-btn">
              <button type="button" className="btn-line" onClick={() => this.handleClick(list.hash_id, 'edit')}>Edit/Manage</button>
              <button type="button" className="btn-line" onClick={() => this.handleClick(list.hash_id, 'view')}>View</button>
              <i className="ic-email1" onClick={() => this.showModalsharePopup(tab, list.hash_id)}></i>
              <i className="ic-chat" onClick={() => this.showModalSMSPopup(tab, list.hash_id)}></i>
              <i className="ic-Call-history" onClick={() => this.showModalViewTimeLine(tab, list.hash_id)}></i>
            </div>
          </td>

        </tr>
      )
    }

    if (!resultList.length) {

      html.push(<tr key="1"><td colSpan={Object.keys(tableHeaders).length} className="text-center" style={{textAlign:'center'}}>{this.props.t('common.no_records_found')}</td></tr>)
    }


    if (this._isMounted) this.setState({ tableHtmlData: html })
    return html;

  }

  showModalsharePopup = (tab, auctionId) => {
    this.setState({ sharePopup: { tab, show: true, auctionId } });
    document.body.classList.add("overflow-hidden");
  }

  closeModalsharePopup = () => {

    this.setState({ sharePopup: { show: false } });
    document.body.classList.remove("overflow-hidden");
  }

  closeModalSmsPopup = () => {
    this.setState({ smsPopup: { show: false } });
    document.body.classList.remove("overflow-hidden");
  }


  searchAuction = () => {
    let postData = {};
    postData.tab = this.state.tab;
    postData.page_number = this.state.pagination.cur_page;
    if (this.state.search && this.state.searchType) {
      postData.search = this.state.search
      postData.search_type = this.state.searchType
      this.getAuctionList(postData);
    } else {
      toast.error('Please enter search value');
    }


  }

  searchDataChange = (data) => {
    this.setState({ search: data.target.value });
  }

  /**
   * CLOSE ACTIVITY MODAL
   */
  closeModalViewTimeLine = () => {
    this.setState({ ViewTimeLine: { show: false, auctionId: 0, tab: 0 } })

    document.body.classList.remove("overflow-hidden");

  }

  /**
   * RESET SEARCH
   */
  resetSearch = () => {

    this.setState({ search: '', searchType: '' });
    this.getAuctionList({ page_number: 1, tab: this.state.tab || 1 })

  }


  initTimer = () => {
    let {resultList, tab } = this.state;
    //RUN FOR LIVE & UPCOMING
    if([1, 2].includes(tab)){
      this.intervalManger = setInterval(()=> {
      
        let datesArray = {}
        if(resultList && resultList.length){
            for(let auction of resultList){
                let matchingDateTime = ((tab===1) ? auction.end_date_time : auction.start_date_time);
                if( new Date(matchingDateTime).getTime() - new Date().getTime() > 1){
                    datesArray = {  ...datesArray, [auction.id]: {date_time: matchingDateTime }  }
                }
                this.setState({datesArray})
            
            }

            //REGENERATE HTML
            if(Object.keys(datesArray).length){
              this.generateTableData()
            }
          
            if(Object.keys(datesArray).length !== resultList.length){
              
              clearInterval(this.intervalManger)
              //GET TAB FROM STATE ELSE SET 1
              this.getAuctions(this.state.tab || 1)
            } 
        }
      },1000);
      
    }
    
  }
 
  render() {
    const { tableHtmlData, formAction, loading, totalLengthWithoutPagination, resultList, page, runPaginationFunc, tableHeaders, vehicleListPagination, liveAuctionTotal, upcommingAuctionTotal, expiredAuctionTotal, search, ViewTimeLine, sharePopup, tab, smsPopup, sellerList,searchType,tableHeadersUpcoming } = this.state;
    let allSellerList = [];
    if (sellerList && sellerList.length) {
      allSellerList = sellerList.map(seller => {
        return { value: seller.id, label: seller.organization }
      })
    }    
    return (
      <div className="container-fluid">
        <FullPageLoader show={loading} />

        <div className="top-sec">
          <div className="top-heading-action-sec">
            <h1>Auctions</h1>
            <div className="action-btn">
              <button className="btn-line" onClick={() => this.addAucton()}>
                <i className="ic-add"></i>
                <span>Add Auction</span>
              </button>

            </div>
          </div>
        </div>
        <div className="tab-outer">
          <div >
            <Tab.Container id="" defaultActiveKey="first">
              <div className="auction-tab">
                <div className="tab-bg">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first" onClick={() => this.getAuctions(1)}>Live Auction <span>({liveAuctionTotal})</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second" onClick={() => this.getAuctions(2)}>Upcoming Auction <span>({upcommingAuctionTotal})</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third" onClick={() => this.getAuctions(3)}>Expired <span>({expiredAuctionTotal})</span></Nav.Link>
                    </Nav.Item>
                  </Nav>


                  <div className="right-search-panel">
                    <fieldset className="form-select-bx">
                      <div className="material">
                        <Select
                          id="searchBy"
                          value={SearchBy.filter((item)=>item.value === searchType)}
                          onChange={(event)=>{this.setState({searchType:(event)? event.value:''}) }}
                          options={SearchBy}
                          isClearable={true}
                          placeholder="Search By"
                          classNamePrefix='auction-dropdown'

                        />
                      </div>
                    </fieldset>
                    <fieldset className=" search-bx search-top-bar m-sm-r">
                      <InputField
                        inputProps={{
                          id: "search",
                          type: "text",
                          name: "search",
                          value: search,
                          className: "form-input"
                        }}
                        onChange={this.searchDataChange}
                      />
                      <i className="ic-search"></i>
                    </fieldset>

                    <div className="btn-submit-reset-auction-tabs">
                      <button type="submit" className="btn btn-primary " onClick={() => this.searchAuction()}>{this.props.t('common.search')}</button>
                      <button type="rest" className="btn-reset " onClick={() => this.resetSearch()}>{this.props.t('common.reset')}</button>
                    </div>
                  </div>



                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="auction-data-table">
                      <div>
                        <div className="table-outer">
                          <FilterTable filterTableData={{ formAction, loading: loading, totalLengthWithoutPagination, tableData: tableHtmlData, page, runPaginationFunc, tableHeaders,tableHeadersUpcoming, tab }} parentCallback={this.callbackFunction} />
                          <div className="pagination-outer">
                            <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': resultList, totalLengthWithoutPagination, vehicleListPagination }} action={formAction} sendRequestDataToParent={this.handleRequestData} checkedAllStatus tab={this.state.tab} />
                          </div>
                        </div>
                        {/* <AuctionActivityLog ViewTimeLine={ViewTimeLine} />  */}
                        <div className="view-timeline-popup">
                          {
                            ViewTimeLine.tab === 1
                              ?
                              <ModalPopup show={ViewTimeLine.show} handleClose={this.closeModalViewTimeLine}>
                                <AuctionActivityLog auctionId={ViewTimeLine.auctionId} />
                              </ModalPopup>
                              :
                              ""
                          }
                        </div>
                        <ShareDetailPopup sharePopup={sharePopup} closeModalsharePopup={this.closeModalsharePopup} sellerList={allSellerList} />
                        <SMSPopup smsPopup={smsPopup} closeModalSmsPopup={this.closeModalSmsPopup} sellerList={allSellerList} />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="auction-data-table">
                      <div>
                        <div className="table-outer">
                          <FilterTable filterTableData={{ formAction, loading: loading, totalLengthWithoutPagination, tableData: tableHtmlData, page, runPaginationFunc, tableHeaders,tableHeadersUpcoming, tab }} parentCallback={this.callbackFunction}/>
                          <div className="pagination-outer">
                            <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': resultList, totalLengthWithoutPagination, vehicleListPagination }} action={formAction} sendRequestDataToParent={this.handleRequestData} checkedAllStatus />
                          </div>
                        </div>

                        <div className="view-timeline-popup">
                          {
                            ViewTimeLine.tab === 2
                              ?
                              <ModalPopup show={ViewTimeLine.show} handleClose={this.closeModalViewTimeLine}>
                                <AuctionActivityLog auctionId={ViewTimeLine.auctionId} />
                              </ModalPopup>
                              :
                              ""
                          }
                        </div>
                        <ShareDetailPopup sharePopup={sharePopup} closeModalsharePopup={this.closeModalsharePopup} sellerList={allSellerList} />
                        <SMSPopup smsPopup={smsPopup} closeModalSmsPopup={this.closeModalSmsPopup} sellerList={allSellerList} />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="auction-data-table">
                      <div>

                        <div className="table-outer">
                          <FilterTable filterTableData={{ formAction, loading: loading, totalLengthWithoutPagination, tableData: tableHtmlData, page, runPaginationFunc, tableHeaders,tableHeadersUpcoming, tab }} parentCallback={this.callbackFunction} />
                          <div className="pagination-outer">
                            <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': resultList, totalLengthWithoutPagination, vehicleListPagination }} action={formAction} sendRequestDataToParent={this.handleRequestData} checkedAllStatus />
                          </div>
                        </div>

                        <div className="view-timeline-popup">
                          {
                            ViewTimeLine.tab === 3
                              ?
                              <ModalPopup show={ViewTimeLine.show} handleClose={this.closeModalViewTimeLine}>
                                <AuctionActivityLog auctionId={ViewTimeLine.auctionId} />
                              </ModalPopup>
                              :
                              ""
                          }

                        </div>
                        <div className="view-timeline-popup">
                          {
                            ViewTimeLine.tab === 3
                              ?
                              <ModalPopup show={ViewTimeLine.show} handleClose={this.closeModalViewTimeLine}>
                                <AuctionActivityLog auctionId={ViewTimeLine.auctionId} />
                              </ModalPopup>
                              :
                              ""
                          }

                        </div>
                        <ShareDetailPopup sharePopup={sharePopup} closeModalsharePopup={this.closeModalsharePopup} sellerList={allSellerList} />
                        <SMSPopup smsPopup={smsPopup} closeModalSmsPopup={this.closeModalSmsPopup} sellerList={allSellerList} />
                      </div>
                    </div>
                  </Tab.Pane>

                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {

  return {
    auctionAllData: state.auction || {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      getAuctionList: bindActionCreators(
        AuctionActions.getAuctionList,
        dispatch
      ),
      getBuyersList: bindActionCreators(
        CommonMethodActions.getBuyersList,
        dispatch
      )
    }
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Auction)));